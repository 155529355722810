import React, { JSX } from 'react';
import { ItemTitleClickEvent, SelectionChangedEvent } from "devextreme/ui/accordion";
import * as Styled from './index.styled';

type Props<T> = {
    readonly id: string;

    readonly data: T[];

    readonly renderTitle?: (data: T) => React.ReactNode;

    readonly getContainerItemContent?: (data?: T) => JSX.Element;

    readonly selected?: number[];

    readonly onSelectionChanged?: (e: SelectionChangedEvent) => void;

    readonly onItemTitleClick?: (e: ItemTitleClickEvent) => void
}

export const LsAccordionPanel = <T extends { title: string, text?: string, icon?: string }>(props: Props<T>) => {
    return (
        <Styled.BorderedAccordion
            id={`accordion-${props.id}`}
            keyExpr="id"
            selectedItemKeys={props.selected}
            repaintChangesOnly={true}
            collapsible={true}
            multiple={true}
            animationDuration={50}
            dataSource={props.data}
            itemTitleRender={props.renderTitle}
            itemRender={props.getContainerItemContent}
            onSelectionChanged={props.onSelectionChanged}
            onItemTitleClick={props.onItemTitleClick}
        />
    );
};
