import React, { JSX, useState } from 'react';
import { Popover } from 'devextreme-react/popover';
import { Button } from 'devextreme-react/button';
import ContextComponents from './contextmenu.styled';
import { generateSafeId } from '../../../helpers/renderModes';

type Action<T> = {
    actionName: string;
    ariaLabel: string;
    displayName: string;
    hidden?: boolean;
    action: (item: T) => void | Promise<void>;
}

interface IProps<T> {
    keyExpr: keyof T;
    uniqueId?: string;
    item: T;
    actions: Action<T>[];
}

export const ContextMenu = <T,>(props: IProps<T>): JSX.Element => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const buttonId = props.uniqueId ? props.uniqueId : props.item[props.keyExpr] as string;
    return (
        <>
            <Button
                id={`btn-show-context-menu-${generateSafeId(buttonId)}`}
                hint='Selecteer'
                type='default'
                stylingMode='text'
                icon='overflow'
                className='btn-small'
                onClick={(e) => {
                    e.event?.stopPropagation();
                    setPopoverVisible(true);
                }}
            />
            <Popover
                target={`#btn-show-context-menu-${generateSafeId(buttonId)}`}
                position='left'
                visible={popoverVisible}
                onHiding={() => setPopoverVisible(false)}
                shading={true}
                shadingColor="rgba(0, 0, 0, 0.2)"
                animation={{ show: { duration: 0 }, hide: { duration: 0 } }}
            >
                <ContextComponents.ListWrap>
                    {props.actions.filter(a => !a.hidden).map(a => (
                        <ContextComponents.LiBtn
                            key={`key-${generateSafeId(buttonId)}-${generateSafeId(a.actionName)}`}
                            aria-label={a.ariaLabel}
                            id={`btn-${a.actionName}`}
                            onClick={() => {
                                a.action(props.item);
                                setPopoverVisible(false);
                            }}
                        >
                            <ContextComponents.ListActionButton>{a.displayName}</ContextComponents.ListActionButton>
                        </ContextComponents.LiBtn>
                    ))}
                </ContextComponents.ListWrap>
            </Popover>
        </>
    );
};
