import React from 'react';
import { isEventKey } from 'keycode';
import styled, { css } from 'styled-components';

import { px } from '../../style';
import { BasicText } from '../typography';
import svgCheck from './imgs/icon-check.svg';
import { IInputCheckboxProps } from './Props';

interface ICheckDotProps {
  readonly $isSelected: boolean;
  readonly $isFocused: boolean;
  readonly $isDisabled: boolean;
  readonly $look: LabelLook;
}

const svgIcon: { readonly [key: string]: string; } = {
  check: `url(${svgCheck}) no-repeat`,
};

/* tslint:disable:max-line-length no-magic-numbers */

const defaultLook = {
  defaultColor: 'rgba(75, 85, 246, 0.1)',
  defaultColorDarker: 'rgba(75, 85, 246, 0.3)',
  primaryColor: '#4b55f6',
  primaryColorDarker: '#2934CC',
  disabledLight: '#F4F4F8',
  disabledDark: '#8A8991',
  textColor: '#414141',
};

enum LabelLook {
  default = 1,
  selected = 2,
  disabled = 3,
  disabledselected = 4,
}

const LabelLookCss = {
  [LabelLook.default]: css`
      background-color: #fff;
      border-color: #4b55f61A;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;      
  `,
  [LabelLook.selected]: css`
      background-color: #4b55f6;
      border-color : #4b55f6;
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4b55f6;
      &:hover {
        background-color: #2934CC;
      }
  `,
  [LabelLook.disabled]: css`
      background-color: #F4F4F8;
      border-color : #F4F4F8;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      &:hover {
        background-color: #2934CC;
      }
  `,
  [LabelLook.disabledselected]: css`
    background-color: #8A8991;
    border-color : #8A8991;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    &:hover {
        background-color: #2934CC;
      }
  `,
};

const Label = styled(BasicText).attrs({ as: 'label' }) <{ $look: LabelLook }>`
  position: relative;
  padding-left: ${px(40)};
  width: auto;
  min-width: 24px;
  height: 24px;
  margin: auto 0;
  &:focus {
    outline: none;
  }
`;

const CheckDot = styled.span<ICheckDotProps>`
  cursor: ${(p: ICheckDotProps) => (p.$isDisabled ? 'default' : 'pointer')};
  color: ${(p: ICheckDotProps) =>
    (p.$isDisabled && defaultLook.disabledDark)
    || (p.$isSelected && '#000')
    || defaultLook.textColor
  };
  font-weight: ${(p: ICheckDotProps) =>
    (p.$isDisabled && '300')
    || (p.$isSelected && '600')
    || '400'
  };
  display:inline-block;

  &::before {
    content: '';
    position: absolute;
    top: 0; bottom: 0; left: 0;
    width: 24px; height: 24px;
    border: 2px solid transparent;
    border-radius: 4px;
    transition: background-color .2s, border-color .2s;
    ${(p) => LabelLookCss[p.$look]};
  }
  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px; bottom:0;
    display: block;
    width: 16px; height: 16px;
    background: ${(p: ICheckDotProps) => (p.$isSelected && `${svgIcon.check}`)};
    transition:color .2s;
    
  }
  &:focus {
    outline: 0;
  }
  &:hover::before {
    
  }
  &:active::before {
    box-shadow: ${(p: ICheckDotProps) =>
    (p.$isDisabled && `0`)
    || (p.$isFocused && `0 0 0 6px ${defaultLook.defaultColor}`)
    || `0`};
  }
`;

const OverflowLabel = styled.span`
  width: ${px(200)}; 
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
  display: inline-block;
  vertical-align: top;
`;

export class InputCheckbox extends React.PureComponent<IInputCheckboxProps> {
  public state = {
    focused: false,
  };

  private labelElement?: HTMLElement;

  public render() {
    const { id, label, ariaLabel, selected = false, disabled = false, helpText } = this.props;

    const labelstyle = disabled ? (selected ? LabelLook.disabledselected : LabelLook.disabled) : (selected ? LabelLook.selected : LabelLook.default);

    return (<>
      <Label
        id={`label-checkbox-${id}`}
        role="checkbox"
        $look={labelstyle}
        aria-checked={selected}
        aria-label={ariaLabel}
        tabIndex={disabled ? -1 : 0}
        onKeyUp={this.onKeyPress}
        onClick={this.onClick}
        onFocus={this.onFocusRadio}
        onBlur={this.onBlurRadio}
        onMouseUp={this.onMouseUp}
        onTouchEnd={this.onTouchEnd}
        onMouseDown={this.stopPropagation}
        ref={this.onLabelRef as any}>
        <CheckDot $look={labelstyle} $isSelected={selected} $isFocused={this.state.focused} $isDisabled={disabled}>
          {label.toString().length > 55 ? <OverflowLabel>{label}</OverflowLabel> : label}
          {helpText && helpText.text && (
            // <HelpText id={`${id}-hint`} title={helpText.title} type={helpText.type}>{helpText.text}</HelpText>
            <>AAA</>
          )}
        </CheckDot>
      </Label>
    </>
    );
  }

  private onKeyPress = (e: React.KeyboardEvent<any>) => {
    if (!isEventKey(e as any, 'space')) {
      return;
    }

    this.propagateChange();
  }
  private stopPropagation = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  private propagateChange = () => {
    const { disabled, selected, onChange } = this.props;

    if (!disabled && onChange) {
      onChange(!selected);
    }
  }

  private onClick = (e) => {
    this.stopPropagation(e);
    this.propagateChange();
  }

  private onLabelRef = (labelElement: HTMLElement) => {
    this.labelElement = labelElement;
  }

  private onMouseUp = (e: React.SyntheticEvent<any>) => {

    if (this.labelElement) {
      // Remove focus through mouse
      this.labelElement.blur();
      e.preventDefault();
    }
  }

  private onTouchEnd = (e: React.SyntheticEvent<any>) => {
    if (this.labelElement) {
      // Remove focus through touch
      this.labelElement.blur();
      e.preventDefault();
    }
  }

  private onFocusRadio = () => {
    this.setState({ focused: true });
  }

  private onBlurRadio = () => {
    this.setState({ focused: false });
  }
}
