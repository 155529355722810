import React from 'react';
import Styled, { WarningLook } from './index.styled';

type Props = {
    look?: WarningLook,
    messageText: string,
    icon?: React.ReactNode,
    color?: string,
    className?: string,
};

/**
 * Represents a UI component that renders a warning wrapper with alert icon.
 */
const WarningWrapper: React.FC<Props> = (props) => {
    return (
        <Styled.WarningWrapper className={props.className} $look={props.look}>
            <>{props.icon} {props.messageText}</>
        </Styled.WarningWrapper>
    );
};

export { WarningWrapper, WarningLook };