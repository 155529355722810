import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';
import { SortDescriptor } from 'devextreme/data';
import _ from 'lodash';
import { FilterValueType, ILsColumnProps, ILsPagingProps } from '../..';

/**
 * Create a general datasource used by datasource based components, with devextreme.
 */
export const CreateCustomDataSource = (
    data: Array<{}>,
    columns?: ILsColumnProps[],
    paging?: ILsPagingProps,
    filterValue?: string | Array<any> | Function,
    sortExpression?: SortDescriptor<ILsColumnProps> | SortDescriptor<ILsColumnProps>[],
    normalizeFilter = false): DataSource => {

    const source = new DataSource({
        store: new CustomStore({
            load: () => data,
            loadMode: 'raw',
        }),
        langParams: {
            locale: 'nl',
            collatorOptions: {
                sensitivity: 'accent',
            }
        }
    });

    if (!_.isEmpty(filterValue)) {
        const appliedFilter = convertDateFilterValues(filterValue, columns);
        if (normalizeFilter) {
            source.filter(specialCharactersNormalizedArray(appliedFilter));
        } else {
            source.filter(appliedFilter);
        }
    }

    if (paging) {
        source.paginate(true);
        source.pageSize(paging.pageSize);
        source.requireTotalCount(true);
    } else {
        source.paginate(false);
    }

    if (sortExpression && sortExpression !== null && !_.isEmpty(sortExpression)) {
        source.sort(sortExpression);
    }

    return source;
}

export const encodeSpecialChars = (text: string): string => {
    // eslint-disable-next-line no-control-regex
    return text?.replace(/[^\u0000-\u007F]/g, (char: string) => {
        return `&#${char.charCodeAt(0)};`;
    });
};

export const specialCharactersNormalizedArray = (value) => {
    if (Array.isArray(value)) {
        return value.map((element) => specialCharactersNormalizedArray(element));
    } else {
        if (typeof value === 'string') {
            return encodeSpecialChars(value);
        }

        return value;
    }
};


export const convertDateFilterValues = (filterValue: FilterValueType, columns: ILsColumnProps[] = []): FilterValueType => {
    const dateFieldsNames = columns?.filter(f => f.dataType === 'date' || f.dataType === 'datetime')?.map(f => f.dataField);

    if (!Array.isArray(filterValue)) {
        return filterValue; // Return unchanged if it's not an array
    }

    const processFilter = (filter: FilterValueType, dateFieldsNames: string[]): FilterValueType => {
        if (Array.isArray(filter)) {
            return filter.map(item => {
                if (Array.isArray(item) && item.length === 3) {
                    const [field, operator, value] = item;
                    const matchingField = dateFieldsNames.find(f => f === field);
                    if (matchingField) {
                        const dateValue = new Date(value);
                        return [field, operator, dateValue];
                    }
                }
                return processFilter(item, dateFieldsNames);
            });
        }

        return filter;
    }

    // skip recursion if filter has only one condition (then it is an Array<string>, not Array<string | Array | Function>)
    const firstCondition = filterValue[0];

    if (!Array.isArray(firstCondition)) {
        const field = dateFieldsNames.find(f => f === firstCondition);
        //filterField is a datefield
        if (field) {
            const filterValueCopy = [...filterValue];
            const dateValue = new Date(filterValueCopy[2]);
            filterValueCopy[2] = dateValue;
            return filterValueCopy;
        }
        return filterValue;
    }
    const filter = processFilter(filterValue, dateFieldsNames);
    return filter;
};
