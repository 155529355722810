import React, { useEffect, useState } from 'react';
import * as Domain from '@liasincontrol/domain';
import { WrapperContent, PageTitle, Heading1 } from '@liasincontrol/ui-basics';
import { ErrorOverlay, Section } from '@liasincontrol/ui-elements';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { AppSettingsService } from '@liasincontrol/config-service';
import { ActionSource, AjaxRequestStatus, useElementDefinitions, useModules } from '@liasincontrol/redux-service';
import { LsGrid, GridColumn, ContextMenu } from '@liasincontrol/ui-devextreme';
import { Performance } from '@liasincontrol/data-service';
import { ApiErrorReportingHelper } from '@liasincontrol/core-service';
import { UpdateDefinitionNameDialog } from './UpdateDefinitionNameDialog';

export type RowType = {
    elementDefinitionId: string,
    value: Domain.Performance.HierarchyItemElementType,
    label: string
}

/**
 * Represents a UI component that renders the list of performance element definitions.
 */
const Index: React.FC = () => {
    const modules = useModules(ActionSource.Performance);
    const [performanceModuleId, setPerformanceModuleId] = useState(null);
    const { elementDefinitions, fetchElementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: performanceModuleId });

    const [performanceElementDefinitions, setPerformanceElementDefinitions] = useState<{
        items: { elementDefinitionId: string, value: Domain.Performance.HierarchyItemElementType, label: string }[];
        totalCount: number;
    }>({ items: [], totalCount: 0 });

    // #region Grid state...
    const availableColumns = getColumnConfiguration((item: RowType) => {
        setEditDefinitionNameDialogControls({ showDialog: true, definitionId: item.elementDefinitionId });
    });

    // #endregion...
    const [editDefinitionNameDialogControls, setEditDefinitionNameDialogControls] = useState<{ showDialog: boolean, definitionId: string }>
        ({ showDialog: false, definitionId: undefined });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [externalError, setExternalError] = useState<{ id: string, message: string }>();

    useEffect(() => {
        if (modules?.items?.[SystemModuleDefinitions.Performance]?.id) {
            setPerformanceModuleId(modules.items[SystemModuleDefinitions.Performance].id);
        }
    }, [modules]);

    useEffect(() => {
        if (!elementDefinitions || !elementDefinitions.items) {
            return;
        }

        const availableTypes = Object.keys(elementDefinitions.items)
            .filter((elementDefinitionId) => {
                return elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Policy
                    || elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Goal
                    || elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Activity
                    || elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Achievement
                    || elementDefinitions.items[elementDefinitionId].systemId === Domain.SystemElementDefinitions.Performance.Indicator
            })
            .map((elementDefinitionId) => {
                return {
                    elementDefinitionId: elementDefinitions.items[elementDefinitionId].id,
                    value: elementDefinitions.items[elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType,
                    label: elementDefinitions.items[elementDefinitionId].name
                };
            });

        setPerformanceElementDefinitions({
            items: availableTypes,
            totalCount: availableTypes.length
        });
    }, [elementDefinitions]);

    if (!modules || modules.status === AjaxRequestStatus.NotSet ||
        !elementDefinitions || elementDefinitions.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    const saveDefinitionName = (definitionId: string, definitionName: string) => {
        if (!definitionId || !definitionName) {
            return;
        }

        let keepOpen = false;
        Performance.HierarchyDataAccessor.updatePerformanceElementDefinitionName(definitionId, definitionName)
            .then(() => {
                fetchElementDefinitions(ActionSource.Performance, { moduleId: performanceModuleId });
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, err);
                if (errorInfo.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceElementDefinitionNameNotUnique)) {
                    const message = Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceElementDefinitionNameNotUnique];
                    keepOpen = true;
                    setExternalError({
                        id: Domain.FieldDefinitions.Shared.nameFieldDefinition.id,
                        message: message,
                    });
                } else {
                    setError(errorInfo);
                }
            })
            .finally(() => setEditDefinitionNameDialogControls(prev => ({ showDialog: keepOpen, definitionId: keepOpen ? prev.definitionId : undefined })))
    };

    return (
        <>
            <WrapperContent>
                <PageTitle>
                    <Heading1>Entiteiten</Heading1>
                </PageTitle>

                <Section look='white'>
                    <ErrorOverlay
                        error={error?.message}
                        errorDetails={error?.details}
                        onBack={error?.canGoBack ? () => setError(undefined) : null}>
                        {availableColumns && (
                            <LsGrid
                                keyExpr='elementDefinitionId'
                                dataSource={performanceElementDefinitions.items}
                                enableColumnChooser={false}
                                columns={availableColumns}
                                showRowLines={true}
                                paging={{ pageSize: AppSettingsService.getAppSettings().General.PageSize }}
                            />
                        )}
                    </ErrorOverlay>
                </Section>
            </WrapperContent>

            {editDefinitionNameDialogControls.showDialog && (
                <UpdateDefinitionNameDialog
                    onClose={() => setEditDefinitionNameDialogControls({ ...editDefinitionNameDialogControls, showDialog: false })}
                    definitionId={editDefinitionNameDialogControls.definitionId}
                    definitionName={performanceElementDefinitions.items?.find(i => i.elementDefinitionId === editDefinitionNameDialogControls.definitionId)?.label}
                    onSave={(definitionId, definitionName) => saveDefinitionName(definitionId, definitionName)}
                    externalError={externalError}
                />
            )}
        </>
    );
};

const getColumnConfiguration = (onChangeName: (item: RowType) => void): GridColumn<RowType>[] => {
    return [
        {
            name: 'label',
            title: 'Naam',
            allowSorting: false,
        },
        {
            name: 'elementDefinitionId',
            title: '',
            type: 'buttons',
            width: '5%',
            minWidth: 50,
            align: 'right',
            hideInColumnChooser: true,
            renderCustom: ({ data }) => (
                <ContextMenu<RowType>
                    keyExpr='elementDefinitionId'
                    item={data}
                    actions={[
                        {
                            action: onChangeName,
                            ariaLabel: `Bewerken ${data.label}`,
                            displayName: 'Bewerken',
                            actionName: `edit-${data.elementDefinitionId}`
                        }
                    ]}
                />)
        }];
};

export { Index as index };
