import React, { useRef, useMemo, useState, useEffect } from 'react';
import HtmlEditor, { HtmlEditorRef, Item, TableResizing } from 'devextreme-react/html-editor';
import Styled from './index.style';
import { ContentReadyEvent, FocusOutEvent, InitializedEvent } from 'devextreme/ui/html_editor_types';
import LinkPopup from './LinkPopup';

export type UrlState = {
    popupVisible: boolean,
    index: number,
    length: number,
    type: UrlTypes,
    text: string,
    url: string
}

export type UrlTypes = 'External' | 'Internal';

type Props = {
    readonly id: string,
    readonly value: string,
    readonly isToolbarHidden?: boolean,
    readonly className?: string,
    readonly style?: React.CSSProperties,
    readonly locale?: string,
    readonly height?: string | number,
    readonly onChange: (value: string) => void,
    readonly onFocusIn?: ((e: {}) => any),
    readonly onFocusOut?: ((e: {}) => any),
    readonly onContentReady?: ((e: ContentReadyEvent) => any),
    readonly onInitialized?: ((e: InitializedEvent) => any),
    readonly withTextAssistant?: boolean,
    readonly onTextAssistant?: () => void,
    readonly canMaximize?: boolean,
    readonly theme?: 'light' | 'dark',
    readonly withSitemapLinks?: boolean,
    readonly sitemapsource?: any[],
}

const headerValues = [false, 2, 3, 4];

/**
 * Represents a UI component that renders a richtext editor.
 */
export const RichTextEditor: React.FC<Props> = (props) => {
    const htmlEditorRef = useRef<HtmlEditorRef>(null);

    //Original
    const [originalUrl, setOriginalUrl] = useState<UrlState>({ popupVisible: false, index: 0, length: 0, type: 'External', text: '', url: '' });

    const textAssistantOptions = useMemo(() => {
        return {
            text: 'Tekstassistent',
            icon: `<svg version="1.1" id="Capa_1"
                       xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                       <g>
                           <path style="fill:none;stroke:rgba(0,0,0,.87);stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M127.467 432.733H111.4c-35.47 0-64.267-28.8-64.267-64.267 0-26.598 21.598-48.2 48.2-48.2H79.267C43.797 320.267 15 291.467 15 256s28.797-64.267 64.267-64.267h16.067c-26.602 0-48.2-21.602-48.2-48.2 0-35.467 28.797-64.267 64.267-64.267h16.067M384.533 432.733H400.6c35.47 0 64.267-28.8 64.267-64.267 0-26.598-21.598-48.2-48.2-48.2h16.067c35.47 0 64.267-28.8 64.267-64.267s-28.797-64.267-64.267-64.267h-16.067c26.602 0 48.2-21.602 48.2-48.2 0-35.467-28.797-64.267-64.267-64.267h-16.067"/>
                           <path style="fill:none;stroke:rgba(0,0,0,.87);stroke-width:30;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10" d="M159.6 111.4c-17.735 0-32.133-14.396-32.133-32.133C127.467 43.8 156.264 15 191.733 15S256 43.8 256 79.267C256 43.8 284.797 15 320.267 15s64.267 28.8 64.267 64.267c0 17.738-14.398 32.133-32.133 32.133M159.6 400.6c-17.735 0-32.133 14.396-32.133 32.133 0 35.467 28.797 64.267 64.267 64.267S256 468.2 256 432.733C256 468.2 284.797 497 320.267 497s64.267-28.8 64.267-64.267c0-17.738-14.398-32.133-32.133-32.133M175.667 304.2v-64.267a32.118 32.118 0 0 1 9.412-22.718A32.106 32.106 0 0 1 207.8 207.8a32.103 32.103 0 0 1 22.721 9.415 32.118 32.118 0 0 1 9.412 22.718V304.2M175.667 272.067h64.266M288.133 207.8h48.2M288.133 304.2h48.2M312.233 207.8v96.4M256 79.267v64.266M256 432.733v-64.266"/>
                       </g>
                   </svg>`,
            //TODO: Fix error when trying to use function instead of hardcoded string
            //icon: textAssistantSvgIconStringWithColor(fillColor),
            stylingMode: 'text',
            hint: 'Tekstassistent',
            onClick: props.onTextAssistant,
        };
    }, [props.onTextAssistant, props.theme]);

    const headerOptions = useMemo(() => {
        return {
            displayExpr: (item) => {
                switch (item) {
                    case 2:
                        return 'Titel';
                    case 3:
                        return 'Subtitel groot';
                    case 4:
                        return 'Subtitel klein';
                    default:
                        return 'Normale tekst';
                }
            }
        };
    }, [props.theme]);

    const insertLinkOptions = useMemo(() => {
        return {
            icon: 'link',
            stylingMode: 'text',
            hint: 'Voeg een link toe',
            className: 'custom-toolbar-button',
            onClick: () => {
                const url: UrlState = { popupVisible: true, index: 0, length: 0, type: 'External', text: '', url: '' };
                const selection = htmlEditorRef.current?.instance().getSelection();

                if (selection) {
                    const formats = htmlEditorRef.current?.instance().getFormat(selection.index, selection.length);
                    let startIndex = +selection.index;
                    let length = +selection.length;

                    if (formats.link) {
                        // Expand selection to include the full range of the link
                        let fullLinkStart = startIndex;
                        let fullLinkEnd = startIndex + length;

                        // Move backwards to find the start of the link
                        while (fullLinkStart > 0) {
                            const prevFormat = htmlEditorRef.current?.instance().getFormat(fullLinkStart - 1, 1);
                            if (prevFormat.link) {
                                fullLinkStart--;
                            } else {
                                break;
                            }
                        }

                        // Move forwards to find the end of the link
                        while (fullLinkEnd < htmlEditorRef.current?.instance().getLength()) {
                            const nextFormat = htmlEditorRef.current?.instance().getFormat(fullLinkEnd, 1);
                            if (nextFormat.link) {
                                fullLinkEnd++;
                            } else {
                                break;
                            }
                        }

                        // Update the selection to cover the full link text
                        startIndex = fullLinkStart;
                        length = fullLinkEnd - fullLinkStart;

                        const fullLinkText = htmlEditorRef.current?.instance().getText(startIndex, length);

                        url.type = formats.link.startsWith('#') ? 'Internal' : 'External';
                        url.url = formats.link.replace('#', '');
                        url.text = fullLinkText || '';
                    } else {
                        const text = htmlEditorRef.current?.instance().getText(startIndex, length);
                        url.text = text || '';
                    }

                    url.index = startIndex;
                    url.length = length;
                }
                setOriginalUrl(url);
            },
        };
    }, [htmlEditorRef, setOriginalUrl]);

    const insertLink = (newtype, newtext, newurl, toIndex, toRemove) => {
        const editor = htmlEditorRef.current?.instance();

        const linkObject = {};
        linkObject['href'] = newtype === 'External' ? newurl : `#${newurl}`;
        linkObject['target'] = newtype === 'External' ? "_blank" : "_self";
        linkObject['text'] = newtext;

        if (editor) {
            if (toRemove > 0) {
                editor.delete(toIndex, toRemove);
            }
            editor.insertEmbed(toIndex, "link", linkObject);
            // space at the end of link
            editor.insertText(toIndex + newtext.length, ' ', { link: false });
            handleFocus(editor, toIndex);
        }
    };

    const handleFocus = (editor, position = 0) => {
        if (editor) {
            // Delay the focus slightly to allow the DOM to stabilize
            setTimeout(() => {
                editor.setSelection(position, 0);
                editor.focus();
            }, 0);
        }
    };

    const resetOriginalUrl = () => {
        setOriginalUrl({ popupVisible: false, index: 0, length: 0, type: 'External', text: '', url: '' });
    };

    useEffect(() => {
        const editorInstance = htmlEditorRef.current?.instance();
        if (editorInstance) {
            const quillInstance = editorInstance.getQuillInstance();

            const onSelectionChange = (range) => {
                const toolbarButton = editorInstance.element().querySelector('.custom-link-toolbar-button [role="button"]');

                if (range) {
                    const formats = quillInstance.getFormat(range.index, range.length);
                    if (formats.link) {
                        toolbarButton?.classList.add('dx-format-active', 'dx-state-selected');
                    } else {
                        toolbarButton?.classList.remove('dx-format-active', 'dx-state-selected');
                    }
                } else {
                    toolbarButton?.classList.remove('dx-format-active', 'dx-state-selected');
                }
            };
            quillInstance.on('selection-change', onSelectionChange);
            return () => {
                quillInstance.off('selection-change', onSelectionChange);
            };
        }
    }, [htmlEditorRef]);

    return (<>
        <Styled.Wrapper className={props.className} style={props.style}>
            <HtmlEditor
                id={props.id}
                ref={htmlEditorRef}
                className='htmlEditor'
                defaultValue={props.value}
                height={props.height}
                allowSoftLineBreak={true}
                onFocusIn={(e) => {
                    props.onFocusIn?.(e);
                }}
                onFocusOut={(e: FocusOutEvent) => {
                    props.onFocusOut?.(e);
                }}
                onValueChanged={(element) => {
                    props.onChange(element.value)
                }}
                onContentReady={(e) => {
                    props.onContentReady?.(e);
                }}
                onInitialized={(e) => {
                    const editor = e.component;
                    const originalToolbar = editor?.get("modules/toolbar");
                    class extendedToolbar extends originalToolbar {
                        get toolbarConfig() {
                            return {
                                dataSource: this._prepareToolbarItems(),
                                disabled: this.isInteractionDisabled,
                                menuContainer: "body",
                                multiline: this.isMultilineMode()
                            };
                        }
                    }
                    editor?.register({
                        'modules/toolbar': extendedToolbar,
                    });
                    props.onInitialized?.(e);
                }}
            >
                <TableResizing enabled={false} />
                <Styled.StyledToolbar>
                    <Item name='undo' />
                    <Item name='redo' />
                    <Item name="separator" />
                    <Item name="header" acceptedValues={headerValues} options={headerOptions} />
                    <Item name="separator" />
                    <Item name='bold' />
                    <Item name='italic' />
                    <Item name='underline' />
                    <Item name='strike' />
                    <Item name='link' visible={!props.withSitemapLinks} />
                    <Item cssClass='custom-link-toolbar-button' widget="dxButton" visible={props.withSitemapLinks} options={insertLinkOptions} />
                    <Item name='separator' />
                    <Item name='orderedList' />
                    <Item name='bulletList' />
                    <Item name='separator' />
                    <Item name='color' />
                    <Item name='background' />
                    <Item name='separator' />
                    <Item name='alignLeft' />
                    <Item name='alignCenter' />
                    <Item name='alignRight' />
                    <Item name='separator' />
                    <Item name='clear' />
                    <Item name='separator' />
                    <Item name='insertTable' />
                    <Item name='insertHeaderRow' />
                    <Item name='deleteTable' />
                    <Item name='insertRowAbove' />
                    <Item name='insertRowBelow' />
                    <Item name='deleteRow' />
                    <Item name='insertColumnLeft' />
                    <Item name='insertColumnRight' />
                    <Item name='deleteColumn' />
                    <Item name='separator' visible={!!props.withTextAssistant} />
                    <Item name='variable' options={textAssistantOptions} visible={!!props.withTextAssistant} />
                </Styled.StyledToolbar>
            </HtmlEditor>
        </Styled.Wrapper>

        {originalUrl?.popupVisible &&
            <LinkPopup
                originalUrl={originalUrl}
                sitemapsource={props.sitemapsource}
                insertLink={(type, text, url) => {
                    insertLink(type, text, url, originalUrl.index, originalUrl.length);
                    resetOriginalUrl();
                }}
                onClose={() => {
                    resetOriginalUrl();
                }}
            />
        }
    </>
    );
};
