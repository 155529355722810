import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Overlay } from '../overlay';
import { TooltipBox } from './box';

/**
 * Prevents click on add from bubbling up.
 * Prevent mouse down from bubbling up when add popup is opened.
 */
export const Tooltip = ({
    children,
    content,
    maxWidth,
    isTooltipVisible,
    displayOverlay
}: {
    children?: React.ReactNode;
    content: React.ReactNode;
    maxWidth?: number;
    isTooltipVisible?: boolean;
    displayOverlay?: boolean;
}) => {
    const toolbox = useRef<HTMLDivElement>(null);
    const [overlay, setOverlay] = useState<boolean>(false);
    const [position, setPosition] = useState<{ left: number; top: number } | null>(null);
    const onShow: React.MouseEventHandler<HTMLDivElement> = (e) => {
        const b = e.currentTarget.getBoundingClientRect();
        const left = b.left + b.width / 2;
        const top = b.top + b.height / 2;
        setPosition({ left, top });
        setOverlay(true);
        e.stopPropagation();
    };

    useEffect(() => {
        if (overlay && typeof window !== `undefined`) {
            // Close when clicking outside of toolbox or scrolling outside of toolbox
            const closeOverlay = (e: Event) => {
                if (e.target !== toolbox.current) {
                    setOverlay(false);
                }
            };

            window.addEventListener(`mousedown`, closeOverlay);
            window.addEventListener(`scroll`, closeOverlay, { capture: true });

            return () => {
                window.removeEventListener(`mousedown`, closeOverlay);
                window.removeEventListener(`scroll`, closeOverlay, { capture: true });
            };
        }
    }, [overlay]);

    const onClickBox = () => {
        setOverlay(false);
    };

    useEffect(() => {
        setOverlay(isTooltipVisible);
    }, [isTooltipVisible]);

    const popOver = (
        <LayerPortal>
            <div onMouseDown={stopPropagation}>
                <TooltipBox children={content} style={{ position: `relative`, ...position, maxWidth: maxWidth }} visible={Boolean(overlay)} onClick={onClickBox} ref={toolbox} />
            </div>
        </LayerPortal>);
    return (
        <Wrapper onClick={onShow}>
            <>
                {displayOverlay &&
                    <Overlay onClick={() => onClickBox} relative visible={overlay} opacity={0.1}>
                        {popOver}
                    </Overlay>
                }
                {!displayOverlay && popOver}
                {children}
            </>
        </Wrapper>
    )
};

const Wrapper = styled.div`
    display: inline-block;
`;

const stopPropagation: React.MouseEventHandler<Element> = (e) => {
    e.stopPropagation();
    e.preventDefault();
};


const usePortalDiv = (attach = true) => {
    const [el] = useState(() => {
        const el = document.createElement(`div`);
        el.style.position = `fixed`;
        el.style.left = `0`;
        el.style.top = `0`;
        return el;
    });

    useLayoutEffect(() => {
        if (attach) {
            document.body.appendChild(el);
            return () => {
                document.body.removeChild(el);
            };
        }
    }, [attach, el]);
    return el;
};


export const LayerPortal = ({ attach = true, children }: { children: React.ReactNode; attach?: boolean }) => {
    const div = usePortalDiv(attach);
    return ReactDOM.createPortal(<>{children}</>, div);
};
