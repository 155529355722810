import styled from 'styled-components';

const StyledCheckBoxContainer = styled.div`
   display: flex;
   align-items: center;
   width: 100%;
   max-width: 100%;
`;

const styledComponents = { StyledCheckBoxContainer };

export default styledComponents;