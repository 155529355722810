import { useSelector } from "react-redux";
import { State, useAppDispatch, PatchesActionCreator, ActionSource } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { useCallback } from "react";
import { PublisherPatchesState } from "../ReduxService/store/state/PublisherState";

type ApplyPatches = (pageId: string, set: { elementId: string, fieldId: string, value: string }[], remove: { elementId: string, fieldId: string }[]) => void;
type ClearPatches = (pageId: string) => void;
type ApplyComplexFieldPatch = (pageId: string, set: { elementId: string, complexFieldDefinitionId: string, rowIndex: number, complexField: Domain.Shared.ComplexField }[], remove: { elementId: string, complexFieldDefinitionId: string, rowIndex: number }[]) => void;

export const usePatches = () => {
    const dispatch = useAppDispatch();
    const patches: PublisherPatchesState = useSelector((state: State) => state.publisher.patches);

    const applyPatches: ApplyPatches = useCallback((pageId: string, set: { elementId: string, fieldId: string, value: string }[], remove: { elementId: string, fieldId: string }[]) => {
        dispatch(PatchesActionCreator.updateField({ source: ActionSource.Publication, data: { pageId, set, remove } }) as any);
    }, []);

    const clearPatches: ClearPatches = useCallback((pageId: string) => {
        dispatch(PatchesActionCreator.clear({ source: ActionSource.Publication, data: { pageId } }) as any);
    }, []);

    const applyComplexFieldPatch: ApplyComplexFieldPatch = useCallback((pageId: string, set: { elementId: string, complexFieldDefinitionId: string, rowIndex: number, complexField: Domain.Shared.ComplexField }[], remove: { elementId: string, complexFieldDefinitionId: string, rowIndex: number }[]) => {
        dispatch(PatchesActionCreator.updateComplexField({ source: ActionSource.Publication, data: { pageId, set, remove } }) as any);
    }, []);

    return { patches, applyPatches, clearPatches, applyComplexFieldPatch };
};
