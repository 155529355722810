import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, RootState, ModulesActionCreator, ActionSource } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

type ModulesType = {
    items: { [moduleSystemId: string]: Domain.Shared.Module },
    status: AjaxRequestStatus
}

export const useModules = (source: ActionSource) => {
    const modules = useSelector<State, ModulesType>((state) => state.modules[source]);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    if (!modules || modules.status === AjaxRequestStatus.NotSet) {
        dispatch(ModulesActionCreator.set({ source: source, data: {} }));
    }

    return modules;
}