import React from 'react';
import { BasicText, Heading2, PageTitle, PanelGrid, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { TextImageWidget } from '@liasincontrol/ui-elements';

/**
 * Represents a UI component that renders the Analytics cockpit view.
 */
const Cockpit: React.FC = () => {
    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit LIAS Analytics</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget
                        title='LIAS Analytics'
                        text='Een grafiek zegt meer dan 100 getallen en maakt het mogelijk om in één opslag de huidige stand 
                        van zaken op te halen. Met LIAS Analytics heeft u direct inzicht in stuurinformatie zonder hier zelf dashboards voor te genereren.'
                        footer={<>
                            <BasicText>Wilt u zelf aan de slag met onze dashboards? Deze dashboards staan voor u klaar in de <a href="https://app.powerbi.com/groups/me/apps" target="_blank" rel="noopener noreferrer">LIAS Analytics</a> app.</BasicText>
                        </>}
                    />
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

export { Cockpit as index };
