import { createAsyncThunk } from '@reduxjs/toolkit';
import { AttachmentsHelper } from '@liasincontrol/core-service';


/**
 * Represents an action that triggers the store to upload an attachment.
 */

const action = createAsyncThunk<string, { file: File, abortSignal: AbortSignal }, {}>('/liasattachments/upload',
    async ({ file, abortSignal }) => {
        const response = await AttachmentsHelper.uploadAttachment(file, abortSignal);
        return response;
    }
);

export default action;

