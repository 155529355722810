import styled, { css } from 'styled-components';
import { px, palette, cssElementLabel } from '@liasincontrol/ui-basics';
import { Look, LookColor } from '.';
import { cssInternalButtonContent, textbuttons_alt_icon_container } from '../../shared/styleguide';

const NavItemCss = ($state: string, $look?: Look) => {
    switch ($state) {
        case 'activeChild': return (css`
            background-color: ${palette.grey3c};
            border-left-color: ${LookColor[$look]?.background || palette.white}; 
            > span {
                font-weight: 900;
            }
            &:hover {
                background-color: ${palette.grey3c};
                border-left-color: ${LookColor[$look]?.background || palette.white}; 
            }
        `);
        case 'child': return (css`
            background-color: ${palette.grey4};
            border-left-color: ${palette.grey4};
            &:hover {
                background-color: ${palette.grey3c};
                border-left-color: ${palette.grey4};
            }
        `);
        case 'activeGroup': return (css`
            background-color: ${palette.grey4};
            > span {
                font-weight: 900;
            }
            &:hover {
                background-color: ${palette.grey4};
            }
        `);
        case 'group': return (css`
            background-color: ${palette.white};
            &:hover {
                background-color: ${palette.white};
            }
        `);
        case 'hoveredMenuItem': return (css`
            background-color: ${palette.white};
            &:hover {
                background-color: ${palette.grey4};
            }
        `);
    }
};

const Container = styled.nav<{ $collapsed: boolean }>`
    height: 100%;
    min-width: 0;
    background-color: ${palette.white};
    box-shadow: 0 0 ${px(8)} ${px(-4)} rgba(0, 0, 0, 0.2);
    width: ${({ $collapsed: collapsed }) => (collapsed ? px(48) : px(280))};
    transition: width 0.3s ease;
`;

const GroupListbox = styled.div<{ $active: boolean }>`
    display: flex;
    flex-flow: column;

    > div {
        visibility: hidden;
        transition: visibility 0.5s;
    }

    > a > span > span {
        border: 0 !important;
        ${({ $active: active }) => (active ? NavItemCss('activeGroup') : NavItemCss('group'))}
    }

    &:hover {
        > a > span > span {
            ${NavItemCss('activeGroup')}
        }
        > div {
            visibility: visible;
            transition: visibility 0.5s;
        }
    }
`;

const ItemContainerCommonCss = css`
    text-decoration: none;
    position: relative;
    ${textbuttons_alt_icon_container}
    border-radius: 0;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const GroupItemContainer = styled.span<{ $active: boolean }>`
    ${ItemContainerCommonCss}
    > span {
        padding: ${px(12)} ${px(16)};
        border: 0;
        border-radius: 0;
        width: 100%;
    }
`;

const MenuItemContainer = styled.div<{ $active: boolean, $look: Look }>`
    ${ItemContainerCommonCss}
    > span {
        border-radius: 0;
        width: 100%;
        padding: ${px(2)} ${px(12)};
        border-left: ${px(4)} solid;
        &:hover {
            border-left: ${px(4)} solid;
        }
        ${({ $active: active, $look: look }) => (active ? NavItemCss('activeChild', look) : NavItemCss('child'))}
    }
`;

const FloatingMenu = styled.div<{ $collapsed: boolean }>`
    position: absolute;
    height: min-content;
    z-index: 1;
    left: ${({ $collapsed: collapsed }) => collapsed ? px(48) : px(280)};
    width: ${px(240)};
    background-color: ${palette.white};
    box-shadow: ${px(2)} ${px(2)} ${px(2)} ${palette.grey4};    
    > span {
        ${cssElementLabel}
        padding: ${px(18)} ${px(16)};
        font-weight: 700;
    }
`;

const FloatingMenuItem = styled.div`
    position: relative;
    width: 100%;
    height: ${px(40)};
    padding: 0 ${px(16)};
    display: flex;
    &:hover {
        ${NavItemCss('activeGroup')}
    }
`;

const ButtonContent = styled.span<{ $cockpitItem?: boolean, $look?: Look }>`
    padding: 0;
    ${cssInternalButtonContent}
    transition: unset;
    grid-template-columns: min-content auto;
    justify-items: start;
    > div {
        ${({ $look: look, $cockpitItem: cockpitItem }) => (cockpitItem && `background-color: ${LookColor[look].background};`)}

        > svg {
        ${({ $look: look, $cockpitItem: cockpitItem }) => (look ? (cockpitItem ? `fill: ${LookColor[look].fill}; stroke: ${LookColor[look].fill};` : `fill: ${LookColor[look].background}; stroke: ${LookColor[look].background};`) : '')}
        }
    }
`;

const ButtonText = styled.span`
    ${cssElementLabel}
    font-weight: 600;
    white-space: nowrap;
`;

const HiddenText = styled.p`
    display: none;
`;

const styledComponents = {
    Container, GroupListbox, ButtonContent, ButtonText, HiddenText,
    FloatingMenu, FloatingMenuItem, MenuItemContainer, GroupItemContainer
};

export default styledComponents;
