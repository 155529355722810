import React from "react";
import { Button } from 'devextreme-react/button';
import { parseNumber } from "devextreme/localization";
import { withField, WithFieldBaseType } from '../field';
import Styled from './index.styled';

export type NumberFieldProps = WithFieldBaseType<number> & {
    format?: string,
    startValue?: number,
    min?: number,
    max?: number,
    step?: number,
    showSpinButtons?: boolean,
    showClearButton?: boolean,
}

export const NumberField = withField<number, NumberFieldProps>(
    (props) => {
        const { onChange, label, startValue, value, step, min, max, id, format, ...rest } = props;
        const innerStep = step && step >= 0 ? step : 1;
        const innerValue = value ?? 0;

        const enableDecrease = (min === undefined || (min !== undefined && min <= innerValue - innerStep));
        const enableIncrease = (!max || (max && max >= innerValue + innerStep));

        const decreaseValue = innerValue - innerStep;
        const increaseValue = innerValue + innerStep;

        const onInput = (e) => {
            const stringVal = e.event.target.value || '';
            const format = e.component.option("format") || '';
            const numberVal = parseNumber(stringVal, format);
            onChange?.(numberVal);
        }

        return <Styled.Wrapper $showSpinButtons={props.showSpinButtons}>
            {props.showSpinButtons && <Button id={`btn-decrease-${id}`} icon="minus" onClick={() => onChange?.(decreaseValue)} disabled={rest.disabled || !enableDecrease} />}
            <Styled.StyledNumberBox
                {...rest}
                step={innerStep}
                min={min}
                max={max}
                defaultValue={startValue}
                value={innerValue}
                showSpinButtons={false}
                format={format}
                inputAttr={{ id: props.id }}
                onInput={onInput}
            />
            {props.showSpinButtons && <Button id={`btn-increase-${id}`} icon="plus" onClick={() => onChange?.(increaseValue)} disabled={rest.disabled || !enableIncrease} />}
        </Styled.Wrapper >;
    });