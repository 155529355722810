import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { Finance as FinanceDataAccess, oDataResponse } from '@liasincontrol/data-service';

const action = createAsyncThunk<oDataResponse<Domain.Finance.BudgetJournalGroup[]>, void, {}>('/finance/budgetJournalGroup', async () => {
    return FinanceDataAccess.BudgetJournalGroupDataAccessor.getAll()
        .then((response) => response.data);
});

export default action;
