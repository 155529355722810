import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import styled from 'styled-components';
import { ColorPickerProps } from './index.props';
import { AutoFocus, px, palette, Label } from '@liasincontrol/ui-basics';
import { HelpText, Popover } from '@liasincontrol/ui-devextreme';

export interface IColorResult {
    hsl: { h: number; s: number; l: number };
    hex: string;
    rgb: { r: number; g: number; b: number };
    hsv: { h: number; s: number; v: number };
    oldHue: number;
    source: string;
}

export interface IPickerProps {
    readonly id: string;
    readonly ariaLabel?: string;
    readonly title?: string;
    readonly size?: 'default' | 'small';
    readonly withBorder?: boolean;
    readonly color?: string;
    readonly colors?: string[];
    readonly defaultColor?: string;
    readonly disabled?: boolean;
    readonly onColorChange?: (color: string) => void;
    readonly helpText?: { title?: string, text: string, type?: 'info' | 'warning' | 'hint' } | null;
}

export const colorPickerDefaultPalette = {
    colors: ['#D9E3F0', '#F47373', '#697689', '#37D67A', '#2CCCE4', '#ff8a65', '#ba68c8', '#fff'],
};

/**
 * Represents a UI component that renders a color picker editor.
 */
export const ColorPickerEditor: React.FC<ColorPickerProps> = (props) => {
    const value = props.value ? props.value : props.defaultColor;
    const [visible, setVisible] = useState(false);

    const hide = () => {
        setVisible(false);
    };

    const show = () => {
        setVisible(true);
    };

    const onColorChangeInternal = (colorResult) => {
        props.editorSettings?.onChange?.(colorResult.hex);
    };

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === 'Escape') {
            hide();
        }
    };

    return (
        <Container className='color-picker' title={props.title}>
            {props.title && <Label>{props.title}</Label>}
            {props.helpText && props.helpText.text && <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>}
            <SwatchWrap btnSize={props.size || 'default'}>
                <SwatchBtn
                    id={`btn-color-picker-${props.id}`}
                    btnSize={props.size || 'default'}
                    btnColor={value || props.defaultColor || palette.grey1}
                    onClick={show}
                    aria-label={props.ariaLabel}
                    disabled={props.editorSettings?.disabled}>
                    Kleurvoorbeeld {props.title}
                </SwatchBtn>
                <Popover
                    target={`#btn-color-picker-${props.id}`}
                    wrapperAttr={{ class: 'lias-popover without-padding' }}
                    position={'bottom'}
                    maxWidth={320}
                    visible={visible}
                    onHiding={() => setVisible(false)}
                    shading={true}
                    shadingColor="rgba(0, 0, 0, 0.2)"
                    hideOnOutsideClick={true}
                    hideOnParentScroll={true}
                >
                    <div tabIndex={0} onKeyDown={onKeyDown}>
                        <AutoFocus key={visible ? 1 : 0} delay={100}>
                            <SketchPicker
                                color={value || props.defaultColor}
                                presetColors={props.colors || colorPickerDefaultPalette.colors}
                                onChange={(color) => onColorChangeInternal(color)}
                                disableAlpha={true} />
                        </AutoFocus>
                    </div>
                </Popover>
            </SwatchWrap>
            {props.withBorder && <div style={{ height: '1rem', borderBottom: `2px solid ${palette.grey4}`, marginBottom: '20px' }} />}
        </Container>
    );
};


interface IBtnProps {
    readonly btnSize: string;
    readonly btnColor: string;
}

interface IWrapProps {
    readonly btnSize: string;
}

const Container = styled.div`
    margin-bottom: 2rem;
`;

const SwatchWrap = styled.div<IWrapProps>`
    position: relative;
    overflow: visible;
    width: ${(p) => (p.btnSize === 'small' && px(40)) || px(56)};
`;

const SwatchBtn = styled.button<IBtnProps>`
    display: block;
    padding: 0;
    margin: 0;
    border: 0 none;
    outline: 0;
    text-indent: -9999px;
    cursor: ${(p) => (!p.disabled && 'pointer') || 'default'};

    width: ${(p) => (p.btnSize === 'small' && px(40)) || px(56)};
    height: ${(p) => (p.btnSize === 'small' && px(40)) || px(56)};
    border-radius: 50%;
    border: 1px solid ${palette.grey3};

    background-color: ${(p) => p.btnColor};
    transition: background-color 0.3s ease;
`;
