import React from 'react';
import { PrivateRoute } from '@liasincontrol/ui-basics';
import { Shared } from '../index';
import { Route } from 'react-router-dom';

const Menu = (
    <>
        <Route path='*' element={<PrivateRoute component={Shared.Menu} />} />
    </>
);

export { Menu as PerformanceMenu };