import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { ModulesActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the modules reducer.
 */
const reducer = createReducer<{
    [service: string]: {
        items: { [moduleSystemId: string]: Domain.Shared.Module },
        status: AjaxRequestStatus,
    }
}>({}, (builder) => {
    return (
        builder.addCase(ModulesActionCreator.set.fulfilled, (state, action) => {
            const list = action.payload.data.value.reduce(
                (collection, item) => ({ ...collection, [item.systemId]: item }),
                {}
            );
            state[action.payload.source] = {
                items: list,
                status: AjaxRequestStatus.Done
            }
            return state;
        }),
        builder.addCase(ModulesActionCreator.set.pending, (state, action) => {
            state[action.meta.arg.source] = {
                items: {},
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(ModulesActionCreator.set.rejected, (state, action) => {
            state[action.meta.arg.source] = {
                items: {},
                status: AjaxRequestStatus.Failed,
            };
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
