import buildQuery, { OrderByOptions } from 'odata-query';
import _ from 'lodash';
import { AxiosResponse, CancelToken } from 'axios';
import { oDataResponse, oDataResponseStructured } from '@liasincontrol/data-service';

import CustomStore from 'devextreme/data/custom_store';
import DataSource from 'devextreme/data/data_source';

interface DataSourceProps<T> {
    readonly selectedColumns?: string[];
    readonly dataSourcePromise: (query?: string, cancelToken?: CancelToken) => Promise<AxiosResponse<oDataResponse<T[]> | oDataResponseStructured<T>, any>>;
    readonly keyExpr?: string;
    readonly paginate?: boolean;
    readonly pageSize?: number;
    readonly filter?: Record<string, any>;
    readonly dataSourceMode?: 'custom' | 'standard';
    readonly thenCallBack?: (data: oDataResponse<T[]> | oDataResponseStructured<T>) => any; //T[] | oDataResponseStructured<T> or oDataResponse<T[]> | oDataResponseStructured<T>
    readonly columnMapper?: (columnName: string) => string;
}

const createStore = <T extends {}>(props: DataSourceProps<T>) => {
    return new CustomStore({
        key: props.keyExpr,
        load: (loadOptions) => {
            if (!loadOptions) {
                return Promise.resolve([]);
            }
            const selectedColumns = props.selectedColumns ?? null;

            //Todo: verify if this still works
            //type OrderByOptions<T> = keyof T | [keyof T, "asc" | "desc"]
            // const orderBy = _.isArray(loadOptions.sort) ? _.uniqBy(loadOptions.sort, "selector")?.map((column) => {
            //     if (props.columnMapper) {
            //         const colname = props.columnMapper(column['selector']);
            //         return (`${colname}, ${column['desc'] ? 'desc' : 'asc'}`);
            //     }
            //     return (`${column['selector']}, ${column['desc'] ? 'desc' : 'asc'}`);
            // }) : [];

            const orderBy = _.isArray(loadOptions.sort) ? _.uniqBy(loadOptions.sort, "selector")?.map((column) => {
                if (props.columnMapper) {
                    const colname = props.columnMapper(column['selector']);
                    return [colname, column['desc'] ? 'desc' : 'asc'] as OrderByOptions<T>;
                }
                return [column['selector'] as keyof T, column['desc'] ? 'desc' : 'asc'] as OrderByOptions<T>;
            }) : [];

            const searchExpression = _.isArray(loadOptions.filter) ? loadOptions.filter[0]?.filterValue : '';

            const escapedSearchTerm = !!searchExpression ? `${encodeURIComponent(`"${searchExpression.replace(/[\\"]/g, '\\$&')}"`)}` : '';

            const query = buildQuery({
                select: selectedColumns?.join(','),
                search: escapedSearchTerm,
                orderBy,
                top: loadOptions.take,
                count: true,
                skip: loadOptions.skip,
                filter: props.filter
            });

            if (props.dataSourceMode === 'custom') {
                return props.dataSourcePromise(query)
                    .then((response: AxiosResponse<oDataResponseStructured<T>>) => {
                        if (!response) return { data: [], totalCount: 0 }
                        if (props.thenCallBack) {
                            const items = props.thenCallBack(response.data);
                            return { data: items, totalCount: response.data.totalCount || 0 };
                        }
                        return { data: response.data.values, totalCount: response.data.totalCount || 0 };
                    });
            }

            return props.dataSourcePromise(query)
                .then((response: AxiosResponse<oDataResponse<T[]>>) => {
                    if (!response) return { data: [], totalCount: 0 }
                    if (props.thenCallBack) {
                        const items = props.thenCallBack(response.data);
                        return { data: items, totalCount: response.data["@count"] };
                    }
                    return { data: response.data.value, totalCount: response.data["@count"] };
                });
        },
        loadMode: 'processed',
        cacheRawData: true,
    });
}

export const createSource = <T extends {}>(props: DataSourceProps<T>) => {
    return new DataSource({
        paginate: props.paginate,
        pageSize: props.pageSize,
        store: createStore(props),
        reshapeOnPush: true,
        cacheRawData: true,
    });
};
