import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, RootState, LiasAttachmentActionCreator, ActionSource } from '@liasincontrol/redux-service';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { unwrapResult } from '@reduxjs/toolkit';

type AttachmentType = {
    attachment: File;
    status: AjaxRequestStatus;
}

// Deal with a single attachment
export const useAttachment = (attachmentId?: string, fileName?: string) => { //Domain.Shared.Attachment
    const attachment = useSelector<State, AttachmentType>((state) => state.liasAttachments[attachmentId]);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    const fetchAttachment = (attachmentId?: string, fileName?: string) => {
        dispatch(LiasAttachmentActionCreator.fetch({ attachmentId, fileName }));
    };

    if (attachmentId && (!attachment?.status || attachment?.status === AjaxRequestStatus.NotSet)) {
        fetchAttachment(attachmentId, fileName);
    }

    return { attachment, fetchAttachment };
};

// Deal with all attachments
export const useAttachments = () => {
    const attachments = useSelector<State, Record<string, AttachmentType>>((state) => state.liasAttachments);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    const uploadAttachment = async (file: File, abortSignal: AbortSignal): Promise<string> => {
        const actionResult = await dispatch(LiasAttachmentActionCreator.upload({ file, abortSignal }));
        const result = unwrapResult(actionResult);
        const newAttachmentId = result;
        return newAttachmentId;
    };

    const removeAttachment = (attachmentId: string) => {
        dispatch(LiasAttachmentActionCreator.remove({ source: ActionSource.Publication, data: { attachmentId } }));
    };

    return { attachments, uploadAttachment, removeAttachment };
};
