import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import { CardHeaderTab, useComponentBounds } from '@liasincontrol/ui-basics';
import { Section } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { Performance } from '@liasincontrol/data-service';
import { DxChart, DxSeriesTemplate, DxCommonSeriesSettings } from '@liasincontrol/ui-devextreme';
import { StatisticsHelper } from '../../../helpers/StatisticsHelper';
import Styled from './index.styled';
import { ActionSource, AjaxRequestStatus, useElementDefinitions, useMeasureMoments } from '@liasincontrol/redux-service';
import { isMomentOpen } from '@liasincontrol/core-service';

type Props = {
    performanceModuleId: string,
};

/**
 * Represents a UI component that renders a tabbed navigation with performance bar graphs.
 */
const PerformanceCharts: React.FC<Props> = (props) => {
    const { measureMoments } = useMeasureMoments();
    const { elementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: props.performanceModuleId });
    const [countedHierarchyItems, setCountedHierarchyItems] = useState<Record<string, Record<string, number>>>({});
    const [selectedCardTab, setSelectedCardTab] = useState<Domain.Performance.HierarchyItemElementType>(Domain.SystemElementDefinitions.Performance.Goal);

    const chartWrapperRef = useRef<HTMLDivElement>(null);
    const { width: parentWidth, height: parentHeight } = useComponentBounds(chartWrapperRef);

    const types = useMemo(() => {
        if (elementDefinitions?.status !== AjaxRequestStatus.Done) {
            return [];
        }
        return Object.keys(elementDefinitions.items).reduce((filtered, option) => {
            const item = elementDefinitions.items[option];
            const systemId = item.systemId as Domain.Performance.HierarchyItemElementType;
            if (StatisticsHelper.availableTypes.indexOf(systemId) >= 0) {
                const type = {
                    id: option,
                    systemId: systemId,
                    name: item.name,
                    fields: item.fields,
                };
                filtered.push(type);
            }
            return filtered;
        }, []);

    }, [elementDefinitions?.status]);

    useEffect(() => {
        if (measureMoments?.status !== AjaxRequestStatus.Done || elementDefinitions?.status !== AjaxRequestStatus.Done) {
            return;
        }

        // Only the default measuremoment exists at the moment.
        const measureMoment = measureMoments.items.filter((moment) => isMomentOpen(moment.status))?.[0];
        if (!measureMoment) {
            return;
        }
        Performance.HierarchyDataAccessor.get(measureMoment.id).then((result) => {
            setCountedHierarchyItems(StatisticsHelper.getHierarchyItemsStatByTypeAndProgress(result.data.hierarchy, elementDefinitions.items));
        });
    }, [elementDefinitions, measureMoments]);

    const cardHeaderTabs = types.map((type) => {
        return (
            <Styled.CardHeaderWrapper key={`${type.name}-wrapper`} $active={selectedCardTab === type.systemId} >
                <CardHeaderTab id={`btn-${type.name}`} title={type.name} key={type.systemId} onClick={() => setSelectedCardTab(type.systemId)} />
            </Styled.CardHeaderWrapper>
        );
    });

    const getChartData = useCallback((type: { id: string, systemId: Domain.Performance.HierarchyItemElementType, name: string, fields: Domain.Shared.FieldDefinition[] }) => {
        if (!countedHierarchyItems) return null;
        if (!type) return [];
        const statusFieldDefinition = type.fields.find((field) => StatisticsHelper.statusFieldDefinitionIds.indexOf(field.systemId) >= 0);
        const sortedOptions = _.sortBy(statusFieldDefinition?.optionItems, (item) => StatisticsHelper.progressOrder.indexOf(item.order));
        const mappedData = sortedOptions.map((optionItem) => ({
            status: optionItem.name,
            itemCount: countedHierarchyItems[type.systemId] && countedHierarchyItems[type.systemId][optionItem.name] ?
                countedHierarchyItems[type.systemId][optionItem.name] : 0,
        }));
        return mappedData;
    }, [countedHierarchyItems]);

    const chartDataSource = types.reduce((items, t) => ({ ...items, [t.systemId]: getChartData(t) }), {});

    return (
        <Section look='white' padding={true} grid={true} colSpan={2} rowSpan={2} widget={true}>
            <Styled.Header>
                {cardHeaderTabs}
            </Styled.Header>
            {selectedCardTab && countedHierarchyItems && (
                <Styled.Link id={`btn-${selectedCardTab}`} as={Link} to={`/performance/tree/overview/${selectedCardTab}`}>
                    <Styled.Content ref={chartWrapperRef}>
                        <DxChart
                            id="chart"
                            redrawOnResize={true}
                            dataSource={chartDataSource[selectedCardTab]}
                            legend={{ visible: false }}
                            animation={false}
                            adaptiveLayout={{
                                height: 80,
                                width: 80
                            }}
                            size={{
                                width: parentWidth,
                                height: parentHeight - 24,
                            }}
                            commonAxisSettings={{
                                tick: { visible: false },
                                grid: { visible: false },
                            }}
                            valueAxis={{
                                tick: { visible: false },
                                label: { visible: false },
                                grid: { visible: false },
                                showZero: true,
                                opacity: 0,
                                visualRange: {
                                    startValue: -0.01,
                                    endValue: chartDataSource[selectedCardTab]?.some(chd => chd.itemCount > 0) ? undefined : 1,
                                },
                            }}
                            palette={['#68A350', '#FFA500', '#FF0000', '#BCBCC6']}
                            customizeLabel={() => {
                                return {
                                    visible: true,
                                    font: {
                                        color: '#000000',
                                        weight: 500,
                                    },
                                    position: 'inside',
                                    customizeText: (e) => {
                                        return `${e.valueText}`;
                                    },
                                };
                            }}
                        >
                            <DxSeriesTemplate nameField="status" />
                            <DxCommonSeriesSettings
                                valueField="itemCount"
                                argumentField="status"
                                type="bar"
                                barPadding={0.4}
                                ignoreEmptyPoints={true}
                            />

                        </DxChart>
                    </Styled.Content>
                </Styled.Link>
            )}
        </Section >
    );
};

export { PerformanceCharts };
