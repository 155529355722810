import React, { useCallback } from 'react';
import CheckBox from "devextreme-react/check-box";
import { HelpText } from '@liasincontrol/ui-devextreme';
import { ValueChangedEvent } from 'devextreme/ui/check_box_types';
import Styled from './index.styled';
import { withField, WithFieldBaseType } from '../field';

export type CheckBoxFieldProps = WithFieldBaseType<boolean> & {
    altLabel?: string,
};

export const CheckboxField = withField<boolean, CheckBoxFieldProps>(
    (props) => {
        const onValueChanged = useCallback((e: ValueChangedEvent) => {
            props.onChange?.(e.value);
        }, [props.onChange]);

        return <Styled.StyledCheckBoxContainer>
            <CheckBox
                height={props.label ? 48 : 'auto'}
                value={props.value}
                text={props.altLabel}
                disabled={props.disabled}
                elementAttr={{
                    id: props.id,
                    class: "lias-checkbox"
                }}
                iconSize="25"
                onValueChanged={onValueChanged}
            />
            {props.helpText && <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>}
        </Styled.StyledCheckBoxContainer>
    }
);

