import React from "react";
import { LoadIndicator as DxLoadIndicator } from 'devextreme-react/load-indicator';
import styled from "styled-components";

export enum IndicatorSize {
    extrasmall = 12,
    small = 14,
    medium = 20,
    default = 24,
    large = 32,
    extralarge = 48,
    xxl = 80,
}

export type LoadIndicatorProps = {
    variant: IndicatorSize,
    align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent',
}

const AlignDiv = styled.div<{ align?: 'start' | 'end' | 'left' | 'right' | 'center' | 'justify' | 'match-parent' }>`
    text-align: ${({ align }) => align ? align : 'left'};
`;

export const LoadIndicator: React.FC<LoadIndicatorProps> = ({ 
    variant = IndicatorSize.default,
    align = 'left',
}) => {
    return (
        <AlignDiv align={align}>
            <DxLoadIndicator height={variant} width={variant} />
        </AlignDiv>
    )
};
