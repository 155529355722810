import React from 'react';
import Header from '../_shared/Header';
import { Wrapper } from '@liasincontrol/ui-basics';
import { Route, Routes } from 'react-router-dom';
import Styled from './index.styled';
import NotFound from '../_shared/NotFound';

type Props = {
    look: 'normal' | 'admin',
    sideMenu?: React.ReactElement,
    components?: React.ReactElement
};

/**
 * Represents an UI element that renders the layout for a standard page, containing a header and side menu.
 */
const Layout: React.FC<Props> = (props) => {
    return (
        <>
            <Wrapper navigation>
                <Styled.SideMenu>
                    <Routes>
                        {props.sideMenu}
                    </Routes>
                </Styled.SideMenu>
                <Styled.Main>
                    <Styled.Component>
                        <Routes>
                            {props.components}
                            <Route path="*" element={<NotFound />} />
                        </Routes>
                    </Styled.Component>
                </Styled.Main>
                <Header look={props.look} />
            </Wrapper>
        </>
    );
}

export default Layout;
