import { useNavigate } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { CreateCustomDataSource, LsGrid } from '@liasincontrol/ui-devextreme';
import * as DataService from '@liasincontrol/data-service';
import { getFinTasksColumnConfiguration, getPubTasksColumnConfiguration, getStudioTasksColumnConfiguration, Module } from './utils';
import { useEffect, useState } from 'react';
import { Heading2, Heading3, Icon, IconSize, IconValue, Label, palette } from '@liasincontrol/ui-basics';
import { Section } from '../../../ui/LiasInControl.UI.Elements/src/elements/Section';
import { Stack } from '@mui/material';
import Styled from './index.styled';
import { UserIdentity } from '@liasincontrol/auth-service';
import { Actions, ActionType, UserRightsService } from '@liasincontrol/userrights-service';

export const TaskList: React.FC<{ userIdentity: UserIdentity }> = ({ userIdentity }) => {
    const navigate = useNavigate();
    const [financeTasks, setFinanceTasks] = useState<Domain.Dto.Shared.FinanceTaskSummary[]>();
    const [studioTasks, setStudioTasks] = useState<Domain.Dto.Shared.StudioTaskSummary[]>();
    const [publisherTasks, setPublisherTasks] = useState<Domain.Dto.Shared.PublicationTaskSummary[]>();

    useEffect(() => {
        DataService.Shared.TaskSummaries.get().then(({ data }) => {
            setFinanceTasks(data.financeTasks);
            setStudioTasks(data.studioHierarchyTasks);
            setPublisherTasks(data.publicationTasks);
        });
    }, []);

    const hasPublisherAccess = UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Publications, ActionType.Read);
    const hasStudioAccess = UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Studio, ActionType.Read);
    const hasFinanceAccess = UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Finance, ActionType.Read);

    const publisherTotalTasksCount = publisherTasks?.reduce((acc, t) => acc + t.numberOfTasks, 0) || 0;
    const studioTotalTasksCount = studioTasks?.reduce((acc, t) => acc + t.numberOfTasks, 0) || 0;
    const financeTotalTasksCount = financeTasks?.reduce((acc, t) => acc + t.numberOfTasks, 0) || 0;

    return (
        <Section look='white' widget rowSpan={5} colSpan={3}>
            <div style={{ height: '100%' }}>
                <Stack height={'fit-content'}>
                    <Styled.WidgetTitle>
                        <Heading3>Openstaande taken</Heading3>
                    </Styled.WidgetTitle>
                    <Stack sx={{ display: 'flex', width: '100%', justifyContent: 'space-evenly', flexDirection: 'row' }}>
                        {hasPublisherAccess && <TaskTile module='Publisher' color={palette.primary1c} backgroundColor='#F1EEF8' tasksCount={publisherTotalTasksCount} />}
                        {hasStudioAccess && <TaskTile module='Studio' color={palette.darkPink} backgroundColor='#FCE4F0' tasksCount={studioTotalTasksCount} />}
                        {hasFinanceAccess && <TaskTile module='Finance' color={palette.darkGreen} backgroundColor='#F2F8F1' tasksCount={financeTotalTasksCount} />}
                    </Stack>
                </Stack>
                <Styled.TaskListsWrapper>
                    {hasPublisherAccess && (
                        <LsGrid<Domain.Dto.Shared.PublicationTaskSummary>
                            id='publisher-tasks-list'
                            dataSource={CreateCustomDataSource(publisherTasks)}
                            columns={getPubTasksColumnConfiguration()}
                            height={publisherTasks?.length >= 10 ? '66%' : null}
                            scrolling={{
                                mode: 'virtual',
                            }}
                            onClickRow={(item) => navigate(`/publisher/publication/${item.publicationId}/writer/page/`, { state: { openEditor: true } })}
                        />
                    )}
                    {hasStudioAccess && (
                        <LsGrid<Domain.Dto.Shared.StudioTaskSummary>
                            id='studio-tasks-list'
                            dataSource={CreateCustomDataSource(studioTasks)}
                            columns={getStudioTasksColumnConfiguration()}
                            height={studioTasks?.length >= 10 ? '66%' : null}
                            scrolling={{
                                mode: 'virtual',
                            }}
                            onClickRow={(item) =>
                                navigate(`/studio/hierarchy/${item.hierarchyDefinitionId}/moment/${item.measureMomentId}/${item.hierarchyId}`, { state: { filterForMyTask: true } })
                            }
                        />
                    )}
                    {hasFinanceAccess && (
                        <LsGrid<Domain.Dto.Shared.FinanceTaskSummary>
                            id='finance-tasks-list'
                            dataSource={CreateCustomDataSource(financeTasks)}
                            columns={getFinTasksColumnConfiguration()}
                            height={financeTasks?.length >= 10 ? '66%' : null}
                            scrolling={{
                                mode: 'virtual',
                            }}
                            onClickRow={() =>
                                navigate('/finance/budgets/budgetjournals', {
                                    state: {
                                        filterForMyTask: true,
                                    },
                                })
                            }
                        />
                    )}
                </Styled.TaskListsWrapper>
            </div>
        </Section>
    );
};

type Props = {
    color: string;
    backgroundColor: string;
    module: Module;
    tasksCount: number;
};

export const TaskTile: React.FC<Props> = (props) => {
    return (
        <Styled.Card $backgroundColor={props.backgroundColor}>
            <Icon size={IconSize.medium} color={props.color} displayMode='block' value={IconValue[props.module] || IconValue.File} />
            <Label text={props.module} />
            <Heading2>{props.tasksCount}</Heading2>
        </Styled.Card>
    );
};
