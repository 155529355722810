import styled from 'styled-components';
import { px, cssBreakpoint } from '@liasincontrol/ui-basics';

const Wrap = styled.div<{ $withPadding: boolean }>`
    position: relative;
    top: ${(p) => (p.$withPadding ? px(-12) : 0)};
`;

const ButtonPlaceholderWithMargin = styled.div`
    margin: 0.25rem 0;
    ${cssBreakpoint('xs', 's')`
        margin: 0;
    `};
    ${cssBreakpoint('m')`
        margin: 0;
    `};
`;

const BasePopover = styled.div`
    background-color: #fff;
    border-radius: ${px(4)};
    box-shadow: 0 ${px(0)} ${px(8)} ${px(-4)} rgba(0, 0, 0, 0.2);
    height: auto;
    padding: 0;
    position: absolute;
    top: ${px(8)};
`;

const SidePopover = styled(BasePopover)`
    min-height: 100vh;
    overflow: hidden;    
    position: fixed;
    background-color: #ffffff;
    left: 0;
    top: 0;
    padding-left: 3rem;
`;

const BottomPopover = styled(BasePopover)`
    top: ${px(8)};
    left: 50%;
    min-width: ${px(220)};
    transform: translateX(-50%);

    /* pointer: */
    &:after {
        position: absolute;
        bottom: 100%; left: 50%;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        margin-left: -8px;
        pointer-events: none;
        border-color: rgba(0, 0, 0, 0);
        border-bottom-color: #fff;
        border-width: 8px;
    }
`;

const LeftPopover = styled(BasePopover)`
    right: ${px(24)};
    top: ${px(-24)};
`;

const styledComponents = { BottomPopover, LeftPopover, SidePopover, Wrap, ButtonPlaceholderWithMargin };
export default styledComponents;