import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ClearIcon from '@mui/icons-material/Clear';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'devextreme-react/button';
import { px, Heading2 } from '@liasincontrol/ui-basics';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';
import { ISectionHeaderProps } from './Props';

// tslint:disable:no-magic-numbers

interface IStyledHeaderProps {
    readonly compact: boolean;
}

const HeaderWrap = styled.div<{ compact: boolean }>`
    position: relative;
    display: block;
    margin: 0 0 ${px(24)} 0;

    border-bottom: ${(p: IStyledHeaderProps) => p.compact === false ? 'solid 2px #E9E9EF' : ''};
    padding-bottom: ${(p: IStyledHeaderProps) => p.compact === false ? '16px' : ''};

`;
const DragWrapper = styled.div`
    display: inline-block;
    margin-right:${px(8)};
    cursor: all-scroll;
`;
const DragIcon = styled(DragIndicatorIcon as any /* children: never bug */)`
    fill: #4b55f6;
    vertical-align: middle;
`;
const SectionAction = styled.div`
    position: absolute;
    right: 0;
    top: 0;
`;
const Anchor = styled.a`
    position:absolute; display:inline-block; top:${px(-75)};
`;

export const SectionHeader = React.forwardRef<HTMLDivElement, ISectionHeaderProps>(({
    children,
    anchor,
    onRemove,
    onToggleCollapse,
    isCollapsed,
    canDrag,
    canRemove,
    className,
    canCollapse = true,
    dragHandleProps,
    compact = false
}, ref) => {
    const sectionAnchor = anchor
        ? (
            <Anchor className="section-anchor" id={anchor} />
        ) : null;
    const dragIcon = canDrag
        ? (
            <DragWrapper {...dragHandleProps}><DragIcon /></DragWrapper>
        ) : null;
    const toggleBtn = (onToggleCollapse && canCollapse && !canRemove)
        ? (
            <SectionAction>
                <Button
                    type="normal"
                    stylingMode='text'
                    hint={isCollapsed ? 'klap uit' : 'klap in'}
                    aria-expanded={isCollapsed}
                    icon={getMuiIconAsSvgString(KeyboardArrowDownIcon)}
                    onClick={onToggleCollapse} />
            </SectionAction>
        ) : null;
    const removeBtn = canRemove && onRemove
        ? (
            <SectionAction>
                <Button
                    type="normal"
                    stylingMode='contained'
                    hint="Verwijder sectie"
                    icon={getMuiIconAsSvgString(ClearIcon)}
                    onClick={onRemove} />
            </SectionAction>
        ) : null;

    if (children) {
        return (
            <HeaderWrap className={className} compact={compact} ref={ref}>
                {sectionAnchor}
                <Heading2><>{dragIcon}{children}</></Heading2>
                {removeBtn}
                {toggleBtn}
            </HeaderWrap>
        );
    }
    return <span />;
});
