import { SelectEditor } from './index.editor';
import { SingleSelectProps } from './index.props';

/**
 * Represents a UI component that renders an Select element.
 */
const SelectElement = <V,>(props: SingleSelectProps<V>) => {
    return (
         <SelectEditor {...props} />
    );
};

export type SelectElementTemplateProps = {
    placeholder?: string;
    isFieldTemplate?: boolean;
}

export { SelectElement, SelectEditor }; 