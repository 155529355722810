import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { BudgetJournalGroupActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Finance structures reducer.
 */
const reducer = createReducer<{ items: Domain.Finance.BudgetJournalGroup[], status: AjaxRequestStatus }>({ items: undefined, status: AjaxRequestStatus.NotSet }, (builder) => {
    return (

        builder.addCase(BudgetJournalGroupActionCreator.set.fulfilled, (state, action) => {
            state = {
                items: action.payload.value,
                status: AjaxRequestStatus.Done,
            };
            return state;
        }),
        builder.addCase(BudgetJournalGroupActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state = {
                items: [],
                status: AjaxRequestStatus.Pending,
            };
            return state;
        }),
        builder.addCase(BudgetJournalGroupActionCreator.set.rejected, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.Failed,
            };
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {
                items: [],
                status: AjaxRequestStatus.NotSet,
            };
            return state;
        })
    );
});

export default reducer;
