import React, { JSX, useCallback } from 'react';
import Styled from './index.styled';
import { DropDownOptions, DxDataSource } from '../..';
import { withField, WithFieldBaseType } from '../field';

export type LookupFieldProps = WithFieldBaseType<any> & {
    displayExpr: string | ((item) => string),
    placeholder?: string,
    value?: any,
    dataSource?: DxDataSource | any[],
    items?: any[],
    isDisabled?: boolean,
    isClearable?: boolean,
    noDataText?: string,
    valueExpr?: string | ((item: any) => string),
    itemRender?: (props) => JSX.Element,
};


export const LookupField = withField<any, LookupFieldProps>(
    (props) => {
        const { id, displayExpr, value, dataSource, items, placeholder, isDisabled, isClearable, noDataText, valueExpr, itemRender, onChange, onBlur } = props;

        const onChangeCallBack = useCallback((e) => onChange?.(e.value), [onChange]);

        return <Styled.StyledLookup
            id={id}
            displayExpr={displayExpr}
            valueExpr={valueExpr}
            value={value}
            disabled={isDisabled}
            placeholder={placeholder ?? 'Kies…'}
            items={items}
            dataSource={dataSource}
            onValueChanged={onChangeCallBack}
            noDataText={noDataText}
            height='51'
            cancelButtonText='ANNULEREN'
            clearButtonText='VERWIJDEREN'
            searchPlaceholder='Zoeken...'
            pageLoadingText='Laden...'
            itemRender={itemRender}
            searchEnabled={true}
            showCancelButton={true}
            showClearButton={isClearable}
            stylingMode='outlined'
            wrapItemText={true}
            dropDownCentered={false}
            onFocusOut={onBlur}
        >
            <DropDownOptions
                showTitle={false}
            />
        </Styled.StyledLookup>;
    }
);
