import { createAsyncThunk } from '@reduxjs/toolkit';
import { Shared as SharedDataAccess } from '@liasincontrol/data-service';
import * as Domain from '@liasincontrol/domain';

const action = createAsyncThunk<Domain.Shared.User[], void, {}>('/users/set', async () => {
    return SharedDataAccess.Users.get()
        .then(res => res.data);
});

export default action;
