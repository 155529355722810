import styled from 'styled-components';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { palette, px, BasicText } from '@liasincontrol/ui-basics';

const Image = styled.img`
    background: #fff;
    border-radius: 0.25rem;
    display: block;
    max-height: inherit;
    max-width: 100%;
`;

const Wrapper = styled.div<{ $maxPreviewHeight?: number }>`
    max-height: ${(p) => (p.$maxPreviewHeight ? `${p.$maxPreviewHeight}px` : '100%')};
    max-width: 100%;
    margin-bottom: ${px(20)};
`;

const PlaceholderWrapper = styled(Wrapper)`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F1F0EF;
    border: 1px solid #F1F0EF;
    border-radius: 0.25rem;
    padding: 1rem;
`;

const ImagePlaceholderIcon = styled(ImageOutlinedIcon)`
    display: inline-block;
    fill: ${palette.grey3}!important;
`;

const ImagePlaceholderText = styled(BasicText)`
    color: ${palette.grey3}!important;
`;

const DownloadItem = styled.div`
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${px(40)};
    min-height: ${px(40)};
    padding: 0;
`;

const DownloadLink = styled.button`
    background: none;
    border: none;
    padding: ${px(8)} 0;
    display: flex;
    cursor: inherit;
    justify-content: flex-start;
    align-items: left;
    color: ${palette.primary2};
    border-radius: ${px(8)} !important;

    span {
        word-wrap: break-word;
    }

    svg {
        min-width: ${px(24)};
        margin-left: ${px(8)};
        margin-right: ${px(8)};
        color: ${palette.grey3b};
    }

    &:link, &:visited, &:focus, &:hover, &:active {
        color: inherit!important;
        text-decoration: none;
        line-height: inherit;
        cursor: pointer;
        outline: 0;
        background-color: ${palette.grey4};       
    }
`;

export { Wrapper, PlaceholderWrapper, Image, ImagePlaceholderText, DownloadItem, DownloadLink, ImagePlaceholderIcon };