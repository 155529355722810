import { createReducer } from '@reduxjs/toolkit';
import { ElementDefinitionsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus, ElementDefinitionState } from '../../../state';

const initialState: ElementDefinitionState = {};

/**
 * Represents the element definitions reducer.
 */
const reducer = createReducer<ElementDefinitionState>(initialState, (builder) => {
    return (
        builder.addCase(ElementDefinitionsActionCreator.set.fulfilled, (state, action) => {
            state[action.payload.source] = { items: undefined, status: AjaxRequestStatus.NotSet, includeFieldDefinitions: true, includeDeleted: false, includeDetailCards: false };
            const items = {};
            action.payload.data.forEach(elementDefinition =>
                items[elementDefinition.id] = elementDefinition
            );
            state[action.payload.source] = {
                items: items,
                status: AjaxRequestStatus.Done,
                includeFieldDefinitions: action.payload.options?.includeFieldDefinitions,
                includeDetailCards: action.payload.options?.includeDetailCards,
                includeDeleted: action.payload.options?.includeDeleted,
            };
            return state;
        }),
        // here where the hell is the module id!!!
        builder.addCase(ElementDefinitionsActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state[action.meta.arg.source] = {
                items: {},
                status: AjaxRequestStatus.Pending,
                includeFieldDefinitions: true,
                includeDeleted: false,
                includeDetailCards: false
            };
            return state;
        }),
        builder.addCase(ElementDefinitionsActionCreator.set.rejected, (state, action) => {
            state[action.meta.arg.source] = {
                items: {},
                status: AjaxRequestStatus.Failed,
                includeFieldDefinitions: true,
                includeDeleted: false,
                includeDetailCards: false
            };
            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
