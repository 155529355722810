import { useSelector, useDispatch } from 'react-redux';
import { State, RootState, TenantActionCreator } from '@liasincontrol/redux-service';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';


export const useTenant = () => {
    const tenant = useSelector<State, string>((state) => state.tenant);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    const initTenant = (tenantId: string | undefined) => {
        dispatch(TenantActionCreator.initTenant(tenantId));
    }

    return { tenant, initTenant };
};