import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { TextAreaField } from '@liasincontrol/ui-devextreme';
import { MultiLineTextProps } from './index.props';
import { getFeedback, getValidationErrorsNode } from '../../shared/validationHelper';
import { TextValidationRestriction } from '@liasincontrol/core-service';

const noOp = () => null;

/**
 * Represents a UI component that renders a multi line text editor.
 */
export const MultiLineTextEditor: React.FC<MultiLineTextProps> = (props) => {
    const [value, setValue] = useState<string>(props.value ? props.value : '');
    const onChangedDebounced = useMemo(() => _.debounce(props.editorSettings?.onChange || noOp, 300), [props.editorSettings?.onChange]);

    useEffect(() => {
        if ((props.value || '') === value) {
            return onChangedDebounced.cancel;
        }

        onChangedDebounced(value);
        return onChangedDebounced.cancel;
    }, [value, onChangedDebounced, props.value]);

    useEffect(() => setValue(props.value ? props.value : ''), [props.value]);

    /**
     * Html textarea doesn't auto grow when rows are declared.
     **/
    return (
        <TextAreaField
            id={props.id}
            key={props.id}
            label={props.label}
            disabled={props.editorSettings?.disabled}
            readOnly={props.editorSettings?.readOnly}
            autoExpand={(!props.fullHeight && (_.isNull(props.rows) || _.isUndefined(props.rows)))}
            rows={props.rows || undefined}
            fullHeight={props.fullHeight || false}
            value={value}
            onFocus={props.onFocus}
            onBlur={props.onFocusOut}
            onChange={setValue}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors || [])}
            feedback={getFeedback(value, props.editorSettings?.restrictions || {})}
            helpText={{ text: props.helpText?.text, title: props.helpText?.title }}
            mandatory={props.editorSettings?.restrictions?.required}
            canMaximize={props.canMaximize}
            maximized={props.maximized}
            onMaximize={props.onMaximize}
            maxLength={(props.editorSettings?.restrictions as TextValidationRestriction)?.maxLength}
            placeholder={props.placeholder}
            autoResizeEnabled={props.editorSettings?.autoResizeEnabled}
            minHeight={props.editorSettings?.minHeight}
        />
    );
};

