import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrapper, WrapperContent, PageTitle, Heading2, PanelGrid } from '@liasincontrol/ui-basics';
import { TextImageWidget, Announcement } from '@liasincontrol/ui-elements';
import * as Domain from '@liasincontrol/domain';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { ActionSource, AjaxRequestStatus, useAnnouncement, useModules } from '@liasincontrol/redux-service';
import { License, UserIdentity } from '@liasincontrol/auth-service';
import { Shared as PerformanceShared } from '@liasincontrol/performance';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import Tiles from './Tiles';
import { ErrorOverlay } from '@liasincontrol/ui-elements';
import { TaskList } from './TaskList';

/**
 * Defines the props of the Homepage view component.
 */
type Props = {
    userIdentity: UserIdentity
};

/**
 * Represents a UI component that renders the Homepage view.
 */
const Homepage: React.FC<Props> = (props) => {
    const [error, setError] = useState<Domain.Shared.ErrorInfo>();
    const [performanceModuleId, setPerformanceModuleId] = useState(null);
    const navigate = useNavigate();
    //why actionsource?
    const modules = useModules(ActionSource.Publication);

    useEffect(() => {
        if (modules?.items?.[SystemModuleDefinitions.Performance]?.id) {
            setPerformanceModuleId(modules.items[SystemModuleDefinitions.Performance].id);
        }
    }, [modules]);

    //only textassistant => redirect?
    if (props.userIdentity?.profile?.licenses.length === 1 && UserRightsService.getInstance().userHasLicence(props.userIdentity, License.AITextAssistant)) {
        navigate('/ai');
    }

    const announcement = useAnnouncement();
    const hasManageUserAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read);

    let adminUrl: string | undefined = undefined;
    if (!adminUrl && hasManageUserAccess) {
        adminUrl = "/admin/usermanagement/users";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_DataStores, ActionType.Read)) {
        adminUrl = "/admin/datastore/list";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Workflows, ActionType.Read)) {
        adminUrl = "/admin/workflow/list";
    }
    if (!adminUrl && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_MeasureMoments, ActionType.Read)) {
        adminUrl = "/admin/measuremoment/list";
    }

    const showPerformanceCharts = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_ShowDashboardCharts, ActionType.Read);
    const hasPublisherAccess = UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.CRUD_Publications, ActionType.Read);

    const onRetry = () => {
        if (!error?.canRetry) {
            return;
        }
        setError(undefined);
    };

    if (!modules || modules.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Welkom {props.userIdentity?.profile?.preferred_username || ''}</Heading2>
                    {announcement.items?.message &&
                        <Announcement title='Mededeling' announcement={announcement.items?.message} renderAs='widget' />
                    }
                </PageTitle>
                <div className="view-wrap">
                    <PanelGrid>
                        <TextImageWidget
                            title='Welkom bij LIAS InControl'
                            text='Maak, beheer en controleer beleidsdocumenten gezamenlijk online. Stel doelen, analyseer resultaten
                                en maak de rapportage inzichtelijk voor iedereen – van beleidsmedewerkers tot raadsleden en burgers.'
                        />

                        <Tiles
                            administrativeUrl={adminUrl}
                            showManageUsers={hasManageUserAccess}
                            hasPublisherAccess={hasPublisherAccess}
                        />

                        {/* Don't nest the TaskTiles inside the ErrorOverlay, otherwise it will mess up the overall PanelGrid layout */}
                        {error?.message
                            ? <ErrorOverlay error={error?.message} errorDetails={error?.details} onRetry={onRetry} onBack={error?.canGoBack ? () => setError(undefined) : undefined} />
                            : <TaskList userIdentity={props.userIdentity} />
                        }
                        {showPerformanceCharts && performanceModuleId &&
                            <PerformanceShared.PerformanceCharts performanceModuleId={performanceModuleId} />
                        }
                    </PanelGrid>
                </div>
            </WrapperContent>
        </Wrapper>
    );
};

export default Homepage;
