import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, MeasureMomentsActionCreator, RootState } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

type MeasureMomentsType = {
    items: Domain.Shared.MeasureMoment[],
    status: AjaxRequestStatus,
}

export const useMeasureMoments = () => {
    const measureMoments = useSelector<State, MeasureMomentsType>((state) => state.measuremoments);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    if (measureMoments?.status === AjaxRequestStatus.NotSet) {
        dispatch(MeasureMomentsActionCreator.set());
    }

    const fetchMeasureMoments = () => {
        dispatch(MeasureMomentsActionCreator.set());
    }

    return { measureMoments, fetchMeasureMoments };
}