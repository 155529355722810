import config from 'devextreme/core/config';
import { locale, loadMessages } from 'devextreme/localization';
import { Dictionary as DxMessages } from './translations/dx-messages';

export const initDevExtremeLocalization = (licenseKey: string) => {
    locale('nl');
    config({
        licenseKey,
        defaultCurrency: 'EUR',
        editorStylingMode: 'outlined'
    });

    loadMessages(DxMessages);
}
