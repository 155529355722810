import React, { forwardRef, useEffect, useRef } from 'react';
import { IconSize } from '@liasincontrol/ui-basics';
import * as Domain from '@liasincontrol/domain';
import PivotGrid, { FieldChooser, Scrolling } from 'devextreme-react/pivot-grid';
import { Toolbar, Item as ToolbarItem } from 'devextreme-react/toolbar';
import PivotGridDataSource, { Field } from 'devextreme/ui/pivot_grid/data_source';
import { Cell, CellClickEvent, CellPreparedEvent, ContentReadyEvent } from "devextreme/ui/pivot_grid";

export interface ILsPivotTableProps {
    /**
     *  Defines the id
     */
    readonly id?: string;
    /**
    * Defines the Data source
    */
    readonly dataSource: PivotGridDataSource;
    /**
    * Filter array.
    */
    readonly filterValue?: string | Array<any> | Function;
    /**
     * Column chooser visible
     */
    readonly enableColumnChooser?: boolean;
    /**
     * Enables client side sorting & paging.
     */
    readonly clientSide?: boolean;
    /**
     * The title setting of datatable.
     */
    readonly title?: string;
    /**
     * The description setting of datatable.
     */
    readonly description?: string;

    /* set panel visibility*/
    readonly panelsVisible?: boolean,
    /**
     * The icons dictionary
     */
    readonly icons?: Record<string, Domain.Shared.SvgIcon>,

    readonly className?: string,

    readonly iconSize?: IconSize,

    /* set totals visibility*/
    readonly showColumnGrandTotals?: boolean,

    readonly showColumnTotals?: boolean,

    readonly showRowGrandTotals?: boolean,

    readonly showRowTotals?: boolean,

    readonly grandTotalCaption?: string,

    readonly onContentReady?: (e: ContentReadyEvent) => void,

    readonly onCellClick?: (e: CellClickEvent) => void;

    readonly onCellPrepared?: (cell: PivotCell, cellElement: HTMLElement) => void

    /**
    * The publication element needed for title styles.
    */
    readonly publicationElement?: Domain.Publisher.PublicationElement,

    readonly scrolling?: boolean,

    readonly showToolbar?: boolean;
    readonly customToolbarItems?: React.ReactElement[];

    readonly scrollIntoView?: boolean;
}

//Devextreme's cell type looks wrong, it's missing area for example which is there (sometimes)
export interface PivotCell extends Cell {
    area: string;
}

export type PivotGridFieldType = Field & { divide?: 1 | 1000 | 1000000, };

export const LsPivotTable = forwardRef<any, ILsPivotTableProps>((props, ref) => {
    const gridRef = useRef(null);

    const handleScrollInto = () => {
        setTimeout(() => {
            if (gridRef.current) {
                const gridElement = gridRef.current;
                const offset = 200; // Adjust this value as needed
                const topPosition = +gridElement.getBoundingClientRect().top + window.scrollY - offset;
                window.scrollTo({ top: topPosition, behavior: 'smooth' });
            }
        }, 100); // Delay to ensure rendering is complete
    };

    useEffect(() => {
        if (props.scrollIntoView) {
            handleScrollInto();
        }
    }, [props.dataSource, props.scrollIntoView]);

    const onCellPrepared = ({ cell, area, cellElement }: CellPreparedEvent) => {
        if (!props.onCellPrepared) return;

        const pivotCell = cell as PivotCell; //avoid typescript error
        pivotCell.area = area; // this is from their own example, I assume area not being always present is a bug
        props.onCellPrepared(pivotCell, cellElement);
    };

    return (<>
        {props.showToolbar && <Toolbar>
            {props.customToolbarItems && props.customToolbarItems.map((item, index) => (
                <ToolbarItem key={`toolbar-item-${index}`} {...item.props as any} />
            ))}
        </Toolbar>}
        <div ref={gridRef}>
            <PivotGrid
                ref={ref}
                id={props.id ?? "pivot-table"}
                className={props.showToolbar ? 'mt-150' : ''}
                dataSource={props.dataSource}
                allowSortingBySummary={true}
                allowSorting={false}
                allowFiltering={false}
                allowExpandAll={true}
                showBorders={true}
                wordWrapEnabled={false}
                width='100%'
                rowHeaderLayout="standard"
                showColumnGrandTotals={props.showColumnGrandTotals}
                showColumnTotals={props.showColumnTotals}
                showRowGrandTotals={props.showRowGrandTotals}
                showRowTotals={props.showRowTotals}
                encodeHtml={false}
                texts={{
                    grandTotal: props.grandTotalCaption
                }}
                onContentReady={props.onContentReady}
                onCellClick={props.onCellClick}
                onCellPrepared={onCellPrepared}
            >
                {props.scrolling &&
                    <Scrolling mode='standard' />
                }
                <FieldChooser enabled={false} />
            </PivotGrid>
        </div>
    </>
    );
});

