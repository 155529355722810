import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { Finance as FinanceDataAccess } from '@liasincontrol/data-service';

const action = createAsyncThunk<Domain.Finance.JournalElementKind[], void, {}>('/finance/journalelementkind', async () => {
    return FinanceDataAccess.JournalElementKindDataAccessor.getAll()
        .then((response) => response.data);
});

export default action;
