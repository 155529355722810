import styled from 'styled-components';
import { Button } from 'devextreme-react/text-box';
import { palette, px } from '@liasincontrol/ui-basics';
import { ISearchIconProps } from './index.props';

const SearchIconWrap = styled(Button)<ISearchIconProps>`
    color: ${(p: ISearchIconProps) => (p.color ? p.color : palette.grey2)};
    position: absolute;
    bottom: ${px(5)};
    margin-right: 20px;
`;


// tslint:disable:no-magic-numbers
const SectionAction = styled.div`
    position: relative;
    display: block;
    float: right;
    padding: ${px(8)} 0;
`;

const styledComponents = { SearchIconWrap, SectionAction };

export default styledComponents;
