import React, { useState } from 'react';
import { Feedback, Label } from '@liasincontrol/ui-basics';
import { HelpText } from '@liasincontrol/ui-devextreme';
import { ElementProps } from '../ElementProps';
import { StaticFieldValue } from './index.styled';

/**
 * Represents a UI component that renders a text viewer.
 * 
 */
export const TextViewer: React.FC<ElementProps<string | number | Date> & { readMore?: { active: boolean, length: number, }, withoutFeedback?: boolean }> = (props) => {
    const [isReadMore, setIsReadMore] = useState(true);
    const text = props.value ? String(props.value) : '';

    const toggleReadMore = () => { setIsReadMore(!isReadMore) };

    const getText = (textToTrim: string) => {
        if (props.readMore?.active) {
            return isReadMore ? textToTrim.slice(0, props.readMore?.length) : textToTrim;
        }
        return textToTrim;
    }

    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            {props.helpText && props.helpText.text && (
                <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>
            )}
            <StaticFieldValue id={props.id} role='textbox' aria-readonly $withPadding={props.withoutFeedback}>{getText(text)}
                {props.readMore?.active && text.length > props.readMore?.length &&
                    <span onClick={toggleReadMore}>
                        {isReadMore ? '...lees verder' : ' ...laat minder zien'}
                    </span>
                }
            </StaticFieldValue>
            {!props.withoutFeedback && <Feedback id={`${props.id}-feedback`} />}
        </>
    );
};