import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import AuthService, { License, UserIdentity } from '@liasincontrol/auth-service';
import { Bar, WrapperHeader, Logo, AccountButton } from '@liasincontrol/ui-basics';
import { Button } from '@liasincontrol/ui-devextreme';
import { MainMenu, ContextMenu, MenuAction } from '@liasincontrol/ui-elements';

type Props = {
    look: 'normal' | 'admin'
};

/**
 * Represents a UI component that renders the application header.
 */
const Header: React.FC<Props> = (props) => {
    const [userIdentity, setUserIdentity] = useState<UserIdentity>();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => {
                const user = await auth.getUser();
                if (user) {
                    setUserIdentity(user);
                } else {
                    const redirectPath = location.pathname; // rest.location?.pathname;
                    auth.attemptSilentLogin(redirectPath as string)
                        .then((identity) => {
                            if (identity) {
                                setUserIdentity(identity);
                            }
                        });
                }
            });
    }, []);

    const isAdminLook = props.look && props.look === 'admin';
    const logoLook = isAdminLook ? 'inverted' : 'default';
    const accountButtonLook = isAdminLook ? 'inverted' : 'default';

    const accountInitials = userIdentity ? (userIdentity.profile?.name?.match(/\b[A-Za-zÀ-ÿ0-9]/g) || []).slice(0, 3).join('').toUpperCase() : 'N/A';
    const userLicenses = userIdentity?.profile?.licenses || [];
    let adminUrl = '';

    if (userIdentity) {
        if (!adminUrl && (UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_UsersAndGroups, ActionType.Read)
            || UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Roles, ActionType.Read))) {
            adminUrl = "/admin/usermanagement/users";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_DataStores, ActionType.Read)) {
            adminUrl = "/admin/datastore/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_Workflows, ActionType.Read)) {
            adminUrl = "/admin/workflow/list";
        }
        if (!adminUrl && UserRightsService.getInstance().canPerformAction(userIdentity, Actions.CRUD_MeasureMoments, ActionType.Read)) {
            adminUrl = "/admin/measuremoment/list";
        }
    }

    const hasOnlyTextAssistant = userIdentity?.profile?.licenses.length === 1 && UserRightsService.getInstance().userHasLicence(userIdentity, License.AITextAssistant);
    const homeUrl = hasOnlyTextAssistant ? '/ai' : '/';

    const logoutUser = () => {
        return AuthService.getInstance().then((auth) => auth.signoutRedirect());
    }

    return (
        <WrapperHeader look={props.look}>
            <Bar look='system'>
                <Bar start>
                    <ContextMenu
                        look={accountButtonLook}
                        icon="smalliconslayout"
                        contextItem="menu"
                        menuAction={MenuAction.Main}
                        position="default"
                        highlightPopup={false}>
                        <MainMenu
                            licenses={userLicenses}
                            withExternalApp={!!!hasOnlyTextAssistant}
                            withUserManagement={true}
                        />
                    </ContextMenu>
                    <Logo
                        look={logoLook}
                        customer={userIdentity?.profile?.tenant_name || 'N/A'}
                        goHome={() => {
                            navigate(homeUrl);
                        }}
                    />
                </Bar>
                <Bar end>
                    {!!!hasOnlyTextAssistant && !!adminUrl && (
                        <Button
                            icon="preferences"
                            id="btn-admin"
                            type="default"
                            stylingMode="text"
                            hint="Open beheer"
                            className={`${isAdminLook ? 'dx-button-normal-inverted' : ''}`}
                            onClick={() => navigate(adminUrl)} />
                    )}
                    <AccountButton look={accountButtonLook} accountName={accountInitials}>
                        <li>
                            <Button
                                text="Uitloggen"
                                id="btn-logout"
                                type="default"
                                stylingMode="text"
                                className="dx-button-square"
                                onClick={() => logoutUser()}
                            />
                        </li>
                    </AccountButton>
                </Bar>
            </Bar>
        </WrapperHeader >
    );
};

export default Header;
