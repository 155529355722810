import React from 'react';
import styled from 'styled-components';
import { px } from '../style';
import { cssBreakpoint } from '../responsive/css';

export interface IWrapperHeaderProps {
    readonly children?: React.ReactNode;
    readonly look?: 'normal' | 'admin';
    readonly navigation?: boolean;
}

export const WrapperHeader = ({ children, look, navigation }: IWrapperHeaderProps) => (
    <>
        <Background $look={look} />
        <Header>
            <>{children}</>
        </Header>
    </>
);

// tslint:disable:no-magic-numbers

const Background = styled.div<{
    $look?: 'normal' | 'admin';
}>`
    height: ${px(56)};
    top: 0;
    position: sticky;
    background-color: ${(p) => (p.$look === 'admin' ? '#4b55f6' : '#fff')};
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.16);
    grid-row: 1;
    grid-column: 1 / -1;
    
    ${cssBreakpoint('xs', 's', 'm')`
        height: ${px(48)};
    `};
`;

const Header = styled.header`
    max-width: 100vw;
    height: ${px(56)};
    top: 0;
    position: sticky;
    grid-row: 1;
    grid-column: 2 / -1;
    display: grid;
    align-items: stretch;
    padding: 0 ${px(48)};

    ${cssBreakpoint('xs', 's', 'm')`
        height: ${px(48)};
    `};
`;
