import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import LoopIcon from '@mui/icons-material/Loop';
import { Button } from 'devextreme-react/button';
import { IconSize, Text } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';

type Props = {
    steps: number;
    activeStep: number;
    onPrev: () => void,
    onNext: () => void,
    onReSend?: () => void,
};

/**
 * Represents a UI component that renders a tabular numbers pad.
 */
export const TabularNumbers: React.FC<Props> = (props) => {
    return (<Styled.Wrap>
        {props.steps > 0 && <>
            <Button
                id="btn-left"
                type="default"
                stylingMode="text"
                icon={getMuiIconAsSvgString(ChevronLeftIcon, IconSize.small)}
                disabled={props.activeStep <= 0}
                onClick={props.onPrev}
                hint="Vorig resultaat"
            />
            <Text active bold value={`${props.activeStep + 1} / ${props.steps}`} />
            <Button
                id="btn-right"
                type="default"
                stylingMode="text"
                icon={getMuiIconAsSvgString(ChevronRightIcon, IconSize.small)}
                disabled={props.activeStep >= props.steps - 1}
                onClick={props.onNext}
                hint="Volgende resultaat"
            />
        </>}
        {props.onReSend && <Button
            id="btn-regenerate"
            type="default"
            stylingMode="text"
            icon={getMuiIconAsSvgString(LoopIcon, IconSize.small)}
            onClick={props.onReSend}
            hint='Opnieuw genereren'
        />}
    </Styled.Wrap>);
};