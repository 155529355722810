import React, { useEffect } from 'react';
import { Heading4, Label, TemplateImg } from '@liasincontrol/ui-basics';
import { Button } from '@liasincontrol/ui-devextreme';
import { TemplateSelectProps } from './index.props';
import { LayoutContainer, LayoutItem, LayoutInfo, LayoutPreview } from './index.styled';

/**
 * Represents a UI component that renders a text editor.
 */
export const TemplateSelectEditor: React.FC<TemplateSelectProps> = (props) => {
    useEffect(() => {
        if (props.items.length > 0 && !props.value) {
            props.editorSettings?.onChange(props.items[0].id);
        }
    }, [props.items, props.editorSettings, props.value]);

    const id = props.id;

    if (!props.items) {
        return null;
    }
    return (
        <>
            {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
            <LayoutContainer id={id} aria-labelledby={`${id}-label`}>
                {props.items.map((item, idx) => (
                    <LayoutItem key={item.id} className={props.value === item.id ? 'active' : ''}>
                        <Heading4 id={`${id}_label_${idx}`}>{item.name}</Heading4>
                        <LayoutPreview>
                            <TemplateImg variant={item.pageDesignId || item.id} size={props.size} />
                        </LayoutPreview>
                        <LayoutInfo aria-labelledby={`${id}_label_${idx}`} aria-describedby={`${id}_descr_${idx}`}>
                            {props.items.length > 1 &&
                                <Button
                                    id={`${id}_button_${idx}`}
                                    text='Selecteer'
                                    type='default'
                                    stylingMode='contained'
                                    disabled={props.value === item.id}
                                    onClick={() => props.editorSettings?.onChange(item.id)}
                                />
                            }
                        </LayoutInfo>
                    </LayoutItem>
                ))}
            </LayoutContainer>
        </>
    );
};
