import { IconSize } from "@liasincontrol/ui-basics";
import { Button, getMuiIconAsSvgString } from "@liasincontrol/ui-devextreme";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { Stack } from "@mui/material";
import { IButtonOptions } from "devextreme-react/cjs/button";
import { PropsWithChildren } from "react";

export type ComponentWithEditButtonType = {
  id: string;
  caption: string;
} & IButtonOptions;

export const ComponentWithEditButton = ({ id, caption, children, ...buttonProps }: PropsWithChildren<ComponentWithEditButtonType>) => {
  return (
    <Stack direction={"row"} spacing={0.3} flexWrap={"wrap"}>
      {children}
      <Button
        id={`btn-add-contributor-${id}`}
        className="btn-small"
        type="default"
        stylingMode="text"
        aria-label={`Gebruikers koppelen ${caption}`}
        icon={getMuiIconAsSvgString(EditOutlinedIcon, IconSize.mediumsmall)}
        {...buttonProps}
      />
    </Stack>
  );
};
