import React, { useState } from 'react';
import { Feedback, Label, MandatoryIcon } from '../primitives';
import Styled from './index.styled';

//old
type Sub<O extends PropertyKey, D extends PropertyKey> = {
    [K in O]: (Record<D, never> & Record<PropertyKey, K>)[K]
}[O];
type Omit<O, D extends string> = Pick<O, Sub<keyof O, D>>;

export const withField = <V,>(
    Component: React.ComponentType<
        IFieldChildProps<V> & Omit<IFieldProps<V>, keyof IFieldChildProps<V>>
    >,
): React.ComponentType<Omit<IFieldProps<V>, 'children'>> => {
    return (props: IFieldProps<V>) => (
        <Field {...props}>
            {(render: IFieldChildProps<V>) => <Component {...{ ...props, ...render }} />}
        </Field>
    );
};

export const Field = React.memo(<T,>(props: IFieldProps<T>) => {
    const { as: Container = React.Fragment } = props;
    const onFocus = () => {
        setFocused(true);
        if (props.onFocus) {
            props.onFocus();
        }
    };
    const onBlur = () => {
        setFocused(false);
        if (props.onBlur) {
            props.onBlur();
        }
    };
    const onChange = (value: T) => {
        if (props.onChange) {
            props.onChange(value);
        }
    };
    const [focused, setFocused] = useState(false);

    const childprops = {
        onBlur,
        onChange,
        onFocus,
        field: { ...props, as: Container },
        value: props.value,
        error: !!props.error,
        success: !!props.success,
        focused,
        fullHeight: props.fullHeight,
        // why was not ...props also?
    };

    const children = props.children(childprops);

    const { id, feedback, disabled, mandatory } = props;
    const label =
        props.label || props.value || focused
            ? props.label || ''
            : '';

    const inputWithCounter = isCounterFeedback(feedback);

    return (
        <Styled.HoverContainer $fullHeight={props.fullHeight}>
            <Styled.LabelContainer>
                {label ? (
                    <Label id={`${id}-label`} htmlFor={id} text={label} />
                ) : <span />}
                <span>
                    {!disabled && mandatory && <MandatoryIcon />}
                </span>
            </Styled.LabelContainer>
            {children}
            {!props.withoutFeedback && <Feedback
                error={!!props.error}
                children={props.error}
                withCounter={inputWithCounter}
                counter={focused ? props.feedback : ''}
                id={`${id}-feedback`}
            />}
        </Styled.HoverContainer>
    );
});

export interface IFieldChildProps<T> {
    readonly value: T;
    readonly focused: boolean;
    readonly error: boolean;
    readonly success: boolean;
    readonly field: IFieldProps<T>;
    onFocus(): void;
    onBlur(): void;
    onChange(value: T): void;
    readonly fullHeight?: boolean;
}
export interface IFieldProps<T> {
    readonly as?: React.ElementType;
    readonly id: string;
    readonly label?: string | React.ReactNode | null;
    readonly helpText?: { title?: string; text: string } | null;
    readonly placeholder?: string;
    readonly mandatory?: boolean;
    readonly maxTagsCount?: number;
    readonly value: T;
    readonly disabled?: boolean;
    readonly error?: React.ReactNode;
    readonly success?: React.ReactNode;
    readonly feedback?: React.ReactNode;
    readonly altLabel?: string | null;
    readonly maximized?: boolean;
    readonly canMaximize?: boolean;
    readonly onFocus?: () => void;
    readonly onBlur?: () => void;
    readonly onChange?: (value: T) => void;
    readonly onMaximize?: () => void;
    //TODO: unit tests will fail if we don't use any here:
    readonly children: any; // (args: IFieldChildProps<T>) => React.ReactNode;

    readonly fullHeight?: boolean;
    readonly withTextAssistant?: boolean,
    readonly onTextAssistant?: () => void,
    readonly theme?: 'dark' | 'light',
    readonly withoutFeedback?: boolean,

    readonly withSitemapLinks?: boolean,
    readonly sitemapsource?: any[],
}

/**
 * Matches number/number eg `"10/100"`
 */
const isCounterFeedback = (input: any) =>
    typeof input === 'string' && /^\d+\/\d+$/.test(input);
