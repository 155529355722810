import React from 'react';
import { withField, WithFieldBaseType } from '../../field';
import { StyledInput } from './index.styled';


type InputFieldProps = WithFieldBaseType<string> & {
  placeholder?: string;
  defaultValue?: string;
  maxLength?: number;
  readOnly?: boolean;
  showClearButton?: boolean;
  valueChangeEvent?: string;
  children?: React.ReactNode;
};

export const InputField = withField<string, InputFieldProps>(
  (props) => {
    const { id, onChange, ...rest } = props;
    return (
      <StyledInput
        {...rest}
        label=''
        inputAttr={{ id }}
        onInput={(e) => onChange?.((e.event?.currentTarget as any)?.value)}
      >
        {rest.children}
      </StyledInput>
    );
  }
);

