import React from 'react';
import styled from 'styled-components';
import { px } from '../../style';
import { Heading1 } from '../typography';

// tslint:disable:no-magic-numbers
const Text = styled(Heading1)`
    margin: 0 0 ${px(4)} 0;
`;

export const Title = (props) => (<Text className="title-control">{props.text}{props.children}</Text>);
