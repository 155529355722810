
/* localisation: messages for devextreme elements*/

export const Dictionary = {
    "nl": {
        "Yes": "Ja",
        "No": "Nee",
        "Cancel": "Annuleren",
        "CheckState": "Check state",
        "Close": "Sluiten",
        "Clear": "Wissen",
        "Done": "Klaar",
        "Loading": "Laden...",
        "Select": "Selecteer...",
        "Search": "Zoeken",
        "Back": "Terug",
        "OK": "OK",
        "Today": "Vandaag",
        "Yesterday": "Gisteren",
        "dxCollectionWidget-noDataText": "Geen gegevens om te tonen",
        "dxDropDownEditor-selectLabel": "Selecteer",
        "validation-required": "Verplicht",
        "validation-required-formatted": "{0} is verplicht",
        "validation-numeric": "Waarde moet numeriek zijn",
        "validation-numeric-formatted": "{0} moet numeriek zijn",
        "validation-range": "Waarde is niet binnen het bereik",
        "validation-range-formatted": "{0} is niet binnen het bereik",
        "validation-stringLength": "De lengte van de waarde is niet correct",
        "validation-stringLength-formatted": "De lengte van {0}  is niet correct",
        "validation-custom": "Waarde is ongeldig",
        "validation-custom-formatted": "{0} is ongeldig",
        "validation-async": "Waarde is ongeldig",
        "validation-async-formatted": "{0} is ongeldig",
        "validation-compare": "Waardes komen niet overeen",
        "validation-compare-formatted": "{0} komen niet overeen",
        "validation-pattern": "Waarde komt niet overeen met het patroon",
        "validation-pattern-formatted": "{0} komt niet overeen met het patroon",
        "validation-email": "Email is niet geldig",
        "validation-email-formatted": "{0} is niet geldig",
        "validation-mask": "Waarde is niet geldig",
        "dxLookup-searchPlaceholder": "Minimum aantal karakters: {0}",
        "dxList-pullingDownText": "Trek naar beneden om te vernieuwen...",
        "dxList-pulledDownText": "Laat los om te vernieuwen...",
        "dxList-refreshingText": "Vernieuwen...",
        "dxList-pageLoadingText": "Laden...",
        "dxList-nextButtonText": "Meer",
        "dxList-selectAll": "Selecteer alles",
        "dxList-listAriaLabel": "Items",
        "dxList-listAriaLabel-deletable": "Deletable items",
        "dxListEditDecorator-delete": "Verwijderen",
        "dxListEditDecorator-more": "Meer",
        "dxList-selectAll-indeterminate": "Half-checked",
        "dxList-selectAll-checked": "Checked",
        "dxList-selectAll-notChecked": "Not checked",
        "dxList-ariaRoleDescription": "List",
        "dxScrollView-pullingDownText": "Trek naar beneden om te vernieuwen...",
        "dxScrollView-pulledDownText": "Laat los om te vernieuwen...",
        "dxScrollView-refreshingText": "Vernieuwen...",
        "dxScrollView-reachBottomText": "Laden...",
        "dxDateBox-simulatedDataPickerTitleTime": "Selecteer tijd",
        "dxDateBox-simulatedDataPickerTitleDate": "Selecteer datum",
        "dxDateBox-simulatedDataPickerTitleDateTime": "Selecteer datum en tijd",
        "dxDateBox-validation-datetime": "Waarde moet een datum of tijd zijn",
        "dxDateRangeBox-invalidStartDateMessage": "Beginwaarde moet een datum zijn",
        "dxDateRangeBox-invalidEndDateMessage": "Eindwaarde moet een datum zijn",
        "dxDateRangeBox-startDateOutOfRangeMessage": "Startdatum ligt buiten bereik",
        "dxDateRangeBox-endDateOutOfRangeMessage": "Einddatum ligt buiten bereik",
        "dxDateRangeBox-startDateLabel": "Startdatum",
        "dxDateRangeBox-endDateLabel": "Einddatum",
        "dxFileUploader-selectFile": "Selecteer bestand",
        "dxFileUploader-dropFile": "of sleep bestand hier",
        "dxFileUploader-bytes": "bytes",
        "dxFileUploader-kb": "KB",
        "dxFileUploader-Mb": "MB",
        "dxFileUploader-Gb": "GB",
        "dxFileUploader-upload": "Uploaden",
        "dxFileUploader-uploaded": "Geüpload",
        "dxFileUploader-readyToUpload": "Klaar om te uploaden",
        "dxFileUploader-uploadAbortedMessage": "Upload geannuleerd",
        "dxFileUploader-uploadFailedMessage": "Uploaden is mislukt",
        "dxFileUploader-invalidFileExtension": "Dit bestandstype is niet toegestaan",
        "dxFileUploader-invalidMaxFileSize": "Het bestand is te groot",
        "dxFileUploader-invalidMinFileSize": "Het bestand is te klein",
        "dxRangeSlider-ariaFrom": "Van",
        "dxRangeSlider-ariaTill": "Tot",
        "dxSwitch-switchedOnText": "AAN",
        "dxSwitch-switchedOffText": "UIT",
        "dxForm-optionalMark": "optioneel",
        "dxForm-requiredMessage": "{0} is verplicht",
        "dxNumberBox-invalidValueMessage": "Waarde moet een nummer zijn",
        "dxNumberBox-noDataText": "Geen gegevens",
        "dxDataGrid-emptyHeaderWithColumnChooserText": "gebruik {0} om kolommen weer te geven",
        "dxDataGrid-emptyHeaderWithGroupPanelText": "Sleep een kolom vanuit het groepeerpaneel hierheen",
        "dxDataGrid-emptyHeaderWithColumnChooserAndGroupPanelText": "Gebruik {0} of sleep een kolom vanuit het groepeerpaneel",
        "dxDataGrid-emptyHeaderColumnChooserText": "kolom kiezer",
        "dxDataGrid-columnChooserTitle": "Kolom Kiezer",
        "dxDataGrid-columnChooserEmptyText": "Sleep hier een kolomkop om hem te verbergen",
        "dxDataGrid-groupContinuesMessage": "Gaat verder op de volgende pagina",
        "dxDataGrid-groupContinuedMessage": "Vervolg van de vorige pagina",
        "dxDataGrid-groupHeaderText": "Groepeer op deze kolom",
        "dxDataGrid-ungroupHeaderText": "Degroeperen",
        "dxDataGrid-ungroupAllText": "Alle degroeperen",
        "dxDataGrid-editingEditRow": "Wijzigen",
        "dxDataGrid-editingSaveRowChanges": "Opslaan",
        "dxDataGrid-editingCancelRowChanges": "Annuleren",
        "dxDataGrid-editingDeleteRow": "Verwijderen",
        "dxDataGrid-editingUndeleteRow": "Ongedaan maken",
        "dxDataGrid-editingConfirmDeleteMessage": "Moet dit record verwijderd worden?",
        "dxDataGrid-validationCancelChanges": "Wijzigingen annuleren",
        "dxDataGrid-groupPanelEmptyText": "Sleep hier een kolomkop om er op te groeperen",
        "dxDataGrid-noDataText": "Geen gegevens",
        "dxDataGrid-searchPanelPlaceholder": "Zoeken...",
        "dxDataGrid-filterRowShowAllText": "(Alle)",
        "dxDataGrid-filterRowResetOperationText": "Reset",
        "dxDataGrid-filterRowOperationEquals": "Gelijk aan",
        "dxDataGrid-filterRowOperationNotEquals": "Niet gelijk aan",
        "dxDataGrid-filterRowOperationLess": "Kleiner dan",
        "dxDataGrid-filterRowOperationLessOrEquals": "Kleiner dan of gelijk aan",
        "dxDataGrid-filterRowOperationGreater": "Groter dan",
        "dxDataGrid-filterRowOperationGreaterOrEquals": "Groter dan of gelijk aan",
        "dxDataGrid-filterRowOperationStartsWith": "Begint met",
        "dxDataGrid-filterRowOperationContains": "Bevat",
        "dxDataGrid-filterRowOperationNotContains": "Bevat niet",
        "dxDataGrid-filterRowOperationEndsWith": "Eindigt met",
        "dxDataGrid-filterRowOperationBetween": "Tussen",
        "dxDataGrid-filterRowOperationBetweenStartText": "Begin",
        "dxDataGrid-filterRowOperationBetweenEndText": "Einde",
        "dxDataGrid-ariaSearchBox": "Zoekveld",
        "dxDataGrid-applyFilterText": "Filter toepassen",
        "dxDataGrid-trueText": "waar",
        "dxDataGrid-falseText": "niet waar",
        "dxDataGrid-sortingAscendingText": "Sorteer oplopend",
        "dxDataGrid-sortingDescendingText": "Sorteer aflopend",
        "dxDataGrid-sortingClearText": "Sortering wissen",
        "dxDataGrid-ariaNotSortedColumn": "Ongesorteerde kolom",
        "dxDataGrid-ariaSortedAscendingColumn": "Kolom gesorteerd in oplopende volgorde",
        "dxDataGrid-ariaSortedDescendingColumn": "Kolom gesorteerd in aflopende volgorde",
        "dxDataGrid-ariaSortIndex": "Sorteerindex {0}",
        "dxDataGrid-editingSaveAllChanges": "Wijzigingen opslaan",
        "dxDataGrid-editingCancelAllChanges": "Wijzigingen annuleren",
        "dxDataGrid-editingAddRow": "Rij toevoegen",
        "dxDataGrid-summaryMin": "Min: {0}",
        "dxDataGrid-summaryMinOtherColumn": "Min van {1} is {0}",
        "dxDataGrid-summaryMax": "Max: {0}",
        "dxDataGrid-summaryMaxOtherColumn": "Max van {1} is {0}",
        "dxDataGrid-summaryAvg": "Gem: {0}",
        "dxDataGrid-summaryAvgOtherColumn": "Gem van {1} is {0}",
        "dxDataGrid-summarySum": "Som: {0}",
        "dxDataGrid-summarySumOtherColumn": "Som van {1} is {0}",
        "dxDataGrid-summaryCount": "Aantal: {0}",
        "dxDataGrid-columnFixingFix": "Blokkeren",
        "dxDataGrid-columnFixingUnfix": "Blokkering opheffen",
        "dxDataGrid-columnFixingLeftPosition": "Naar links",
        "dxDataGrid-columnFixingRightPosition": "Naar rechts",
        "dxDataGrid-columnFixingStickyPosition": "Sticky",
        "dxDataGrid-exportTo": "Exporteren",
        "dxDataGrid-exportToExcel": "Exporteer naar Excel bestand",
        "dxDataGrid-exporting": "Exporteren...",
        "dxDataGrid-excelFormat": "Excel bestand",
        "dxDataGrid-selectedRows": "Geselecteerde rijen",
        "dxDataGrid-exportSelectedRows": "Exporteer geselecteerde rijen",
        "dxDataGrid-exportAll": "Exporteer alle gegevens",
        "dxDataGrid-headerFilterLabel": "Filter opties",
        "dxDataGrid-headerFilterIndicatorLabel": "Toon filter opties voor kolom '{0}'",
        "dxDataGrid-headerFilterEmptyValue": "(Leeg)",
        "dxDataGrid-headerFilterOK": "OK",
        "dxDataGrid-headerFilterCancel": "Annuleren",
        "dxDataGrid-ariaAdaptiveCollapse": "Verberg extra gegevens",
        "dxDataGrid-ariaAdaptiveExpand": "Toon extra gegevens",
        "dxDataGrid-ariaColumn": "",
        "dxDataGrid-ariaColumnHeader": "Column header",
        "dxDataGrid-ariaValue": "Waarde",
        "dxDataGrid-ariaError": "Error",
        "dxDataGrid-ariaRevertButton": "Press Escape to discard the changes",
        "dxDataGrid-ariaFilterCell": "Filter cel",
        "dxDataGrid-ariaCollapse": "Inklappen",
        "dxDataGrid-ariaModifiedCell": "Modified",
        "dxDataGrid-ariaDeletedCell": "Deleted",
        "dxDataGrid-ariaEditableCell": "Editable",
        "dxDataGrid-ariaExpand": "Uitklappen",
        "dxDataGrid-ariaCollapsedRow": "Ingeklapte regel",
        "dxDataGrid-ariaExpandedRow": "Uitgeklapte regel",
        "dxDataGrid-ariaDataGrid": "Gegevenstabel met {0} rijen en {1} kolommen",
        "dxDataGrid-ariaSearchInGrid": "Zoeken in gegevenstabel",
        "dxDataGrid-ariaSelectAll": "Selecteer alle",
        "dxDataGrid-ariaSelectRow": "Selecteer rij",
        "dxDataGrid-ariaToolbar": "Data grid toolbar",
        "dxDataGrid-ariaEditForm": "Edit form",
        "dxDataGrid-filterBuilderPopupTitle": "Filterbouwer",
        "dxDataGrid-filterPanelCreateFilter": "Maak filter",
        "dxDataGrid-filterPanelClearFilter": "Wissen",
        "dxDataGrid-filterPanelFilterEnabledHint": "Filter activeren",
        "dxDataGrid-masterDetail": "Cell with details",
        "dxTreeList-ariaTreeList": "Boomstructuur met {0} rijen en {1} kolommen",
        "dxTreeList-ariaExpandableInstruction": "Press Ctrl + right arrow to expand the focused node and Ctrl + left arrow to collapse it",
        "dxTreeList-ariaSearchInGrid": "Zoek in de boomstructuur",
        "dxTreeList-ariaToolbar": "Boomstructuur toolbar",
        "dxTreeList-editingAddRowToNode": "Toevoegen",
        "dxPager-infoText": "Pagina {0} van {1} ({2} items)",
        "dxPager-pagesCountText": "van",
        "dxPager-pageSize": "Items per pagina: {0}",
        "dxPager-pageSizesAllText": "Alle",
        "dxPager-page": "Pagina {0}",
        "dxPager-prevPage": "Vorige pagina",
        "dxPager-nextPage": "Volgende pagina",
        "dxPager-ariaLabel": "Pagina navigatie",
        "dxPager-ariaPageSize": "Paingagrootte",
        "dxPager-ariaPageNumber": "Paginanummer",
        "dxPagination-infoText": "Pagina {0} van {1} ({2} items)",
        "dxPagination-pagesCountText": "van",
        "dxPagination-pageSize": "Items per page: {0}",
        "dxPagination-pageSizesAllText": "Alle",
        "dxPagination-page": "Page {0}",
        "dxPagination-prevPage": "Previous page",
        "dxPagination-nextPage": "Next page",
        "dxPagination-ariaLabel": "Page navigation",
        "dxPagination-ariaPageSize": "Page size",
        "dxPagination-ariaPageNumber": "Page number",
        "dxPivotGrid-grandTotal": "Eindtotaal",
        "dxPivotGrid-total": "{0} Totaal",
        "dxPivotGrid-fieldChooserTitle": "Veldenkiezer",
        "dxPivotGrid-showFieldChooser": "Toon veldenkiezer",
        "dxPivotGrid-expandAll": "Alle uitklappen",
        "dxPivotGrid-collapseAll": "Alle inklappen",
        "dxPivotGrid-sortColumnBySummary": "Sorteer \"{0}\" op deze kolom",
        "dxPivotGrid-sortRowBySummary": "Sorteer \"{0}\" op deze rij",
        "dxPivotGrid-removeAllSorting": "Verwijderen alle sorteringen",
        "dxPivotGrid-dataNotAvailable": "N/B",
        "dxPivotGrid-rowFields": "Rijvelden",
        "dxPivotGrid-columnFields": "Kolomvelden",
        "dxPivotGrid-dataFields": "Gegevensvelden",
        "dxPivotGrid-filterFields": "Filtervelden",
        "dxPivotGrid-allFields": "Alle velden",
        "dxPivotGrid-columnFieldArea": "Sleep kolomvelden hier",
        "dxPivotGrid-dataFieldArea": "Sleep gegevensvelden hier",
        "dxPivotGrid-rowFieldArea": "Sleep rijvelden hier",
        "dxPivotGrid-filterFieldArea": "Sleep filtervelden hier",
        "dxScheduler-ariaLabel": "Scheduler. {0} view",
        "dxScheduler-appointmentAriaLabel-group": "Group: {0}",
        "dxScheduler-appointmentAriaLabel-recurring": "Recurring appointment",
        "dxScheduler-appointmentListAriaLabel": "Appointment list",
        "dxScheduler-editorLabelTitle": "Onderwerp",
        "dxScheduler-editorLabelStartDate": "Startdatum",
        "dxScheduler-editorLabelEndDate": "Einddatum",
        "dxScheduler-editorLabelDescription": "Omschrijving",
        "dxScheduler-editorLabelRecurrence": "Herhalen",
        "dxScheduler-navigationPrevious": "Previous page",
        "dxScheduler-navigationNext": "Next page",
        "dxScheduler-openAppointment": "Afspraak openen",
        "dxScheduler-recurrenceNever": "Nooit",
        "dxScheduler-recurrenceMinutely": "Elke minuut",
        "dxScheduler-recurrenceHourly": "Hourly",
        "dxScheduler-recurrenceDaily": "Dagelijks",
        "dxScheduler-recurrenceWeekly": "Wekelijks",
        "dxScheduler-recurrenceMonthly": "Maandelijks",
        "dxScheduler-recurrenceYearly": "Jaarlijks",
        "dxScheduler-recurrenceRepeatEvery": "Herhaal elke",
        "dxScheduler-recurrenceRepeatOn": "Herhaal op",
        "dxScheduler-recurrenceEnd": "Einde herhaling",
        "dxScheduler-recurrenceAfter": "Na",
        "dxScheduler-recurrenceOn": "Op",
        "dxScheduler-recurrenceRepeatMinutely": "minute(n)",
        "dxScheduler-recurrenceRepeatHourly": "uur/uren",
        "dxScheduler-recurrenceRepeatDaily": "dag(en)",
        "dxScheduler-recurrenceRepeatWeekly": "week/weken",
        "dxScheduler-recurrenceRepeatMonthly": "maand(en)",
        "dxScheduler-recurrenceRepeatYearly": "jaar/jaren",
        "dxScheduler-switcherDay": "Dag",
        "dxScheduler-switcherWeek": "Week",
        "dxScheduler-switcherWorkWeek": "Werkweek",
        "dxScheduler-switcherMonth": "Maand",
        "dxScheduler-switcherAgenda": "Planningsweergave",
        "dxScheduler-switcherTimelineDay": "Tijdslijn dag",
        "dxScheduler-switcherTimelineWeek": "Tijdslijn week",
        "dxScheduler-switcherTimelineWorkWeek": "Tijdslijn werkweek",
        "dxScheduler-switcherTimelineMonth": "Tijdslijn maand",
        "dxScheduler-recurrenceRepeatOnDate": "op datum",
        "dxScheduler-recurrenceRepeatCount": "keer",
        "dxScheduler-allDay": "Hele dag",
        "dxScheduler-ariaEditForm": "Edit form",
        "dxScheduler-confirmRecurrenceEditTitle": "Edit Recurring Appointment",
        "dxScheduler-confirmRecurrenceDeleteTitle": "Delete Recurring Appointment",
        "dxScheduler-confirmRecurrenceEditMessage": "Alleen deze afspraak of de hele serie wijzigen?",
        "dxScheduler-confirmRecurrenceDeleteMessage": "Alleen deze afspraak of de volledige serie verwijderen?",
        "dxScheduler-confirmRecurrenceEditSeries": "Serie wijzigen",
        "dxScheduler-confirmRecurrenceDeleteSeries": "Serie verwijderen",
        "dxScheduler-confirmRecurrenceEditOccurrence": "Afspraak wijzigen",
        "dxScheduler-confirmRecurrenceDeleteOccurrence": "Afspraak verwijderen",
        "dxScheduler-noTimezoneTitle": "Geen tijdszone",
        "dxScheduler-moreAppointments": "Nog {0}",
        "dxCalendar-currentDay": "Today",
        "dxCalendar-currentMonth": "Current month",
        "dxCalendar-currentYear": "Current year",
        "dxCalendar-currentYearRange": "Current year range",
        "dxCalendar-todayButtonText": "Vandaag",
        "dxCalendar-ariaWidgetName": "Kalender",
        "dxCalendar-previousMonthButtonLabel": "Previous month",
        "dxCalendar-previousYearButtonLabel": "Previous year",
        "dxCalendar-previousDecadeButtonLabel": "Previous decade",
        "dxCalendar-previousCenturyButtonLabel": "Previous century",
        "dxCalendar-nextMonthButtonLabel": "Next month",
        "dxCalendar-nextYearButtonLabel": "Next year",
        "dxCalendar-nextDecadeButtonLabel": "Next decade",
        "dxCalendar-nextCenturyButtonLabel": "Next century",
        "dxCalendar-captionMonthLabel": "Month selection",
        "dxCalendar-captionYearLabel": "Year selection",
        "dxCalendar-captionDecadeLabel": "Decade selection",
        "dxCalendar-captionCenturyLabel": "Century selection",
        "dxCalendar-selectedDate": "The selected date is {0}",
        "dxCalendar-selectedDates": "The selected dates",
        "dxCalendar-selectedDateRange": "The selected date range is from {0} to {1}",
        "dxCalendar-selectedMultipleDateRange": "from {0} to {1}",
        "dxCalendar-selectedDateRangeCount": "There are {0} selected date ranges",
        "dxCalendar-readOnlyLabel": "Read-only calendar",
        "dxAvatar-defaultImageAlt": "Avatar",
        "dxChat-elementAriaLabel": "Chat",
        "dxChat-textareaPlaceholder": "Type a message",
        "dxChat-sendButtonAriaLabel": "Send",
        "dxChat-defaultUserName": "Unknown User",
        "dxChat-messageListAriaLabel": "Message list",
        "dxChat-alertListAriaLabel": "Error list",
        "dxChat-emptyListMessage": "There are no messages in this chat",
        "dxChat-emptyListPrompt": "Write your first message",
        "dxChat-typingMessageSingleUser": "{0} is typing...",
        "dxChat-typingMessageTwoUsers": "{0} and {1} are typing...",
        "dxChat-typingMessageThreeUsers": "{0}, {1} and {2} are typing...",
        "dxChat-typingMessageMultipleUsers": "{0} and others are typing...",
        "dxColorView-ariaRed": "Rood",
        "dxColorView-ariaGreen": "Groen",
        "dxColorView-ariaBlue": "Blauw",
        "dxColorView-ariaAlpha": "Transparantie",
        "dxColorView-ariaHex": "Kleurcode",
        "dxTagBox-selected": "{0} geselecteerd",
        "dxTagBox-allSelected": "Alles geselecteerd ({0})",
        "dxTagBox-moreSelected": "Nog {0}",
        "dxTagBox-tagRoleDescription": "Tag. Press the delete button to remove this tag",
        "dxTagBox-ariaRoleDescription": "Tag box",
        "vizExport-printingButtonText": "Afdrukken",
        "vizExport-titleMenuText": "Exporteren/Afdrukken",
        "vizExport-exportButtonText": "{0} bestand",
        "dxFilterBuilder-and": "En",
        "dxFilterBuilder-or": "Of",
        "dxFilterBuilder-notAnd": "Niet en",
        "dxFilterBuilder-notOr": "Niet of",
        "dxFilterBuilder-addCondition": "Voorwaarde toevoegen",
        "dxFilterBuilder-addGroup": "Groep toevoegen",
        "dxFilterBuilder-enterValueText": "<vul waarde in>",
        "dxFilterBuilder-filterOperationEquals": "Gelijk aan",
        "dxFilterBuilder-filterOperationNotEquals": "Niet gelijk aan",
        "dxFilterBuilder-filterOperationLess": "Is kleiner dan",
        "dxFilterBuilder-filterOperationLessOrEquals": "Is kleiner dan of gelijk aan",
        "dxFilterBuilder-filterOperationGreater": "Is groter dan",
        "dxFilterBuilder-filterOperationGreaterOrEquals": "Is groter dan of gelijk aan",
        "dxFilterBuilder-filterOperationStartsWith": "Begint met",
        "dxFilterBuilder-filterOperationContains": "Bevat",
        "dxFilterBuilder-filterOperationNotContains": "Bevat niet",
        "dxFilterBuilder-filterOperationEndsWith": "Eindigt met",
        "dxFilterBuilder-filterOperationIsBlank": "Is leeg",
        "dxFilterBuilder-filterOperationIsNotBlank": "Is niet leeg",
        "dxFilterBuilder-filterOperationBetween": "Is tussen",
        "dxFilterBuilder-filterOperationAnyOf": "Is een van",
        "dxFilterBuilder-filterOperationNoneOf": "Is geen van",
        "dxFilterBuilder-filterAriaRootElement": "Filter builder",
        "dxFilterBuilder-filterAriaGroupLevel": "Level {0}",
        "dxFilterBuilder-filterAriaGroupItem": "Group item",
        "dxFilterBuilder-filterAriaOperationButton": "Operation",
        "dxFilterBuilder-filterAriaAddButton": "Add",
        "dxFilterBuilder-filterAriaRemoveButton": "Remove {0}",
        "dxFilterBuilder-filterAriaItemField": "Item field",
        "dxFilterBuilder-filterAriaItemOperation": "Item operation",
        "dxFilterBuilder-filterAriaItemValue": "Item value",
        "dxHtmlEditor-dialogColorCaption": "Lettertype kleur",
        "dxHtmlEditor-dialogBackgroundCaption": "Achtergrond kleur",
        "dxHtmlEditor-dialogLinkCaption": "Link",
        "dxHtmlEditor-dialogLinkUrlField": "URL",
        "dxHtmlEditor-dialogLinkTextField": "Tekst",
        "dxHtmlEditor-dialogLinkTargetField": "Open link in een nieuw venster",
        "dxHtmlEditor-dialogImageCaption": "Afbeelding",
        "dxHtmlEditor-dialogImageUrlField": "URL",
        "dxHtmlEditor-dialogImageAltField": "Alternatieve tekst",
        "dxHtmlEditor-dialogImageWidthField": "Breedte (px)",
        "dxHtmlEditor-dialogImageHeightField": "Hoogte (px)",
        "dxHtmlEditor-dialogInsertTableRowsField": "Rijen",
        "dxHtmlEditor-dialogInsertTableColumnsField": "Kolommen",
        "dxHtmlEditor-dialogInsertTableCaption": "Tabel invoeren",
        "dxHtmlEditor-dialogUpdateImageCaption": "Afbeelding bijwerken",
        "dxHtmlEditor-dialogImageUpdateButton": "Bijwerken",
        "dxHtmlEditor-dialogImageAddButton": "Toevoegen",
        "dxHtmlEditor-dialogImageSpecifyUrl": "Van het web",
        "dxHtmlEditor-dialogImageSelectFile": "Van dit apparaat",
        "dxHtmlEditor-dialogImageKeepAspectRatio": "Verhoudingen behouden",
        "dxHtmlEditor-dialogImageEncodeToBase64": "Coderen als Base64",
        "dxHtmlEditor-heading": "Titel",
        "dxHtmlEditor-normalText": "Normale tekst",
        "dxHtmlEditor-background": "Achtergrondkleur",
        "dxHtmlEditor-bold": "Vet",
        "dxHtmlEditor-color": "Tekstkleur",
        "dxHtmlEditor-font": "Lettertype",
        "dxHtmlEditor-italic": "Cursief",
        "dxHtmlEditor-link": "Koppeling toevoegen",
        "dxHtmlEditor-image": "Afbeelding toevoegen",
        "dxHtmlEditor-size": "Grootte",
        "dxHtmlEditor-strike": "Doorhalen",
        "dxHtmlEditor-subscript": "Subscript",
        "dxHtmlEditor-superscript": "Superscript",
        "dxHtmlEditor-underline": "Onderstrepen",
        "dxHtmlEditor-blockquote": "Blockquote",
        "dxHtmlEditor-header": "Kop",
        "dxHtmlEditor-increaseIndent": "Inspringing vergroten",
        "dxHtmlEditor-decreaseIndent": "Inspringing verkleinen",
        "dxHtmlEditor-orderedList": "Genummerde lijst",
        "dxHtmlEditor-bulletList": "Opsommingstekens",
        "dxHtmlEditor-alignLeft": "Links uitlijnen",
        "dxHtmlEditor-alignCenter": "Centreren",
        "dxHtmlEditor-alignRight": "Rechts uitlijnen",
        "dxHtmlEditor-alignJustify": "Uitlijnen",
        "dxHtmlEditor-codeBlock": "Code Blok",
        "dxHtmlEditor-variable": "Variable toevoegen",
        "dxHtmlEditor-undo": "Ongedaan maken",
        "dxHtmlEditor-redo": "Herhalen",
        "dxHtmlEditor-clear": "Opmaak wissen",
        "dxHtmlEditor-insertTable": "Tabel invoegen",
        "dxHtmlEditor-insertHeaderRow": "Kopregel toevoegen",
        "dxHtmlEditor-insertRowAbove": "Rij invoegen boven",
        "dxHtmlEditor-insertRowBelow": "Rij invoegen onder",
        "dxHtmlEditor-insertColumnLeft": "Kolom invoegen links",
        "dxHtmlEditor-insertColumnRight": "Kolom invoegen rechts",
        "dxHtmlEditor-deleteColumn": "Kolom verwijderen",
        "dxHtmlEditor-deleteRow": "Rij verwijderen",
        "dxHtmlEditor-deleteTable": "Tabel verwijderen",
        "dxHtmlEditor-cellProperties": "Celeigenschappen",
        "dxHtmlEditor-tableProperties": "Tabeleigenschappen",
        "dxHtmlEditor-insert": "Invoegen",
        "dxHtmlEditor-delete": "Verwijdren",
        "dxHtmlEditor-border": "Rand",
        "dxHtmlEditor-style": "Stijl",
        "dxHtmlEditor-width": "Breedte",
        "dxHtmlEditor-height": "Hoogte",
        "dxHtmlEditor-borderColor": "Kleur",
        "dxHtmlEditor-borderWidth": "Border Width",
        "dxHtmlEditor-tableBackground": "Achtergrond",
        "dxHtmlEditor-dimensions": "Afmetingen",
        "dxHtmlEditor-alignment": "Uitlijning",
        "dxHtmlEditor-horizontal": "Horizontaal",
        "dxHtmlEditor-vertical": "Verticaal",
        "dxHtmlEditor-paddingVertical": "Verticale afstand",
        "dxHtmlEditor-paddingHorizontal": "Horizontale afstand",
        "dxHtmlEditor-pixels": "Pixels",
        "dxHtmlEditor-list": "Lijst",
        "dxHtmlEditor-ordered": "Genummerd",
        "dxHtmlEditor-bullet": "Opsommingsteken",
        "dxHtmlEditor-align": "Uitlijnen",
        "dxHtmlEditor-center": "Centreren",
        "dxHtmlEditor-left": "Links",
        "dxHtmlEditor-right": "Rechts",
        "dxHtmlEditor-indent": "Inspringen",
        "dxHtmlEditor-justify": "Uitvullen",
        "dxHtmlEditor-borderStyleNone": "none",
        "dxHtmlEditor-borderStyleHidden": "hidden",
        "dxHtmlEditor-borderStyleDotted": "dotted",
        "dxHtmlEditor-borderStyleDashed": "dashed",
        "dxHtmlEditor-borderStyleSolid": "solid",
        "dxHtmlEditor-borderStyleDouble": "double",
        "dxHtmlEditor-borderStyleGroove": "groove",
        "dxHtmlEditor-borderStyleRidge": "ridge",
        "dxHtmlEditor-borderStyleInset": "inset",
        "dxHtmlEditor-borderStyleOutset": "outset",
        "dxFileManager-newDirectoryName": "Nieuwe folder",
        "dxFileManager-rootDirectoryName": "Bestanden",
        "dxFileManager-errorNoAccess": "Geen toegang. Operatie afgebroken.",
        "dxFileManager-errorDirectoryExistsFormat": "Folder '{0}' bestaat al.",
        "dxFileManager-errorFileExistsFormat": "Bestand '{0}' bestaat al.",
        "dxFileManager-errorFileNotFoundFormat": "Bestand '{0}' niet gevonden.",
        "dxFileManager-errorDirectoryNotFoundFormat": "Folder '{0}' bestaat al.",
        "dxFileManager-errorWrongFileExtension": "Deze bestandsextensie is niet toegestaan.",
        "dxFileManager-errorMaxFileSizeExceeded": "De bestandsgrootte overschrijdt de toegestane limiet",
        "dxFileManager-errorInvalidSymbols": "Deze naam bevat ongeldige tekens.",
        "dxFileManager-errorDefault": "Er is een ongedefinieerde fout opgetreden",
        "dxFileManager-errorDirectoryOpenFailed": "De folder kan niet geopend worden",
        "dxFileManager-commandCreate": "Nieuwe map",
        "dxFileManager-commandRename": "Hernoem",
        "dxFileManager-commandMove": "Verplaats naar",
        "dxFileManager-commandCopy": "Kopieer naar",
        "dxFileManager-commandDelete": "Verwijder",
        "dxFileManager-commandDownload": "Download",
        "dxFileManager-commandUpload": "Upload bestanden",
        "dxFileManager-commandRefresh": "Verversen",
        "dxFileManager-commandThumbnails": "Miniatuurweergave",
        "dxFileManager-commandDetails": "Detailweergave",
        "dxFileManager-commandClearSelection": "Wis selectie",
        "dxFileManager-commandShowNavPane": "Wissel navigatiepaneel",
        "dxFileManager-dialogDirectoryChooserMoveTitle": "Verplaats naar",
        "dxFileManager-dialogDirectoryChooserMoveButtonText": "Verplaats",
        "dxFileManager-dialogDirectoryChooserCopyTitle": "Kopieer naar",
        "dxFileManager-dialogDirectoryChooserCopyButtonText": "Kopieer",
        "dxFileManager-dialogRenameItemTitle": "Hernoem",
        "dxFileManager-dialogRenameItemButtonText": "Opslaan",
        "dxFileManager-dialogCreateDirectoryTitle": "Nieuwe map",
        "dxFileManager-dialogCreateDirectoryButtonText": "Maken",
        "dxFileManager-dialogDeleteItemTitle": "Verwijderen",
        "dxFileManager-dialogDeleteItemButtonText": "Verwijderen",
        "dxFileManager-dialogDeleteItemSingleItemConfirmation": "Weet u zeker dat u {0} wilt verwijderen?",
        "dxFileManager-dialogDeleteItemMultipleItemsConfirmation": "Weet u zeker dat u {0} items wilt verwijderen?",
        "dxFileManager-dialogButtonCancel": "Annuleren",
        "dxFileManager-editingCreateSingleItemProcessingMessage": "Maken van een map binnen {0}",
        "dxFileManager-editingCreateSingleItemSuccessMessage": "Map aangemaakt binnen {0}",
        "dxFileManager-editingCreateSingleItemErrorMessage": "Map was niet aangemaakt",
        "dxFileManager-editingCreateCommonErrorMessage": "Map is niet aangemaakt",
        "dxFileManager-editingRenameSingleItemProcessingMessage": "Hernoemen van een item binnen {0}",
        "dxFileManager-editingRenameSingleItemSuccessMessage": "Item binnen {0} hernoemd",
        "dxFileManager-editingRenameSingleItemErrorMessage": "Item is niet hernoemd",
        "dxFileManager-editingRenameCommonErrorMessage": "Item is niet hernoemd",
        "dxFileManager-editingDeleteSingleItemProcessingMessage": "Item verwijderen uit {0}",
        "dxFileManager-editingDeleteMultipleItemsProcessingMessage": "Verwijderen {0} items uit {1}",
        "dxFileManager-editingDeleteSingleItemSuccessMessage": "Item verwijderd uit {0}",
        "dxFileManager-editingDeleteMultipleItemsSuccessMessage": "{0} items verwijderd uit {1}",
        "dxFileManager-editingDeleteSingleItemErrorMessage": "Item is niet verwijderd",
        "dxFileManager-editingDeleteMultipleItemsErrorMessage": "{0} items zijn niet verwijderd",
        "dxFileManager-editingDeleteCommonErrorMessage": "Sommige items zijn niet verwijderd",
        "dxFileManager-editingMoveSingleItemProcessingMessage": "Item verplaatsen naar {0}",
        "dxFileManager-editingMoveMultipleItemsProcessingMessage": "Verplaatsen {0} items naar {1}",
        "dxFileManager-editingMoveSingleItemSuccessMessage": "Item verplaatst naar {0}",
        "dxFileManager-editingMoveMultipleItemsSuccessMessage": "Verplaatsen {0} items naar {1}",
        "dxFileManager-editingMoveSingleItemErrorMessage": "Item is niet verplaatst",
        "dxFileManager-editingMoveMultipleItemsErrorMessage": "{0} items zijn niet verplaatst",
        "dxFileManager-editingMoveCommonErrorMessage": "Sommige items zijn niet verplaatst",
        "dxFileManager-editingCopySingleItemProcessingMessage": "Item naar {0} kopi\xebren",
        "dxFileManager-editingCopyMultipleItemsProcessingMessage": "Kopi\xebren {0} items naar {1}",
        "dxFileManager-editingCopySingleItemSuccessMessage": "Item gekopieerd naar {0}",
        "dxFileManager-editingCopyMultipleItemsSuccessMessage": "{0} items gekopieerd naar {1}",
        "dxFileManager-editingCopySingleItemErrorMessage": "Item is niet gekopieerd",
        "dxFileManager-editingCopyMultipleItemsErrorMessage": "{0} items zijn niet gekopieerd",
        "dxFileManager-editingCopyCommonErrorMessage": "Sommige items zijn niet gekopieerd",
        "dxFileManager-editingUploadSingleItemProcessingMessage": "Uploaden item naar {0}",
        "dxFileManager-editingUploadMultipleItemsProcessingMessage": "Uploaden {0} items naar {1}",
        "dxFileManager-editingUploadSingleItemSuccessMessage": "Item ge\xfcpload naar {0}",
        "dxFileManager-editingUploadMultipleItemsSuccessMessage": "{0} items ge\xfcpload naar {1}",
        "dxFileManager-editingUploadSingleItemErrorMessage": "Item is niet ge\xfcpload",
        "dxFileManager-editingUploadMultipleItemsErrorMessage": "{0} items zijn niet ge\xfcpload",
        "dxFileManager-editingUploadCanceledMessage": "Geannuleerd",
        "dxFileManager-editingDownloadSingleItemErrorMessage": "Item is niet gedownload",
        "dxFileManager-editingDownloadMultipleItemsErrorMessage": "{0} items zijn niet gedownload",
        "dxFileManager-listDetailsColumnCaptionName": "Name",
        "dxFileManager-listDetailsColumnCaptionDateModified": "Wijzigingsdatum",
        "dxFileManager-listDetailsColumnCaptionFileSize": "Bestandsgrootte",
        "dxFileManager-listThumbnailsTooltipTextSize": "Grootte",
        "dxFileManager-listThumbnailsTooltipTextDateModified": "Wijzigingsdatum",
        "dxFileManager-notificationProgressPanelTitle": "Voortgang",
        "dxFileManager-notificationProgressPanelEmptyListText": "Geen acties",
        "dxFileManager-notificationProgressPanelOperationCanceled": "Geannuleerd",
        "dxDiagram-categoryGeneral": "Algemeen",
        "dxDiagram-categoryFlowchart": "Stroomschema",
        "dxDiagram-categoryOrgChart": "Organisatiediagram",
        "dxDiagram-categoryContainers": "Container",
        "dxDiagram-categoryCustom": "Aangepast",
        "dxDiagram-commandExportToSvg": "Exporteer naar SVG",
        "dxDiagram-commandExportToPng": "Exporteer naar PNG",
        "dxDiagram-commandExportToJpg": "Exporteer naar JPEG",
        "dxDiagram-commandUndo": "Ongedaan maken",
        "dxDiagram-commandRedo": "Opnieuw doen",
        "dxDiagram-commandFontName": "Lettertype",
        "dxDiagram-commandFontSize": "Lettergrootte",
        "dxDiagram-commandBold": "Vet",
        "dxDiagram-commandItalic": "Cursief",
        "dxDiagram-commandUnderline": "Onderstreept",
        "dxDiagram-commandTextColor": "Letterkleur",
        "dxDiagram-commandLineColor": "Lijnkleur",
        "dxDiagram-commandLineWidth": "Lijndikte",
        "dxDiagram-commandLineStyle": "Lijnstijl",
        "dxDiagram-commandLineStyleSolid": "Doorgetrokken",
        "dxDiagram-commandLineStyleDotted": "Gestippeld",
        "dxDiagram-commandLineStyleDashed": "Gestreept",
        "dxDiagram-commandFillColor": "Vulkleur",
        "dxDiagram-commandAlignLeft": "Links uitlijnen",
        "dxDiagram-commandAlignCenter": "Centreren",
        "dxDiagram-commandAlignRight": "Rechts uitlijnen",
        "dxDiagram-commandConnectorLineType": "Verbindingslijn type",
        "dxDiagram-commandConnectorLineStraight": "Recht",
        "dxDiagram-commandConnectorLineOrthogonal": "Orthogonaal",
        "dxDiagram-commandConnectorLineStart": "Verbindingslijn start",
        "dxDiagram-commandConnectorLineEnd": "Verbindingslijn eind",
        "dxDiagram-commandConnectorLineNone": "Geen",
        "dxDiagram-commandConnectorLineArrow": "Pijl",
        "dxDiagram-commandFullscreen": "Volledige schermweergave",
        "dxDiagram-commandUnits": "Eenheden",
        "dxDiagram-commandPageSize": "Pagina grootte",
        "dxDiagram-commandPageOrientation": "Pagina oriëntatie",
        "dxDiagram-commandPageOrientationLandscape": "Landschap",
        "dxDiagram-commandPageOrientationPortrait": "Portrait",
        "dxDiagram-commandPageColor": "Pagina kleur",
        "dxDiagram-commandShowGrid": "Toon raster",
        "dxDiagram-commandSnapToGrid": "Plak aan raster",
        "dxDiagram-commandGridSize": "Raster grootte",
        "dxDiagram-commandZoomLevel": "Zoom niveau",
        "dxDiagram-commandAutoZoom": "Automatische zoom",
        "dxDiagram-commandFitToContent": "Aan inhoud aanpassen",
        "dxDiagram-commandFitToWidth": "Aan breedte aanpassen",
        "dxDiagram-commandAutoZoomByContent": "Automatisch aan inhoud aanpassen",
        "dxDiagram-commandAutoZoomByWidth": "Automatisch aan breedte aanpassen",
        "dxDiagram-commandSimpleView": "Eenvoudige weergave",
        "dxDiagram-commandCut": "Knippen",
        "dxDiagram-commandCopy": "Kopiëren",
        "dxDiagram-commandPaste": "Plakken",
        "dxDiagram-commandSelectAll": "Alles selecteren",
        "dxDiagram-commandDelete": "Verwijderen",
        "dxDiagram-commandBringToFront": "Naar voorgrond",
        "dxDiagram-commandSendToBack": "Naar achtergrond",
        "dxDiagram-commandLock": "Vergrendelen",
        "dxDiagram-commandUnlock": "Ontgrendelen",
        "dxDiagram-commandInsertShapeImage": "Afbeeldingsobject invoegen",
        "dxDiagram-commandEditShapeImage": "Afbeeldingsobject aanpassen",
        "dxDiagram-commandDeleteShapeImage": "Afbeeldingsobject verwijderen",
        "dxDiagram-commandLayoutLeftToRight": "Links-naar-rechts",
        "dxDiagram-commandLayoutRightToLeft": "Rechts-naar-links",
        "dxDiagram-commandLayoutTopToBottom": "Boven-naar-beneden",
        "dxDiagram-commandLayoutBottomToTop": "Beneden-naar-boven",
        "dxDiagram-unitIn": "in",
        "dxDiagram-unitCm": "cm",
        "dxDiagram-unitPx": "px",
        "dxDiagram-dialogButtonOK": "OK",
        "dxDiagram-dialogButtonCancel": "Annuleren",
        "dxDiagram-dialogInsertShapeImageTitle": "Afbeelding invoegen",
        "dxDiagram-dialogEditShapeImageTitle": "Afbeelding aanpassen",
        "dxDiagram-dialogEditShapeImageSelectButton": "Selecteer afbeelding",
        "dxDiagram-dialogEditShapeImageLabelText": "of sleep de afbeelding hier",
        "dxDiagram-uiExport": "Exporteren",
        "dxDiagram-uiProperties": "Eigenschappen",
        "dxDiagram-uiSettings": "Instellingen",
        "dxDiagram-uiShowToolbox": "Gereedschapskist",
        "dxDiagram-uiSearch": "Zoeken",
        "dxDiagram-uiStyle": "Stijl",
        "dxDiagram-uiLayout": "Opmaak",
        "dxDiagram-uiLayoutTree": "Opmaakboom",
        "dxDiagram-uiLayoutLayered": "Gelaagde opmaak",
        "dxDiagram-uiDiagram": "Diagram",
        "dxDiagram-uiText": "Tekst",
        "dxDiagram-uiObject": "Object",
        "dxDiagram-uiConnector": "Verbinding",
        "dxDiagram-uiPage": "Pagina",
        "dxDiagram-shapeText": "Tekst",
        "dxDiagram-shapeRectangle": "Rechthoek",
        "dxDiagram-shapeEllipse": "Ovaal",
        "dxDiagram-shapeCross": "Kruis",
        "dxDiagram-shapeTriangle": "Driehoek",
        "dxDiagram-shapeDiamond": "Diamand",
        "dxDiagram-shapeHeart": "Hart",
        "dxDiagram-shapePentagon": "Vijfhoek",
        "dxDiagram-shapeHexagon": "Zeshoek",
        "dxDiagram-shapeOctagon": "Achthoek",
        "dxDiagram-shapeStar": "Ster",
        "dxDiagram-shapeArrowLeft": "Pijl naar links",
        "dxDiagram-shapeArrowUp": "Pijl naar boven",
        "dxDiagram-shapeArrowRight": "Pijl naar rechts",
        "dxDiagram-shapeArrowDown": "Pijl naar beneden",
        "dxDiagram-shapeArrowUpDown": "Pijl naar boven en beneden",
        "dxDiagram-shapeArrowLeftRight": "Pijl naar links en rechts",
        "dxDiagram-shapeProcess": "Proces",
        "dxDiagram-shapeDecision": "Beslissing",
        "dxDiagram-shapeTerminator": "Aflsuiter",
        "dxDiagram-shapePredefinedProcess": "Gedefinieerd proces",
        "dxDiagram-shapeDocument": "Document",
        "dxDiagram-shapeMultipleDocuments": "Meerdere documenten",
        "dxDiagram-shapeManualInput": "Handmatige invoer",
        "dxDiagram-shapePreparation": "Voorbereiding",
        "dxDiagram-shapeData": "Data",
        "dxDiagram-shapeDatabase": "Database",
        "dxDiagram-shapeHardDisk": "Harde schijf",
        "dxDiagram-shapeInternalStorage": "Interne opslag",
        "dxDiagram-shapePaperTape": "Papierband",
        "dxDiagram-shapeManualOperation": "Handmatige operatie",
        "dxDiagram-shapeDelay": "Vertraging",
        "dxDiagram-shapeStoredData": "Opgeslagen data",
        "dxDiagram-shapeDisplay": "Weergave",
        "dxDiagram-shapeMerge": "Samenvoegen",
        "dxDiagram-shapeConnector": "Verbinding",
        "dxDiagram-shapeOr": "Of",
        "dxDiagram-shapeSummingJunction": "Optelkruising",
        "dxDiagram-shapeContainerDefaultText": "Container",
        "dxDiagram-shapeVerticalContainer": "Vertikale container",
        "dxDiagram-shapeHorizontalContainer": "Horizontale container",
        "dxDiagram-shapeCardDefaultText": "Tekst",
        "dxDiagram-shapeCardWithImageOnLeft": "Kaart met afbeelding links",
        "dxDiagram-shapeCardWithImageOnTop": "Kaart met afbeelding boven",
        "dxDiagram-shapeCardWithImageOnRight": "Kaart met afbeelding rechts",
        "dxGantt-dialogTitle": "Titel",
        "dxGantt-dialogStartTitle": "Start",
        "dxGantt-dialogEndTitle": "Eind",
        "dxGantt-dialogProgressTitle": "Voortgang",
        "dxGantt-dialogResourcesTitle": "Bronnen",
        "dxGantt-dialogResourceManagerTitle": "Beheer bronnen",
        "dxGantt-dialogTaskDetailsTitle": "Taak details",
        "dxGantt-dialogEditResourceListHint": "Pas bronnen aan",
        "dxGantt-dialogEditNoResources": "Geen bronnen",
        "dxGantt-dialogButtonAdd": "Toevoegen",
        "dxGantt-contextMenuNewTask": "Nieuwe taak",
        "dxGantt-contextMenuNewSubtask": "Nieuwe sub-taak",
        "dxGantt-contextMenuDeleteTask": "Verwijder taak",
        "dxGantt-contextMenuDeleteDependency": "Verwijder afhankelijkheid",
        "dxGantt-dialogTaskDeleteConfirmation": "Door het verwijderen van deze taak zullen ook alle subtaken en verbindingen verwijderd worden. Zeker weten?",
        "dxGantt-dialogDependencyDeleteConfirmation": "Moet deze afhankelijkheid van de taak verwijderd worden?",
        "dxGantt-dialogResourcesDeleteConfirmation": "Door deze bron te verwijderen, wordt deze ook uit alle taken verwijderd waaraan hij is toegewezen. Zeker weten om bron '{0}' te verwijderen?",
        "dxGantt-dialogConstraintCriticalViolationMessage": "De taak die verplaatst moet worden is gekoppeld aan een andere taak door een afhankelijkheidsrelatie. Deze wijziging is in strijd met de afhankelijkheidsregels. Hoe verder?",
        "dxGantt-dialogConstraintViolationMessage": "De taak die verplaatst moet worden is gekoppeld aan een andere taak door een afhankelijkheidsrelatie. Hoe verder?",
        "dxGantt-dialogCancelOperationMessage": "Annuleer de operatie",
        "dxGantt-dialogDeleteDependencyMessage": "Verwijder de afhankelijkheid",
        "dxGantt-dialogMoveTaskAndKeepDependencyMessage": "Verplaats de taak en behoud de afhankelijkheid",
        "dxGantt-dialogConstraintCriticalViolationSeveralTasksMessage": "De taak die u probeert te verplaatsen is gekoppeld aan andere taken door afhankelijkheidsrelaties. Deze wijziging conflicteerd met afhankelijkheidsregels. Hoe wilt u verder gaan?",
        "dxGantt-dialogConstraintViolationSeveralTasksMessage": "De taak die u probeert te verplaatsen is gekoppeld aan andere taken door afhankelijkheidsrelaties. Hoe wilt u verder gaan?",
        "dxGantt-dialogDeleteDependenciesMessage": "Verwijder de afhankelijkheidsrelaties",
        "dxGantt-dialogMoveTaskAndKeepDependenciesMessage": "Verplaats de taak en behoud de afhankelijkheden",
        "dxGantt-undo": "Ongedaan maken",
        "dxGantt-redo": "Opnieuw doen",
        "dxGantt-expandAll": "Alles uitklappen",
        "dxGantt-collapseAll": "Alles inklappen",
        "dxGantt-addNewTask": "Nieuwe taak",
        "dxGantt-deleteSelectedTask": "Verwijder geselecteerde taak",
        "dxGantt-zoomIn": "Inzoomen",
        "dxGantt-zoomOut": "Uitzoomen",
        "dxGantt-fullScreen": "Volledig scherm",
        "dxGantt-quarter": "Q{0}",
        "dxGantt-sortingAscendingText": "Sorteer oplopend",
        "dxGantt-sortingDescendingText": "Sorteer aflopend",
        "dxGantt-sortingClearText": "Sortering wissen",
        "dxGantt-showResources": "Toon bronnen",
        "dxGantt-showDependencies": "Toon afhankelijkheden",
        "dxGantt-dialogStartDateValidation": "Startdatum moet liggen na {0}",
        "dxGantt-dialogEndDateValidation": "Einddatum moet liggen na {0}",
        "dxGallery-itemName": "Gallery item",
        "dxMultiView-elementAriaRoleDescription": "MultiView",
        "dxMultiView-elementAriaLabel": "Use the arrow keys or swipe to navigate between views",
        "dxMultiView-itemAriaRoleDescription": "View",
        "dxMultiView-itemAriaLabel": "{0} of {1}",
        "dxSplitter-resizeHandleAriaLabel": "Split bar",
        "dxSplitter-resizeHandleAriaRoleDescription": "Separator"
    }
};