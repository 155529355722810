import React, { useEffect, useState, useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import AuthService from '@liasincontrol/auth-service';
import { Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { Title } from './index.styled';

/**
 * Represents an UI component that renders a not found page.
 */
const NotFound: React.FC = () => {
    const { cause } = useParams<{ cause: 'omniauser' | 'license' | 'permission' | 'invalid-config' | undefined }>();
    const [isLoginError, setIsLoginError] = useState<boolean>(false);

    const title = useMemo(() => {
        switch (cause) {
            case 'omniauser':
                return 'Login fout';
            case 'permission':
            case 'license':
                return 'Toegangsfout';
        }

        return 'Pagina niet gevonden';
    }, [cause]);

    const errorMessage = useMemo(() => {
        switch (cause) {
            case 'omniauser':
                return isLoginError 
                    ? 'Er is een fout opgetreden bij het inloggen. Neem contact op met de applicatiebeheerder van uw organisatie.' 
                    : 'U kunt niet inloggen omdat u niet bent gekoppeld aan de InControl applicatie. Neem contact op met de applicatiebeheerder van uw organisatie.';
            case 'permission':
                return 'U heeft niet de benodigde rechten om dit scherm te openen. Neem contact op met de applicatiebeheerder van uw organisatie.';
            case 'license':
                return 'Uw organisatie heeft geen licentie voor dit onderdeel van de applicatie';
            case 'invalid-config':
                return 'Er is een fout opgetreden bij het ophalen van de configuratie. Neem contact op met de helpdesk van Ilionx Group B.V. via lias.servicedesk@ilionx.com.';
        }

        return null;
    }, [cause, isLoginError]);

    useEffect(() => {
        AuthService.getInstance()
            .then(async (auth) => {
                const userIndentity = await auth.getUser();
                if (userIndentity?.profile?.error) {
                    setIsLoginError(true);
                }
            });
    }, []);


    return (
        <Wrapper>
            <WrapperContent>
                <Title>{title}</Title>
                {errorMessage && <p>{errorMessage}</p>}
                {cause !== 'omniauser' && cause !== 'invalid-config' && <Link id="btn-back" to="/">Naar de cockpit</Link>}
            </WrapperContent>
        </Wrapper>
    );
};

export default NotFound;