import React from 'react';
import { Button } from 'devextreme-react/button';
import { Tooltip } from 'devextreme-react/tooltip';
import { withField, WithFieldBaseType } from '../field';

export type ButtonFieldProps = WithFieldBaseType<any> & {
    name: string,
    onReset?: () => void,
};

export const ButtonField = withField<any, ButtonFieldProps>(
    (props) => {
        return <div>
            <Button
                id={`button-${props.id}`}
                type="default"
                stylingMode='text'
                text={props.name}
                onClick={(e) => props.onChange?.(e.event)}
                disabled={props.disabled}>
            </Button>
            {props.onReset && <>
                <Button
                    id={`btn-reset-filter-to-template-${props.id}`}
                    type="default"
                    stylingMode='text'
                    disabled={props.disabled}
                    icon="refresh"
                    onClick={() => props.onReset?.()} />
                <Tooltip
                    target={`#btn-reset-filter-to-template-${props.id}`}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    hideOnOutsideClick={true}
                >
                    Laad {props.label} uit template
                </Tooltip>
            </>}
        </div>
    }
);
