import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { Section } from '@liasincontrol/ui-elements';
import { isMomentOpen } from '@liasincontrol/core-service';
import { Performance } from '@liasincontrol/data-service';
import { IconHelper } from '../../../helpers/IconHelper';
import { StatisticsHelper } from '../../../helpers/StatisticsHelper';
import Styled from './index.styled';
import { ActionSource, useMeasureMoments, useElementDefinitions, AjaxRequestStatus } from '@liasincontrol/redux-service';

/**
 * Defines the props of the PerformanceTiles component.
 */
type Props = {
    performanceModuleId: string;
};

/**
 * Represents a UI component that renders the performance tiles list.
 */
export const PerformanceIconTiles: React.FC<Props> = (props) => {
    const { measureMoments } = useMeasureMoments();
    const { elementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: props.performanceModuleId });
    const [countedHierarchyItems, setCountedHierarchyItems] = useState<Record<string, Record<string, number>>>({});

    const types = useMemo(() => {
        if (elementDefinitions?.status !== AjaxRequestStatus.Done) {
            return [];
        }
        return Object.keys(elementDefinitions.items).reduce((filtered, option) => {
            const item = elementDefinitions.items[option];
            const systemId = item.systemId as Domain.Performance.HierarchyItemElementType;

            if (StatisticsHelper.availableTypes.indexOf(systemId) >= 0) {
                const type = {
                    id: option,
                    systemId: systemId,
                    name: item.name,
                    fields: item.fields,
                };
                filtered.push(type);
            }
            return filtered;
        }, []);

    }, [elementDefinitions?.status]);

    useEffect(() => {
        // Get the first open moment, if it exists.
        const filteredMoments = measureMoments.items.filter((moment) => isMomentOpen(moment.status));
        const firstOpenMoment = filteredMoments.length > 0 ? filteredMoments[0] : undefined;

        if (!firstOpenMoment || elementDefinitions?.status !== AjaxRequestStatus.Done) {
            return;
        }
        Performance.HierarchyDataAccessor.get(firstOpenMoment.id).then((result) => {
            setCountedHierarchyItems(StatisticsHelper.getHierarchyItemsStatByTypeAndProgress(result.data.hierarchy, elementDefinitions.items));
        });
    }, [elementDefinitions?.status, measureMoments]);

    return (
        <>
            {types.map((type) => (
                <Section key={type.systemId} look='transparent' padding={false} grid={true} colSpan={1} rowSpan={1} widget={true}>
                    <Styled.LinkList key={`btn-${type.systemId}`} to={`/performance/tree/overview/${type.systemId}`}>
                        <Styled.Content>
                            <Styled.Title style={{ textOverflow: 'ellipsis' }}>{type.name}</Styled.Title>
                            {countedHierarchyItems && (
                                <Styled.StatusWrapper>
                                    {type.fields.map((item) =>
                                        _.sortBy(item.optionItems, (item) => StatisticsHelper.progressOrder.indexOf(item.order)).map((optionItem) => (
                                            <Styled.StatusContent key={`div-${type.systemId}-${optionItem.id}`}>
                                                {IconHelper.getPerformanceCockpitProgressIcon(optionItem.name, 42)}
                                                <Styled.Label>{countedHierarchyItems[type.systemId] && countedHierarchyItems[type.systemId][optionItem.name] ? countedHierarchyItems[type.systemId][optionItem.name] : '0'}</Styled.Label>
                                            </Styled.StatusContent>
                                        ))
                                    )}
                                </Styled.StatusWrapper>
                            )}
                        </Styled.Content>
                    </Styled.LinkList>
                </Section>
            ))}
        </>
    );
};
