import React from 'react';
import { MultiLineTextEditor } from './index.editor';
import { MultiLineTextProps } from './index.props';
import { TextViewer } from '../../shared/TextViewer';

/**
 * Represents a UI component that renders an multi line text element.
 */
export const MultiLineTextElement: React.FC<MultiLineTextProps> = (props) => {
    if (props.editorSettings?.disabled && props.editorSettings?.readMore?.active) {
        return <TextViewer {...props} />;
    }
    return <MultiLineTextEditor {...props} />;
};