import { css } from 'styled-components';
import { palette, px } from "@liasincontrol/ui-basics";
import { opacify } from 'polished';


//Base.tsx
export const whenDisabled = (c: any) => (p: any) => p.disabled && css`:nth-child(n) { 
    ${c}
}`;

export const base = {
    button: css`
        background-color: transparent;
        border: 0 none transparent;
        box-sizing: border-box;
        cursor: pointer;
        color: inherit;
        font: inherit;
        display: inline-block;
        margin: 0;
        outline: 0 none transparent;
        padding: 0;
        position: relative;
        transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, box-shadow 0.3s ease;
        text-decoration: none;
        user-select: none;
        > span { text-transform: uppercase; }
        ${whenDisabled(css`cursor: default;`)}
    `,
    colors: (foreground: string, background: string, border = ``, pxBorderWidth = 2) => (
        css`
        background-color: ${background};
        border: ${background === (border || background)
                ? `${px(pxBorderWidth)} solid ${opacify(-1, background)}`
                : `${px(pxBorderWidth)} solid ${border || background}`};
        span, svg{
            color: ${foreground};
            line-height: 1;
        }
    `),
};

const Tooltip = css`
    > span > span {
        box-shadow: 0 0 ${px(20)} rgba(75, 85, 246, 0.3);
        opacity: 0;
        pointer-events: none;
        color: #fff;
        position: absolute;
        top: calc(100% + 4px);
        background: ${palette.grey2};
        display: block;
        padding: 5px 16px;
        border-radius: 4px;
        left: 50%;
        transform: translateX(-50%) scale(0.95);
        z-index: 1;
        transition: opacity 0.2s, top 0.2s, transform 0.2s;
        text-align: center;
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translateX(-50%) rotate(45deg);
            width: 6px;
            height: 6px;
            border: solid red;
            border-color: #414141 transparent transparent #414141;
        }
    }
    :hover > span > span {
        transition-delay: 0.5s;
        color: #fff;
        opacity: 1;
        pointer-events: all;
        top: calc(100% + 7px);
        transform: translateX(-50%) scale(1);
    }
`;

//end base.tsx

//Button internal styles... todo => whyyyy???
export const cssInternalButtonContent = css`
    align-items: center;
    justify-items: center;
    border: 0 solid transparent;
    display: grid;
    grid-auto-flow: column;
    grid-gap: ${px(8)};
    outline: 0;
    transition: color 0.2s ease, background-color 0.2s ease, border-color 0.2s ease, border-width 0.2s ease, box-shadow 0.3s ease;
`;

export const medium_admin_container = css`
    border-radius: 50% !important;
          > span > svg {
              color: inherit;
              fill: currentColor;
              width: 18px;
              height: 18px;
          }
          > span {
              height: ${px(36)};
              width: ${px(36)};
              border-radius: 50%;
              ${base.colors(palette.white, palette.transparent)};
              border-width: 1px;
          }
          :focus > span {
              ${base.colors(palette.primary2, palette.white, palette.primary1b)}
              border-width: 1px;
          }
          :hover > span {
              ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
              border-width: 1px;
          }
          :active > span {
              ${base.colors(palette.white, 'rgba(255, 255, 255, .2)')}
              border-width: 1px;
          }
          > span {
              ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
          }

          ${Tooltip}
`;

export const large_icon_container = css`
        border-radius: ${px(100)};
        > span > svg {
            color: inherit;
            fill: currentColor;
            width: 18px;
            height: 18px;
        }
        > span {
            padding: ${px(9)} ${px(16)};
            border-radius: ${px(100)};
            ${base.colors(palette.primary2, palette.transparent)}
        }
        :focus > span {
            ${base.colors(palette.primary1b, palette.transparent, palette.primary1b)}
        }
        :hover > span  {
            ${base.colors(palette.primary1b, opacify(-.95, palette.primary2))}
        }
        :active > span  {
            ${base.colors(palette.primary1b, opacify(-.90, palette.primary2))}
        }
        > span {
            ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
        }
`;
export const bluebackgroundbuttons_medium_icon_container = css`
            border-radius: ${px(100)};
            outline: 0;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                padding: ${px(7)} ${px(16)};
                border-radius: ${px(100)};
                border-width: 2px;
                ${base.colors(palette.white, `#15195F`)};
            }
            :focus > span {
                ${base.colors(palette.white, `#15195F`, palette.white)}
            }
            :hover > span {
                ${base.colors(palette.white, '#484d88')}
            }
            :active > span {
                ${base.colors(palette.white, '#484d88')}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }
`;

export const iconbuttons_medium_transparentalt_container = css`
            border-radius: 50% !important;
            > span > svg {
                color: inherit;
                fill: currentColor;
                width: 18px;
                height: 18px;
            }
            > span {
                height: ${px(36)};
                width: ${px(36)};
                border-radius: 50%;
                ${base.colors(palette.grey2, palette.transparent)};
            }
            :focus > span {
                ${base.colors(palette.grey2, palette.white, palette.primary1b)}
            }
            :hover > span {
                ${base.colors(palette.grey2, palette.grey4)}
            }
            :active > span {
                ${base.colors(palette.grey2, palette.grey4)}
            }
            > span {
                ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
            }

            ${Tooltip}
`;

export const textbuttons_alt_icon_container = css`
border-radius: ${px(100)};
> span > svg {
    color: inherit;
    fill: currentColor;
    width: 14px;
    height: 14px;
}
> span {
    padding: ${px(9)} ${px(16)};
    border-radius: ${px(100)};
    ${base.colors(palette.grey2, palette.transparent)}
}
:focus > span {
    ${base.colors(palette.grey2, palette.transparent, palette.primary1b)}
}
:hover > span  {
    ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
}
:active > span  {
    ${base.colors(palette.grey2, opacify(-.90, palette.grey3b))}
}
> span {
    ${whenDisabled(base.colors(palette.grey3, palette.grey4))}
}
`;

