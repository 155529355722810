import React, { JSX } from 'react';
import Styled, { DevicePosition } from './index.styled';
import { Variants } from 'framer-motion';

export type DeviceType = 'desktop' | 'tablet' | 'phone' | 'debug';

export interface DeviceFrameProps {
    readonly device?: DeviceType;
    readonly onHome?: () => void;
    readonly position?: DevicePosition;

}

export const deviceVariants: Variants = {
    desktop: {
        width: '1400px',
        height: '750px',
    },
    tablet: {
        width: '1140px',
        height: '720px',
    },
    phone: {
        width: '430px',
        height: '932px',
    },
};


const Desktop = {
    width: '1320px',
    height: '700px',
};

const Tablet = {
    width: '1080px',
    height: '660px',
};

const Phone = {
    width: '380px',
    height: '860px',
};

export const contentVariants: Variants = {
    desktop: Desktop,
    tablet: Tablet,
    phone: Phone,
};


export const DeviceFrame = ({ device, position }: DeviceFrameProps) => {
    return (
        <Styled.Frame
            $position={position}
            variants={deviceVariants}
            initial="desktop"
            animate={device}
        />
    );
};


const getLayoutByDeviceElement = (device: DeviceType, children: React.ReactNode): JSX.Element => {
    switch (device) {
        case 'phone': return (<Styled.PhoneWrapper><>{children}</></Styled.PhoneWrapper>);
        case 'tablet': return (<Styled.ScaledTabletWrapper><>{children}</></Styled.ScaledTabletWrapper>);
        case 'desktop': return (<Styled.DesktopWrapper><>{children}</></Styled.DesktopWrapper>);
        default: return (<Styled.ScaledTabletWrapper><>{children}</></Styled.ScaledTabletWrapper>);
    }
};

export const Device = ({ device, children, onHome }: { device: DeviceType; children: React.ReactNode; onHome?: () => void }) => {
    if (device === 'debug') {
        device = 'desktop';
    }

    return (
        <Styled.DeviceWrapper >
            <DeviceFrame device={device} onHome={onHome}/>
            <Styled.DeviceContent variants={contentVariants} id="device-content" className={device} animate={device} initial="desktop">
                {getLayoutByDeviceElement(device, children)}
            </Styled.DeviceContent>
        </Styled.DeviceWrapper>
    );
};
