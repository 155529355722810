import React from 'react';
import styled from 'styled-components';

import { ElementLabel } from '../typography';

export interface ILabelProps {
  readonly text?: React.ReactNode;
}

export const Label = React.forwardRef<HTMLLabelElement, ILabelProps & React.LabelHTMLAttributes<HTMLLabelElement>>(
  ({ children, text = children, ...rest }, innerRef) => (<Container {...rest} ref={innerRef}><>{text}</></Container>),
);

/* tslint:disable:no-magic-numbers */
const Container = styled(ElementLabel)`
  padding: 0;
  min-height: 25px;
  line-height: 24px;
`;
