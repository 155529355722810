import { palette } from "@liasincontrol/ui-basics";
import { ComponentWithEditButton, ComponentWithEditButtonType } from "../genericComponents";
import { InnerProps, enhanceWithTooltip } from "../genericComponents/enhanceWithTooltip";
import { styled } from "styled-components";

type Props = {
  displayedValue: string;
  buttonProps: ComponentWithEditButtonType;
} & InnerProps;

const Caption = styled.div<{ palette: any }>`
  background-color: ${(props) => props.palette.grey4};
  border-radius: 3px;
  font-size: 0.9rem;
  padding: 4px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Button = ({ displayedValue, buttonProps, ...rest }: Props) => {
  return displayedValue ? (
    <ComponentWithEditButton {...buttonProps}>
      <Caption palette={palette} {...rest}>
        {displayedValue}
      </Caption>
    </ComponentWithEditButton>
  ) : null;
};

export const WorkflowStatusWithEditButton = enhanceWithTooltip<Props>(Button);
