import React from 'react';
import styled, { css } from 'styled-components';
import { Button } from 'devextreme-react/button';
import CloseIcon from '@mui/icons-material/Close';
import { cssBreakpoint, px, Bar } from '@liasincontrol/ui-basics';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';
import { IContainerProps, ISectionProps } from './Props';

const Container = styled.section<IContainerProps>`
    position:relative;
    display:inline-block;
    text-align:left;
    border-radius:4px;
    background: ${(p) => p.$look === 'white' ? '#fff' : ''};
    margin: ${(p) => p.$grid === false ? '0 0 24px 0' : null};
    ${(p) => p.$padding === true ?
        p.$widget === true ? css`padding: 16px 24px;` : css`padding: 32px 40px;`
        : ''};
    max-width: ${(p) => (p.$fixedWidth === true ? `calc(100vw - ${px(280)} - 8rem)` : '')};
    width: ${(p) => p.$grid === false ? '100%' : ''};
    overflow-x: ${(p) => (p.$fixedWidth && 'auto')};
    grid-row: span ${(p) => p.$rowSpan};
    grid-column: span ${(p) => p.$colSpan};
    box-shadow:${(p) => (p.$look === 'white' && '0 5px 20px rgba(75, 85, 246, .06)') || 'none'};

    ${cssBreakpoint('xs', 's', 'm')`        
        margin: ${(p) => (p['grid'] === false ? `0 0 12px 0` : `unset`)};
        max-width: ${(p) => (p['fixedWidth'] === true ? `100vw` : '')};
    `};

    ${cssBreakpoint('xs')`
        grid-column: 1 / -1;
    `};
`;

export const Section: React.FC<ISectionProps> = (
    { ref, children, className, look = 'transparent', padding = true, colSpan = 2, rowSpan = 2, grid = false, widget = false, fixedWidth = false,  title, withCloseButton = false, onClose }) => (
    <Container
        className={className}
        $look={look}
        $padding={padding}
        $colSpan={colSpan}
        $rowSpan={rowSpan}
        $grid={grid}
        $widget={widget}
        ref={ref}
        $fixedWidth={fixedWidth}>
        {title || withCloseButton ? <Bar look='toolbar'>
            {title && <Bar start>{title}</Bar>}
            {withCloseButton && <Bar end> <Button type="normal" stylingMode='contained' icon={getMuiIconAsSvgString(CloseIcon)} onClick={onClose}></Button></Bar>}
        </Bar>
            : null}
        <>{children}</>
    </Container >
);
