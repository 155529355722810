import { createReducer } from '@reduxjs/toolkit';
import { LiasAttachmentActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus, LiasAttachmentState } from '../../../state';

const initialState: LiasAttachmentState = {};

/**
 * Represents the attachments reducer.
 */
const reducer = createReducer<LiasAttachmentState>(initialState, (builder) => {
    builder
        .addCase(LiasAttachmentActionCreator.fetch.pending, (state, action) => {
            state[action.meta.arg.attachmentId] = {
                attachment: undefined,
                status: AjaxRequestStatus.Pending,
            };
        })
        .addCase(LiasAttachmentActionCreator.fetch.fulfilled, (state, action) => {
            const { attachmentId, attachment } = action.payload;
            state[attachmentId] = {
                attachment: attachment,
                status: AjaxRequestStatus.Done,
            };
        })
        .addCase(LiasAttachmentActionCreator.fetch.rejected, (state, action) => {
            state[action.meta.arg.attachmentId] = {
                attachment: undefined,
                status: AjaxRequestStatus.Failed,
            };
        })
        .addCase(LiasAttachmentActionCreator.upload.pending, (state, action) => {
            // state[action.meta.arg.file.name] = {
            //     attachment: undefined,
            //     status: AjaxRequestStatus.Pending,
            // };
        })
        .addCase(LiasAttachmentActionCreator.upload.fulfilled, (state, action) => {
            //place new stuff in state
            const attachmentId = action.payload;
            state[attachmentId] = {
                attachment: action.meta.arg.file,
                status: AjaxRequestStatus.Done,
            };
        })
        .addCase(LiasAttachmentActionCreator.upload.rejected, (state, action) => {
            // state[action.meta.arg.file.name] = {
            //     attachment: undefined,
            //     status: AjaxRequestStatus.Failed,
            // };
        })

        .addCase(LiasAttachmentActionCreator.set, (state, action) => {
            state[action.payload.data.attachmentId] = {
                attachment: action.payload.data.attachment,
                status: AjaxRequestStatus.Done,
            };
        })
        .addCase(LiasAttachmentActionCreator.remove, (state, action) => {
            //No server remove!!!
            delete state[action.payload.data.attachmentId];
        })
        .addCase(TenantActionCreator.initTenant, (state, action) => {
            return initialState;
        });
});

export default reducer;
