import React from 'react';
import styled from 'styled-components';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Popover } from 'devextreme-react/popover';
import { Button } from 'devextreme-react/button';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { cssFontBold, cssFontNormal, IconSize, palette } from '@liasincontrol/ui-basics';
import { IHelpTextProps } from './Props';
import { getMuiIconAsSvgString } from '../modal';
// tslint:disable:no-magic-numbers

export interface IPopoverProps {
    readonly isVisible: boolean;
}

export interface IHelpTextState {
    readonly popOver: boolean;
}

//export const HelpText: React.FC<IHelpTextProps, IHelpTextState> = (props) => {

export class HelpText extends React.Component<IHelpTextProps, IHelpTextState> {
    public state = { popOver: false };

    public render() {
        const { children, id, title, type } = this.props;

        return (<>
            <AllignedButton
                id={`btn-show-info-${id}`}
                type="default"
                stylingMode='text'
                className="btn-small"
                onClick={this.show}
                icon={getIconName(type)}
            />
            <Popover
                target={`#btn-show-info-${id}`}
                hideOnOutsideClick={true}
                hideOnParentScroll={true}
                showEvent="click"
                position="right"
            >
                <Wrap>{title ? <Title>{title}</Title> : null}{children ? <Content>{children}</Content> : null} </Wrap>
            </Popover>
        </>
        );
    }

    public show = () => {
        this.setState({ popOver: !this.state.popOver });
    }
}

const getIconName = (type): string => {
    switch (type) {
        case 'info':
            return getMuiIconAsSvgString(InfoOutlinedIcon, IconSize.extramediumsmall);
        case 'warning':
            return getMuiIconAsSvgString(WarningAmberOutlinedIcon, IconSize.extramediumsmall, palette.warningOrange);
        case 'hint':
            return getMuiIconAsSvgString(HelpOutlineOutlinedIcon, IconSize.extramediumsmall);
        default:
            return getMuiIconAsSvgString(InfoOutlinedIcon, IconSize.extramediumsmall);
    }
};

/* styling */

const Wrap = styled.div`
    padding: 0px;
    max-width: 350px;
`;

const AllignedButton = styled(Button)`
    vertical-align: middle;
`;

const Title = styled.h6`
    margin:0; padding:0 0 3px 0;
    ${cssFontBold(14, 20, 40)}
`;

const Content = styled.p`
    white-space: pre-wrap;
    margin:0; 
    padding:0 0 3px 0;
    word-wrap: break-word;
    ${cssFontNormal(14, 20, 0)}
`;
