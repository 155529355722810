import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { IconSize, px } from '@liasincontrol/ui-basics';
import React from 'react';
import styled from 'styled-components';
import { Button } from 'devextreme-react/button';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';

export interface IPresentationProps {
    readonly editable: boolean;
    readonly active: boolean;
    readonly bottom: number;
    readonly right: number;
    readonly onBackToTop: () => void;
    readonly onEdit: () => void;
}

const BtnWrap = styled.div`
    position: fixed;
    display: grid;
    grid-gap: ${px(16)};
    grid-auto-flow: row;
    grid-auto-rows: auto;
`;

const offsetX = 40;
const offsetY = 32;

export const FloaterButtons = React.forwardRef<any, IPresentationProps>(({ active, editable, onEdit, onBackToTop, bottom, right }, ref) => (
    <BtnWrap
        className="floater-btns"
        role="navigation"
        ref={ref}
        style={{ top: `${bottom - offsetY}px`, left: `${right - offsetX}px` }}>
        <Button
            id="btn-gototop"
            type="default"
            stylingMode='outlined'
            className='btn-large'
            onClick={onBackToTop} hint="Naar boven"
            icon="arrowup" />
        {editable
            ? <Button id="btn-gotoeditable" type="normal" stylingMode='contained' onClick={onEdit} hint="Bewerken" icon={getMuiIconAsSvgString(EditOutlinedIcon, IconSize.large)} />
            : null}
    </BtnWrap>
));
