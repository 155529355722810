import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { IconSize, SVGIcon } from '@liasincontrol/ui-basics';
import { StringUtils } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import { DxTabsIconPosition, ILsColumnProps } from '..';
import { Format } from 'devextreme/localization';

export const renderModeAvailable: string[] = ['OptionField', 'ElementDefinition'];

const iconPositionToFlexDirection = {
    "top": "column",
    "bottom": "column-reverse",
    "start": "row",
    "end": "row-reverse",
};

const iconPositionToMargin = {
    start: 'margin-right',
    end: 'margin-left',
}

export const IconContainer = styled.div<{ $align: `left` | `right` | `center`, $iconPosition?: DxTabsIconPosition, $maxWidth?: number }>`
    display: flex;
    justify-content: ${({ $align: align }) => align};
    ${({ $iconPosition: iconPosition }) => !!iconPosition && `flex-direction: ${iconPositionToFlexDirection[iconPosition]}`};

    svg {
        ${({ $iconPosition: iconPosition }) => !!iconPosition && `${iconPositionToMargin[iconPosition || 'start']}: 0.25rem`};
    }

    max-width: ${({ $maxWidth: maxWidth }) => maxWidth ? `${maxWidth}px` : 'none'};

    div.icon-name {
        align-self: ${({ $maxWidth: maxWidth, $iconPosition: iconPosition = 'start' }) => (['start', 'end'].includes(iconPosition) || (!maxWidth)) ? 'center' : 'stretch'};
        word-wrap: break-word;
        min-width: 10%;
        text-overflow: ellipsis;
        overflow: hidden;
    }
`;

export const MaxWidthContainer = styled.div<{ $maxWidth?: number }>`
    max-width: ${({ $maxWidth }) => $maxWidth ? `${$maxWidth}px` : 'none'};
    overflow: ${({ $maxWidth }) => $maxWidth ? 'hidden' : 'visible'};
    ${({ $maxWidth }) => $maxWidth && `word-wrap: break-word;`}
`;

export type GroupRenderModeProps = {
    groupRenderMode?: GroupRenderMode,
    propertyGroupName?: string,
    propertyGroupKind?: string,
};

export enum GroupRenderMode {
    ColoredIconName = 1,
    ColoredIcon = 2,
    Name = 3,
    Value = 4,
}

type GroupRenderModeFunc = (columnData: ILsColumnProps, rowData: any, icons: Record<string, Domain.Shared.SvgIcon>,
    iconSize: IconSize, iconPosition?: DxTabsIconPosition, maxWidth?: number) => React.ReactNode;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const groupRenderModes: { [key in GroupRenderMode]: GroupRenderModeFunc } = {
    [GroupRenderMode.ColoredIconName]: (columnData: ILsColumnProps, rowData: any, icons: Record<string, Domain.Shared.SvgIcon>,
        iconSize = IconSize.medium, iconPosition?: DxTabsIconPosition, maxWidth?: number) => {
        const color = rowData[columnData.propertyGroupName as string + 'Color'];
        const iconId = rowData[columnData.propertyGroupName as string + 'Icon'];
        const name = rowData[columnData.propertyGroupName as string + 'Name'];

        return (name &&
            <IconContainer $align={columnData.alignment || 'left'} $iconPosition={iconPosition} $maxWidth={maxWidth}>
                <SVGIcon displayMode='inline' value={icons[iconId]?.svg} size={iconSize} color={color} />
                <div className='icon-name' dangerouslySetInnerHTML={StringUtils.toRawMarkup(name)}></div>
            </IconContainer>
        );
    },
    [GroupRenderMode.ColoredIcon]: (columnData: ILsColumnProps, rowData: any, icons: Record<string, Domain.Shared.SvgIcon>, iconSize = IconSize.medium) => {
        const color = rowData[columnData.propertyGroupName as string + 'Color'];
        const iconId = rowData[columnData.propertyGroupName as string + 'Icon'];
        const name = rowData[columnData.propertyGroupName as string + 'Name'];
        return (name &&
            <IconContainer $align={columnData.alignment || 'left'}><SVGIcon displayMode='inline' value={icons[iconId]?.svg} size={iconSize} color={color} /></IconContainer>
        )
    },
    [GroupRenderMode.Name]: (columnData: ILsColumnProps, rowData: any, icons: Record<string, Domain.Shared.SvgIcon>, iconSize = IconSize.medium, iconPosition?: DxTabsIconPosition, maxWidth?: number) => {
        if (_.isEmpty(rowData)) {
            return;
        }
        const name = rowData[columnData.propertyGroupName as string + 'Name'];
        return <MaxWidthContainer dangerouslySetInnerHTML={StringUtils.toRawMarkup(name)} $maxWidth={maxWidth}></MaxWidthContainer>
    },
    [GroupRenderMode.Value]: (columnData: ILsColumnProps, rowData: any, icons: Record<string, Domain.Shared.SvgIcon>, iconSize = IconSize.medium, iconPosition?: DxTabsIconPosition, maxWidth?: number) => {
        if (_.isEmpty(rowData)) {
            return;
        }

        const name = rowData[columnData.propertyGroupName as string + 'Value'];
        return <MaxWidthContainer $maxWidth={maxWidth}>{name}</MaxWidthContainer>
    }
};

export const groupCellRender = (rowData, column, icons?, iconSize?: IconSize, iconPosition?: DxTabsIconPosition, maxWidth?: number) => {
    return groupRenderModes[column?.groupRenderMode || GroupRenderMode.Name](column, rowData, icons, iconSize, iconPosition, maxWidth);
}

// Generate base62 hash ID based on text
export const generateSafeId = (text?: string): string => {
    if (!text) {
        return '';
    }

    // Generate a Base62 hash from the input text
    const base62Chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
        const char = text.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash |= 0; // Convert to 32bit integer
    }

    let base62Hash = '';
    let num = Math.abs(hash);

    while (num > 0) {
        base62Hash = base62Chars[num % 62] + base62Hash;
        num = Math.floor(num / 62);
    }

    // Prepend 'id_' to ensure it's a valid HTML ID
    return `id_${base62Hash || '0'}`;
}


export const minColumnWidth = 32;
export const dateFormat = 'dd-MM-yyyy';
export const numberFormat = '#0,###.##';

export const getColumnFormat = (column): Format => {
    if (!column) return '';

    switch (column.dataType) {
        case 'datetime':
        case 'date':
            return dateFormat;
        case 'number':
            return column.format ? column.format : numberFormat;
        default:
            return column.format;
    }
};
