import React from 'react';
import { Link } from 'react-router-dom';
import Styled from './index.styled';

/**
 * Represent a type of a task summary shown in the task tile .
 */
export type CardListItem = {
    title: string,
    value: number,
};

type Props = {
    readonly id: string,
    readonly title: string,
    readonly listItems: CardListItem[],
    readonly url?: string,
    readonly disabled?: boolean,
    readonly direction?: 'horizontal' | 'vertical',
    readonly withMargin?: boolean,
    readonly withDonut?: boolean,
};

/**
 * Represents an UI component that displays a card container with title, list of entries and arrow button.
 */
export const CardList: React.FC<Props> = (props) => {
    const getCardElement = () => {
        const direction = props.direction || 'vertical';
        const itemsCount = props.listItems?.length || 1;

        return (
            <Styled.Card key={`card-${props.id}`} $direction={direction} $itemCount={itemsCount} $withMargin={props.withMargin} >
                <Styled.CardHeader> {props.title} </Styled.CardHeader>
                {
                    props.listItems?.map((item) => (
                        <Styled.Item key={`item-${props.id}-${item.title}`} direction={direction}>
                            <div>{item.title}</div>
                            <div>{item.value}</div>
                        </Styled.Item>
                    ))
                }
                <Styled.CardFooter>
                    <Styled.ArrowIcon />
                </Styled.CardFooter >
            </Styled.Card >
        );
    };

    if (props.url) {
        return (
            <Link id={`btn-${props.id}`} key={`link-${props.id}`} aria-label={`Open ${props.title}`} to={props.url} style={{ color: 'inherit', textDecoration: 'none', padding: 0, margin: 0 }}>
                {getCardElement()}
            </Link >
        );
    }

    return getCardElement();
}
