import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // phone
      sm: 768, // tablet
      md: 1024, // laptop
      lg: 1366, // desktop
      xl: 1920, // widescreen
      xxl: 2560, // ultra wides
    },
  },
  typography: {
    fontFamily: 'Nunito,Roboto,RobotoFallback,"Noto Kufi Arabic",Helvetica,Arial,sans-serif',
    fontWeightRegular: 500
},
});

export default theme;
