import * as Domain from '@liasincontrol/domain';
import type { PublisherState } from './PublisherState';
import type { PerformanceState } from './PerformanceState';
import type { StudioState } from './StudioState';
import type { FinanceState } from './FinanceState';

export enum AjaxRequestStatus {
    NotSet = 'notset',
    Pending = 'pending',
    Done = 'done',
    Failed = 'failed',
}

export type LiasAttachmentState = {
    [attachmentId: string]: {
        attachment: File,
        status: AjaxRequestStatus,
    }
}

export type ElementDefinitionState = {
    [service: string]: {
        items: { [id: string]: Domain.Shared.ElementDefinition, }
        status: AjaxRequestStatus,
        includeFieldDefinitions: boolean,
        includeDetailCards: boolean,
        includeDeleted: boolean,
    };
};

/**
 * Represents the application state.
 */
export type State = {
    /**
     * Defines the tenant name for which the Redux state can be applied.
     */
    tenant: string,

    /**
     * Defines the list of users.
     */
    users: {
        items: Domain.Shared.User[],
        status: AjaxRequestStatus,
    };

    /**
     * Defines the list of workflow templates.
     */
    workflowtemplates: {
        items: Domain.Shared.WorkflowTemplateWithStates[],
        status: AjaxRequestStatus,
    };

    /**
     * Defines the list of measure moments.
     */
    measuremoments: {
        items: Domain.Shared.MeasureMoment[],
        status: AjaxRequestStatus,
    };

    /**
     * Defines a dictionary of element definitions.
     * The key is represented by the service unique identifier (NOT systemId).
     */
    elementdefinitions: ElementDefinitionState;

    /**
     * Defines a dictionary of modules.
     * The key is represented by the service unique identifier.
     */
    modules: {
        [service: string]: {
            items: { [moduleSystemId: string]: Domain.Shared.Module },
            status: AjaxRequestStatus,
        };
    };

    /**
     * Defines a dictionary of attachments.
     */
    liasAttachments: LiasAttachmentState;

    /**
     * Defines the Publisher-specific redux state.
     */
    publisher: PublisherState;

    /**
     * Defines the Performance-specific redux state.
     */
    performance: PerformanceState;

    /**
    * Defines the Studio-specific redux state.
    */
    studio: StudioState;

    /**
    * Defines the Finance-specific redux state.
    */
    finance: FinanceState;

    /**
     * Defines a dictionary of hierarchy definitions.
     * The key is represented by the service unique identifier (NOT systemId).
     */
    hierarchydefinitions: {
        [service: string]: {
            status: AjaxRequestStatus,
            items: { [id: string]: Domain.Shared.HierarchyDefinition },
        };
    };

    /**
     * Defines the list of userGroups.
     */
    usergroups: {
        items: Domain.Shared.UserGroup[],
        status: AjaxRequestStatus,
    };

    /**
     * Defines the list of available action for ChatGPT.
     */
    textAssistantSkills: {
        items: Domain.Shared.TextAssistantSkill[],
        status: AjaxRequestStatus,
    };

    /**
     * Defines a dictionary of SVG icons.
     */
    icons: {
        items: { [id: string]: Domain.Shared.SvgIcon },
        status: AjaxRequestStatus
    }

    /**
     * Defines the announcement.
     */
    announcements: {
        items: Domain.Shared.Announcement,
        status: AjaxRequestStatus
    }

};

export default State;

export {
    PublisherState, PerformanceState, StudioState,
};
