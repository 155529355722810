import React from 'react';
import { ListField } from '@liasincontrol/ui-devextreme';

import { ListProps } from './index.props';
import { getValidationErrorsNode } from '../../shared/validationHelper';

export const ListEditor: React.FC<ListProps> = (props) => {

    return (
        <ListField
            id={props.id}
            key={props.id}
            label={props.label}
            helpText={props.helpText}
            mandatory={props.editorSettings?.restrictions?.required}
            error={getValidationErrorsNode(props.editorSettings?.validationErrors)}
            value={props.value}
            displayExpr={props.displayExpr}
            items={props.items}
            itemKey={props.itemKey}
            placeholder={props.placeholder ?? "Kies..."}
            dataSource={props.dataSource}
            isDisabled={props.editorSettings?.disabled}
            noDataText={props.noDataText ?? "Geen gegevens beschikbaar"}
            selectedLabel={props.selectedLabel ?? "Geselecteerd"}
            enableSearch={props.enableSearch}
            customHeaderComponent={props.customHeaderComponent}
            itemTemplate={props.itemTemplate}
            searchExpr={props.searchExpr}
            onChange={(val) => {
                props.editorSettings?.onChange?.(val);
            }}
        />
    );
}
