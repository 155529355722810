import React from 'react';
import Styled from './index.styled';
import { Popover } from 'devextreme-react/popover';

export interface IEdgeTabProps {
    readonly children?: React.ReactNode;
    readonly title: React.ReactNode;
    readonly icon: React.ReactNode;
    readonly active: boolean;
    readonly tabCount: number;
    readonly disabled?: boolean;
    readonly id: string;
    readonly onClick: () => void;
}

export const EdgeTab = ({ id, children, icon, title, active, tabCount, disabled, onClick: toggleTab }: IEdgeTabProps) => {
    return (
        <>
            {active && <Styled.TabContent $tabCount={tabCount}><>{children}</></Styled.TabContent>}
            <Styled.Tab $isActive={active} onClick={disabled ? undefined : toggleTab} data-title={title} $isDisabled={disabled}>
                <div id={`tab-item-${id}`} className='tab-icon-container'>
                    {icon}
                </div>
                {title && <Popover
                    target={`#tab-item-${id}`}
                    height={50}
                    showEvent="mouseenter"
                    hideEvent="mouseleave"
                    position={{
                        my: 'left',
                        at: 'right',
                        offset: { x: -10, y: 0 },
                        of: `#tab-item-${id}`
                    }}
                    hideOnOutsideClick={true}
                    hideOnParentScroll={true}
                    wrapperAttr={{ class: 'lias-popover arrow-invisible' }}
                    animation={null}
                >
                    <Styled.TabLabel>{title}</Styled.TabLabel>
                </Popover>}
            </Styled.Tab>
        </>
    );
};

export { EdgeTabs } from './index.styled';
