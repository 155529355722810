import React from 'react';
import { Feedback, Label } from '@liasincontrol/ui-basics';
import { HelpText } from '@liasincontrol/ui-devextreme';
import { RichTextProps } from './index.props';
import Styled from './index.styled';

/**
 * Represents a UI component that renders a richtext viewer.
 */
export const RichTextViewer: React.FC<RichTextProps> = (props) => (
    <>
        {props.label && <Label id={`${props.id}-label`} htmlFor={props.id} text={props.label} />}
        {props.helpText && props.helpText.text && (
            <HelpText id={`${props.id}-hint`} title={props.helpText.title}>{props.helpText.text}</HelpText>
        )}
        <Styled.HtmlViewer {...props} />
        <Feedback id={`${props.id}-feedback`} />
    </>
);