import { px, palette } from '@liasincontrol/ui-basics';
import styled from 'styled-components';

const StaticFieldValue = styled.div<{ $withPadding?: boolean }>`
    min-height: ${px(48)};
    padding-top: ${({ $withPadding }) => $withPadding ? px(16) : px(12)};
    padding-bottom: ${({ $withPadding }) => $withPadding ? px(16) : px(12)};
    border-bottom: ${px(2)} solid #f4f4f8;
    white-space: pre-wrap;
    word-break: break-word;
    min-width: 90%;

    span {
        display: inline-block ;
        color: ${palette.primary2};
    }
`;

export { StaticFieldValue };