class BudgetJournalBase {

    id: string;

    code: string;

    name: string;

    measureMomentId: string;

    authorId: string;

    createdOn: Date;

    budgetJournalKindId?: string;

    budgetJournalKindName?: string;

    baseYear?: number;

    workflowStep?: string;

    editStage: EditStage;

    budgetJournalGroupId?: string;

    lastExportedOn?: Date;

    lastExportedBy?: string;

    userHasActiveTask: boolean;
}

/**
 * Represents a budgetJournal item for list view.
 */
class BudgetJournal extends BudgetJournalBase {

    authorName?: string;

    measureMomentName?: string;

    budgetJournalGroupName?: string;
}

enum EditStage {
    Draft,
    Workflow,
    Final,
}

/**
 * Represents a budgetJournal extended item for detail view.
 */
class BudgetJournalDetail extends BudgetJournalBase {

    description?: string;

    officialText?: string;

    administrativeText?: string;

}

enum MoveBudgetJournalDirection {
    Forward,
    Backward,
}

type MoveBudgetJournalForwardOrBackward = {
    direction: MoveBudgetJournalDirection
}

export { BudgetJournal, BudgetJournalDetail, EditStage, MoveBudgetJournalForwardOrBackward, MoveBudgetJournalDirection };