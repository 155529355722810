
import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

/**
 * Class that allows interaction with the user-specific backend APIs.
 */
export class Users {
    /**
     * Gets a list with all the users that are enabled to login in the application.
     * @param query Defines the query that can be used for filtering the data on the server side.
     */
    public static get = async (): Promise<AxiosResponse<Domain.Shared.User[]>> => {

        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Shared.User[]>(`/api/user`, config);
    }

    /**
    *  Updates group membership of the user.
    */
    public static addUserToGroups = async (userId: string, groupIds: string[]): Promise<AxiosResponse<void>> => {

        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/user/${userId}/groups`, { "groupIds": groupIds }, config);
    }

    /**
    * Export users & roles.
    */
    public static exportData = async (): Promise<{ blob: Blob, fileName: string }>  => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        const response = await HttpClient.get(`/api/user/export`, config);

        // Get the content-disposition header to extract the filename
        const contentDisposition = response.headers['content-disposition'];

        let fileName = 'gebruikers-export.csv'; // Default filename
        if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (filenameMatch && filenameMatch[1]) {
                fileName = filenameMatch[1].replace(/['"]/g, '');
            }
        }

        // Create a blob from the response
        const fileType = response.headers['content-type'] || 'text/csv';
        const fileBlob = new Blob([response.data], { type: fileType });

        return { blob: fileBlob, fileName };
    }
}

export default Users;