import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import styled from 'styled-components';

/**
 * TODO: Find a way to use px from UI.Basics without circular dependencies.
 */
const px = (pxSize: number) => `${pxSize / 16}rem`;

interface IIconProps {
    readonly $disabled?: boolean;
    readonly $expanded?: boolean;
    readonly $height?: number;
}
const MainLineWrap = styled.div`
    height: ${px(40)};
`;
const ExtraLineWrap = styled.div`
    padding: ${px(6)};
`;
const ActionsWrap = styled.div`
    float: right;    
    > button { margin-left: ${px(4)}; }
`;
const DragWrap = styled.span`
    float: left; 
    padding: ${px(8)} 0 0 0;
`;
const TxtLabel = styled.span`
    float: left; 
    padding: ${px(10)} 0 0 0;
    max-width: ${px(180)};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
`;
const Li = styled.li<IIconProps>`
    position: relative;
    padding: ${px(6)} ${px(8)};
    margin-bottom: ${px(2)};
    text-align: left;
    white-space: nowrap; 
    text-overflow: ellipsis;
    list-style-type: none;
    transition: background-color 0.4s, padding-right 0.4s ease;
    border-radius: ${px(8)};
    color: ${(p: IIconProps) => p.$disabled ? '#8A8991' : '#414141'};
    background-color: ${(p: IIconProps) => p.$expanded ? '#f1f2fd' : '#fff'};    
    cursor: ${(p: IIconProps) => (p.$disabled ? 'default' : 'pointer')};
    ${({ $height: height }: IIconProps) => height && `
        height: ${px(height)};
        line-height: ${px(height)};
    `}

    &:hover {
        color: ${(p: IIconProps) => p.$disabled ? '#8A8991' : '#414141'};
        background-color: '#f8f9fe';
    };
`;

const StyledIconDrag = styled(DragIndicatorIcon) <IIconProps>`
    color: ${(p: IIconProps) => p.$disabled ? '#6E6E77' : '#4b55f6'};
    fill: currentColor;
    cursor: ${(p: IIconProps) => p.$disabled ? 'default' : 'move'};
`;
const PanelSection = styled.div`
    padding-bottom: ${px(16)};
    & > p {
        min-height: 0;
    }
`;
const InputContainer = styled.div`
    position: relative;
`;
const InputContainer2 = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    > input.one-third { width: 26%; height: min-content; padding-top: 13px; padding-bottom: 13px }
    > div { width: 72%; }
`;
const StyledInput = styled.input`
    width:100%;
    max-width:100%;
    padding:${px(12)} ${px(24)};
    margin:0;
    color:#414141;
    text-overflow:ellipsis;
    border-radius:4px;
    background:0 none;
    outline:0;
    transition:border-color 0.3s ease;
    background: #fff;
    white-space: nowrap;
    border:2px solid #f4f4f8;
    &:hover { border:2px solid rgba(75, 85, 246, 0.15); }
    &:focus { border:2px solid rgba(75, 85, 246, 1); } /* dit is #4b55f6 */
`;

export { MainLineWrap, ExtraLineWrap, ActionsWrap, DragWrap, TxtLabel, Li, StyledIconDrag, PanelSection, InputContainer, InputContainer2, StyledInput };