import { formatDate as devextremeFormatDate } from 'devextreme/localization';
import { isValid, parse } from 'date-fns';

export const INVARIANT_CULTURE_FORMAT = "MM/dd/yyyy";
export const DEFAULT_DATE_FORMAT = "dd-MM-yyyy";
export const DEFAULT_DATE_TIME_FORMAT = "dd-MM-yyyy 'om' HH:mm:ss";

/**
 * Helper class that offers date manipulation methods.
 */
export class DateUtils {

    /**
     * Makes a best-effort conversion from any value (assuming string for now) to a Date.
     * @param value Value representing a date as a string, Date or number object.
     * @param strictConversion If true and the supplied value cannot be converted to Date, return null. If false, return the raw input value.
     */
    public static tryConvertDate = (value: any, strictConversion = false): Date | null => {
        if (value instanceof Date) {
            return value;
        }
        
        if (!value) {
            return null;
        }

        try {
            return new Date(value);
        } catch (_) {
            return strictConversion ? null : value;
        }
    }

    /**
     * Makes a best-effort conversion from a string value returned by the Backend, which should normally be formated with the InvariantCulture format settings.
     * @param value Value representing a date as a string or Date object.
     * @param strictConversion If true and the supplied value cannot be converted to Date, return null. If false, return the raw input value.
     */
    public static fromInvariantString = (value: any, strictConversion = false): Date | null => {
        if (value instanceof Date) {
            return value;
        }
        if (!value) {
            return null;
        }

        try {
            // Dates received from the backend are serialized as InvariantCulture strings. See serializeForBackend method below.
            const parsedDate = parse(value,  "MM/dd/yyyy HH:mm:ss xxx", new Date());

            if (isValid(parsedDate)) return parsedDate;

            // In case we can't properly parse an InvariantCulture, let the Date object try its best to parse a valid Date.
            if (!strictConversion)  return new Date(value);
        } catch (_) {     
            return null;
        }
    }

    /**
     * Format a Date to its InvariantCulture format, as needed when passing data to the backend.
     * @param value Date time value to format.
     */
    public static toInvariantString = (value: Date | null | undefined): string => {
        // Dates must be passed as the equivalent of .Net CultureInfo.InvariantCulture, which is MM/dd/yyyy. 
        // See https://docs.microsoft.com/en-us/dotnet/standard/base-types/standard-date-and-time-format-strings.
        return this.formatDateTime(value, INVARIANT_CULTURE_FORMAT);  
    }

    /**
     * Format a Date & time to the standard used in the entire application (dd-MM-yyyy [om] HH:mm:ss) or custom if its asked.
     * @param value Date time value to format.
     * @param format Pattern string to format.
     */
    public static formatDateTime = (value: Date | null | undefined, format = DEFAULT_DATE_TIME_FORMAT): string | null => {
        const d = this.tryConvertDate(value);
        return d ? devextremeFormatDate(d, format) : null;
    }

    /**
     * Format a Date-only value to the standard used in the entire application (dd-MM-yyyy).
     * @param value Date time value to format.
     */
    public static formatDate = (value: Date | null | undefined): string | null => {
        const d = this.tryConvertDate(value);
        return value ? devextremeFormatDate(d, DEFAULT_DATE_FORMAT) : null;  
    }
}
