import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '@liasincontrol/auth-service';
import { useTenant } from '@liasincontrol/redux-service';

const Index: React.FC = () => {
    const [redirectUri, setRedirectUri] = useState<string>();
    const { initTenant } = useTenant();

    AuthService.getInstance()
        .then((auth) => auth.signinRedirectCallback())
        .then((user) => {
            const tenant_name: string = user?.profile?.tenant_name;
            initTenant(tenant_name);
            const redirectUri: string = user.state['redirectUri'];
            setRedirectUri(redirectUri);
        })
        .catch((error) => {
            // Do nothing
        });

    if (redirectUri && redirectUri.length !== 0) {
        return (
            <Navigate to={redirectUri} />
        );
    }

    return <></>;
};

export default Index;
