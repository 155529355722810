import { NormalText } from './../primitives/typography/index';
import styled from 'styled-components';
import { SmallText } from '../primitives/typography';
import { px } from '../style';
import { palette } from './../styleguide';

const StyledItem = styled.span<{ $iconOnly: boolean; $active: boolean }>`
    display: inline-grid;
    align-items: center;
    grid-gap: 0 ${px(12)};
    grid: ${(p) => (p.$iconOnly ? `'icon' auto / auto` : `'icon title' auto 'icon subtitle' auto / min-content auto`)};
    opacity: ${(p) => (p.$active ? 1 : 0.8)};
`;

const StyledIconContainer = styled.span`
    grid-area: icon;
    grid-row: 1 / span 2;
    align-self: center;
    justify-self: center;
`;

const StyledTitleLarge = styled(NormalText)`
    grid-area: title;
    grid-column: 2 / span 1;
    grid-row: 1 / span 2;
    text-transform: none;
    font-weight: 700;
    font-size: ${px(16)};
    color: ${palette.grey2};
`;

const StyledTitleSmall = styled(SmallText)`
    font-size: ${px(12)};
    font-weight: 400;
    color: ${palette.grey2};
    text-transform: none;
`;

const StyledSubtitle = styled(SmallText)`
    font-weight: 400;
    color: #999999;
    text-transform: none;
`;

const styledComponents = { StyledItem, StyledIconContainer, StyledTitleSmall, StyledTitleLarge, StyledSubtitle };
export default styledComponents;