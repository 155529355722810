import React, { useEffect, useState } from 'react';
import Styled from './index.styled';

interface IProps {
    readonly accountName: string;
    readonly badge?: boolean;
    readonly look?: 'default' | 'inverted';
    readonly children?: React.ReactNode;
}

/**
 * Expects multiple `<li><button /></li>`
 */
export const AccountButton = ({ accountName, badge, look, children }: IProps) => {
    const [visible, setVisible] = useState(false);

    const handleOnclick = (event) => {
        event.currentTarget.blur();
        event.stopPropagation();
        setVisible(true);
    };
    const hide = () => setVisible(false);

    useEffect(() => {
        if (visible && typeof window !== `undefined`) {
            window.addEventListener(`click`, hide);

            return () => window.removeEventListener(`click`, hide);
        }
    }, [visible]);

    return (
        <Styled.Wrap>
            <Styled.Button $look={look} aria-label="Uw account" onClick={handleOnclick}>
                {accountName}
                {children && <Styled.Badge $badge={badge}></Styled.Badge>}
            </Styled.Button>
            {visible &&
                <Styled.Dropdown>
                    <ul>{children}</ul>
                </Styled.Dropdown>
            }
        </Styled.Wrap>
    );
};
