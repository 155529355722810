import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import React from 'react';
import Ink from 'react-ink';
import { BasicText } from '../primitives/typography';
import styled from 'styled-components';
import { NumberBox } from 'devextreme-react/number-box';
import { px } from '../style';
import { palette } from '../styleguide';

/**
 * Represents a low-level UI component that renders an numeric/integer editor.
 */
export const InputInteger = React.memo(({
  disabled,
  error,
  focused,
  idInput,
  minimumValue,
  maximumValue,
  startValue,
  placeholder,
  step,
  onBlur,
  onChange,
  onFocus,
  success,
  value,
  controlButtons = true,
}: IInputIntegerProps) => {

  if (!step || step < 0) {
    step = 1;
  }
  if (!minimumValue || minimumValue < 0) {
    minimumValue = 0;
  }

  const isValidValue = !Number.isNaN(value);
  const enableDecrease = isValidValue && (!minimumValue || (minimumValue && minimumValue <= value - step));
  const enableIncrease = isValidValue && (!maximumValue || (maximumValue && maximumValue >= value + step));

  const decreaseValue = value - step;
  const increaseValue = value + step;

  return controlButtons ? (
    <Container>
      <Button id={`btn-decrease-${idInput}`} onClick={() => onChange(decreaseValue)} disabled={disabled || !enableDecrease}>
        <ButtonInner tabIndex={-1}>
          <DecreaseIcon />
          {!disabled && isValidValue && <Ink duration={600} radius={230} />}
        </ButtonInner>
      </Button>
      <Center>
        <StyledInput
          id={`input-${idInput}`}
          defaultValue={startValue}
          min={minimumValue}
          max={maximumValue}
          showSpinButtons={false}
          step={step}
          disabled={disabled}
          error={error}
          focused={focused}
          onFocusOut={onBlur}
          valueChangeEvent="keyup"
          onValueChange={(val) => onChange(val)}
          onFocusIn={onFocus}
          success={success}
          value={value}
          placeholder={placeholder}
        />
      </Center>
      <Button id={`btn-increase-${idInput}`} onClick={() => onChange(increaseValue)} disabled={disabled || !enableIncrease}>
        <ButtonInner tabIndex={-1}>
          <IncreaseIcon />
          {!disabled && isValidValue && <Ink duration={600} radius={230} />}
        </ButtonInner>
      </Button>
    </Container>
  ) : (
    <StyledInput
      id={`input-${idInput}`}
      defaultValue={startValue}
      min={minimumValue}
      max={maximumValue}
      showSpinButtons={false}
      step={step}
      disabled={disabled}
      error={error}
      focused={focused}
      onFocusOut={onBlur}
      onValueChange={(val) => onChange(val)}
      valueChangeEvent="keyup"
      onFocusIn={onFocus}
      success={success}
      value={value}
      placeholder={placeholder}
    />
  );
});

export interface IInputIntegerProps {
  readonly idInput?: string;
  readonly success?: boolean;
  readonly error?: boolean;
  readonly disabled?: boolean;
  readonly focused?: boolean;
  readonly value: number;
  readonly placeholder?: string;
  readonly minimumValue?: number;
  readonly maximumValue?: number;
  readonly startValue?: number;
  readonly step?: number;
  readonly controlButtons?: boolean;
  readonly onFocus?: () => void;
  readonly onBlur?: () => void;
  readonly onChange?: (value: number) => void;
}


/* tslint:disable:no-magic-numbers */
const StyledInput = styled(BasicText).attrs({ as: NumberBox }) <IInputIntegerProps>`
    width:100%;
    max-width:100%;
    margin:0;
    color:${palette.grey2};
    text-overflow:ellipsis;
    border-radius:4px;
    background:0 none;
    outline:0;
    transition:border-color 0.3s ease;
    background: #fff;
    white-space: nowrap;
    ::-ms-clear {
        display: none;
    }

    // Override the DevExtreme default properties.
    &.dx-editor-outlined {
      background: #fff;
      border: 2px solid #f4f4f8 !important;
      border-radius: 4px;
    }

    &.dx-state-hover {
      border: 2px solid  rgba(75, 85, 246, 0.15) !important;
    }

    &.dx-state-focused {
      border: 2px solid rgba(75, 85, 246, 1) !important;
    }

    .dx-texteditor-input {
      padding: ${px(12)} ${px(16)};
      min-height: ${px(20)}; 
    }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: ${px(48)} 1fr ${px(48)};
  align-items: center;
  justify-content: center;
`;
const Center = styled.div`
  display: block;
  margin: 0 ${px(16)};
  width: ${`calc(100% - ${px(32)})`};
`;
const Button = styled.button`
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border: 0 transparent;
  padding: 0;
  outline: 0;
  :focus {
    outline: none;
  }
`;
const ButtonInner = styled.span`
  display: table-cell;
  vertical-align: middle;
  width: ${px(48)};
  height: ${px(48)};
  line-height: 0;
  border-radius: ${px(6)};
  background-color: ${palette.primary4};
  border: ${px(2)} solid ${palette.primary4};
  padding: 0;
  outline: 0;
  cursor: pointer;
  color: ${palette.primary1b};
  transition: background-color 0.3s ease, border-color 0.3s ease,
    color 0.3s ease;
  :hover {
    background-color: ${palette.primary3};
    border-color: ${palette.primary3};
    color: #2934cc;
  }
  :focus {
    outline: none;
  }
  :hover:focus,
  :active {
    background-color: ${palette.primary3};
    border-color: ${palette.primary3};
    color: #2934cc;
  }
  ${Button}:disabled &,
  ${Button}:focus & :disabled {
    background-color: ${palette.grey4};
    border-color: ${palette.grey4};
    color: ${palette.grey3};
    cursor: default;
  }
  /* keyboard-only focus style */
  ${Button}:focus & {
    background-color: #fff;
    border-color: #2934cc;
    color: #2934cc;
  }
`;
const DecreaseIcon = styled(Remove as any /* children: never bug */)`
  fill: currentColor;
`;
const IncreaseIcon = styled(Add as any /* children: never bug */)`
  fill: currentColor;
`;