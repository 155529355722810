import React, { useEffect, useState } from 'react';
import { isUndefined } from 'lodash';
import * as Domain from '@liasincontrol/domain';
import { DxTemplate, DxTreeList, DxColumn, DxSearchPanel, LiasContextMenu, StyledDraggableWrapper } from '@liasincontrol/ui-devextreme';
import { FieldsHelper, IconHelper } from '@liasincontrol/core-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { IconSize, SVGIcon } from '@liasincontrol/ui-basics';
import { IconHelper as PerformanceIconHelper } from '../../../helpers/IconHelper';
import Styled from '../index.styled';
import './index.style.less';
import { HierarchyItemDeleteDialog } from './HierarchyItemDeleteDialog';
import { HierarchyItemReparentDialog } from './HierarchyItemReparentDialog';
import { ActionSource, AjaxRequestStatus, useElementDefinitions, useIcons } from '@liasincontrol/redux-service';

type Props = {
    userIdentity: UserIdentity,
    performanceModuleId?: string,

    hierarchyItems: Domain.Performance.HierarchyItem[],
    isMeasureMomentClosed: boolean,
    selectedType: Domain.Performance.HierarchyItemElementType,
    workflowDictionary: Record<Domain.Performance.HierarchyItemElementType, Domain.Shared.WorkflowTemplateWithStates>,
    availableUsers: Domain.Shared.User[],
    allowReordering?: boolean,

    onDeleteEntity: (entityId: string) => void,
    onEditEntity: (entityId: string) => void,
    onCreateEntity: (type: Domain.Performance.HierarchyItemElementType, parentEntityId: string) => void,
    onReparentEntity: (entityId: string, parentEntityId: string) => void,
    onReorderEntities: (moveAfterItemId: string, movedItemId: string) => void,
};

type HierarchyItemElement = Domain.Performance.HierarchyItemElement & { progress: Domain.Shared.FieldDefinitionOptionItem, workflowState: Domain.Shared.WorkflowTemplateState, assignedUser: Domain.Shared.User, order: number };
type CellProps = {
    data: HierarchyItemElement
};

const availableTypes = [
    Domain.SystemElementDefinitions.Performance.Policy,
    Domain.SystemElementDefinitions.Performance.Goal,
    Domain.SystemElementDefinitions.Performance.Achievement,
    Domain.SystemElementDefinitions.Performance.Activity
];

const availableStatuses: string[] = [
    Domain.SystemFieldDefinitions.Performance.GoalStatus,
    Domain.SystemFieldDefinitions.Performance.AchievementStatus,
    Domain.SystemFieldDefinitions.Performance.ActivityStatus
];

/**
 * Represents a UI component that renders a grid of performance hierarchy items.
 */
export const HierarchyGrid: React.FC<Props> = (props) => {
    const [gridData, setGridData] = useState<HierarchyItemElement[]>([]);
    const icons = useIcons();
    const { elementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: props.performanceModuleId });
    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<{ isVisible: boolean, isBusy: boolean, data?: HierarchyItemElement }>({ isVisible: false, isBusy: false });
    const [isReparentDialogVisible, setIsReparentDialogVisible] = useState<{ isVisible: boolean, isBusy: boolean, data?: HierarchyItemElement }>({ isVisible: false, isBusy: false, });

    useEffect(() => {
        const filteredHierarchyItems = props.hierarchyItems
            ?.filter(item => (
                availableTypes.indexOf(item.element.elementDefinitionSystemId as Domain.Performance.HierarchyItemElementType) === availableTypes.indexOf(props.selectedType) ||
                (item.parentHierarchyItemId && availableTypes.indexOf(item.element.elementDefinitionSystemId as Domain.Performance.HierarchyItemElementType) > availableTypes.indexOf(props.selectedType)))
            );
        const data = mapHierarchyItemsToGridData(filteredHierarchyItems, elementDefinitions.items, props.selectedType, props.workflowDictionary, props.availableUsers);
        setGridData(data);
        setIsDeleteDialogVisible(resetActionState);
        setIsReparentDialogVisible(resetActionState);
    }, [props.hierarchyItems, props.selectedType, elementDefinitions, props.workflowDictionary, props.availableUsers]);

    // #region handlers...
    const onCreateEntity = (parentType: Domain.Performance.HierarchyItemElementType, parentEntityId: string) => {
        const type = availableTypes[availableTypes.indexOf(parentType) + 1];
        props.onCreateEntity(type, parentEntityId);
    };

    /**
     * Gets the available parents that can be assigned to a performance hierarchy item.
     * 
     * @param entityType Defines the type of the hierarchy item that has to be assigned to another parent.
     * @param currentParentId Defines the unique identifier of the current parent of the hierarchy item.
     */
    const getAvailableParents = (entityType: Domain.Performance.HierarchyItemElementType, currentParentId: string): Domain.Performance.HierarchyItemElement[] => {
        const type = availableTypes[Object.values(availableTypes).indexOf(entityType) - 1];
        const availableParents = mapHierarchyItemsToGridData(props.hierarchyItems, elementDefinitions.items, props.selectedType, props.workflowDictionary, props.availableUsers).filter(item => item.type === type && item.id !== currentParentId);
        return availableParents;
    };
    // #endregion

    // #region Cell templates...

    if (!elementDefinitions || elementDefinitions.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    const TitleCellTemplate: React.FC<CellProps> = (cellprops) => {
        const ed = getElementDefinition(Object.values(elementDefinitions.items), cellprops.data.type);
        return <div className="overflow-cell">
            <div className="overflow-cell-icon">
                <SVGIcon value={icons.items[ed.icon]?.svg} size={IconSize.medium} color={ed.color} />
            </div>

            <Styled.SingleValueWrapper >
                <label className="overflow-cell-label cursor-pointer" onClick={() => props.onEditEntity(cellprops.data.id)} title={cellprops.data.name}>{cellprops.data.name}</label>
            </Styled.SingleValueWrapper>
        </div>;
    };

    const NumberCellTemplate: React.FC<CellProps> = (props) => (
        <div className="overflow-cell">
            <Styled.SingleValueWrapper>
                <span className="overflow-cell-label">{props.data.number}</span>
            </Styled.SingleValueWrapper>
        </div>
    );

    const ContextualMenuCellTemplate: React.FC<CellProps & { userIdentity: UserIdentity }> = (contextProps) => {
        if (props.isMeasureMomentClosed) {
            return null;
        }

        return (
            <LiasContextMenu<HierarchyItemElement>
                item={contextProps.data}
                keyExpr='id'
                actions={[
                    {
                        text: 'Nieuw',
                        callBack: () => onCreateEntity(contextProps.data.type, contextProps.data.id),
                        visible: contextProps.data.type !== Domain.SystemElementDefinitions.Performance.Activity,
                    },
                    {
                        callBack: () => props.onEditEntity(contextProps.data.id),
                        text: 'Bewerken'
                    },
                    {
                        callBack: () => setIsDeleteDialogVisible((prev) => ({ ...prev, isVisible: true, isBusy: false, data: contextProps.data })),
                        text: 'Verwijderen',
                    },
                    {
                        callBack: () => setIsReparentDialogVisible((prev) => ({ ...prev, isVisible: true, isBusy: false, data: contextProps.data })),
                        text: 'Verplaatsen',
                        visible: contextProps.data.type !== Domain.SystemElementDefinitions.Performance.Policy
                    },
                ]}
            />);
    };

    const ProgressValueCellTemplate: React.FC<CellProps> = (cellprops) => {
        if (!cellprops.data.progress) {
            return null;
        }

        return (
            <div className="overflow-cell">
                <div className="overflow-cell-icon">
                    {PerformanceIconHelper.getPerformanceProgressIcon(cellprops.data.progress.name, IconSize.small)}
                </div>

                <Styled.SingleValueWrapper>
                    <span className="overflow-cell-label">{cellprops.data.progress.name}</span>
                </Styled.SingleValueWrapper>
            </div>
        );
    };

    const WorkflowStateCellTemplate: React.FC<CellProps> = (props) => {
        if (!props.data.workflowState) {
            return null;
        }

        return (
            <div className="overflow-cell">
                <div className="overflow-cell-icon">
                    {IconHelper.getWorkFlowStatusIcon(props.data.workflowState.name, IconSize.small)}
                </div>

                <Styled.SingleValueWrapper>
                    <span className="overflow-cell-label">{props.data.workflowState.name}</span>
                </Styled.SingleValueWrapper>
            </div>
        );
    };

    const AssigendUserTemplate: React.FC<CellProps> = (props) => {
        const assignedUser = props.data.assignedUser ? props.data.assignedUser.name : '';

        return (
            <div className="overflow-cell">
                <Styled.SingleValueWrapper>
                    <span className="overflow-cell-label">{assignedUser}</span>
                </Styled.SingleValueWrapper>
            </div>
        );
    };
    // #endregion    

    const onReorderEntity = (treeListData) => {
        if (treeListData.fromIndex === treeListData.toIndex || props.isMeasureMomentClosed)
            return;

        const visibleTreeData: HierarchyItemElement[] = treeListData.component.getVisibleRows().map(row => gridData.find(item => row.node.data.id === item.id));
        const siblings = visibleTreeData.filter(item => item.parentId === treeListData.itemData.parentId && item.id !== treeListData.itemData.id);

        if (siblings.length === 0)
            return;

        const toIndex = treeListData.toIndex > treeListData.fromIndex ? treeListData.toIndex : treeListData.toIndex - 1;
        let previousItemId = null;

        if (toIndex >= 0) {
            if (visibleTreeData[toIndex].parentId !== treeListData.itemData.parentId) {
                if (visibleTreeData[toIndex].id !== treeListData.itemData.parentId) {
                    previousItemId = undefined;
                }
            }
            else {
                previousItemId = visibleTreeData[toIndex].id;
            }
        }

        const previousItemIndex = siblings.findIndex(item => item.id === previousItemId);
        if (previousItemId === null || (!isUndefined(previousItemId) && previousItemIndex !== -1 && (siblings[previousItemIndex].order || 0) + 1 !== treeListData.itemData.order)) {
            props.onReorderEntities(previousItemId, treeListData.itemData.id);
        }
    };

    return (<>
        <StyledDraggableWrapper $dragEnabled={true}>
            <DxTreeList
                id='performance-items-overview'
                dataSource={gridData}
                keyExpr='id'
                columnAutoWidth={false}
                wordWrapEnabled={true}
                noDataText='Geen gegevens beschikbaar'
                parentIdExpr='parentId'
                rowDragging={{
                    allowReordering: props.allowReordering && !props.isMeasureMomentClosed,
                    showDragIcons: true,
                    dragDirection: 'vertical',
                    dropFeedbackMode: 'push',
                    autoScroll: true,
                    onReorder: onReorderEntity
                }}
                scrolling={{ mode: 'standard' }}
            >
                <DxSearchPanel visible={true} width={250} searchVisibleColumnsOnly={true} placeholder='Zoeken...' />

                <DxColumn
                    caption='Titel'
                    dataField='name'
                    cellTemplate='TitleCellTemplate'
                    width='50%'
                    allowSorting={false}
                />

                <DxColumn
                    caption='Nummer'
                    dataField='number'
                    cellTemplate='NumberCellTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='7%'
                />

                <DxColumn
                    caption='Workflow status'
                    dataField='workflowState'
                    cellTemplate='WorkflowStateCellTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='13%'
                />

                <DxColumn
                    caption='Toegewezen aan'
                    dataField='assigendUser'
                    cellTemplate='AssigendUserTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='15%'
                />

                <DxColumn
                    caption='Voortgang'
                    dataField='progress'
                    cellTemplate='ProgressValueCellTemplate'
                    alignment='left'
                    allowSorting={false}
                    width='15%'
                />

                <DxColumn
                    cellTemplate='ContextualMenuCellTemplate'
                    allowSorting={false}
                    width='5%'
                />

                <DxTemplate name='TitleCellTemplate' render={TitleCellTemplate} />
                <DxTemplate name='NumberCellTemplate' render={NumberCellTemplate} />
                <DxTemplate name='ProgressValueCellTemplate' render={ProgressValueCellTemplate} />
                <DxTemplate name='WorkflowStateCellTemplate' render={WorkflowStateCellTemplate} />
                <DxTemplate name='AssigendUserTemplate' render={AssigendUserTemplate} />
                <DxTemplate name='ContextualMenuCellTemplate' render={(templateProps) => <ContextualMenuCellTemplate {...templateProps} userIdentity={props.userIdentity} users={props.availableUsers} />} />
            </DxTreeList>
        </StyledDraggableWrapper>
        {isDeleteDialogVisible.isVisible && (
            <HierarchyItemDeleteDialog
                entityId={isDeleteDialogVisible?.data?.id}
                entityName={isDeleteDialogVisible?.data?.name}
                disableSubmitButton={isDeleteDialogVisible?.isBusy}
                onDelete={() => {
                    setIsDeleteDialogVisible((prev) => ({ ...prev, isBusy: true }));
                    props.onDeleteEntity(isDeleteDialogVisible?.data?.id);
                }}
                onCancel={() => setIsDeleteDialogVisible(resetActionState)}
            />
        )}

        {isReparentDialogVisible.isVisible && (
            <HierarchyItemReparentDialog
                entityId={isReparentDialogVisible?.data?.id}
                disableSubmitButton={isReparentDialogVisible.isBusy}
                getAvailableParents={() => getAvailableParents(isReparentDialogVisible?.data?.type, isReparentDialogVisible?.data?.parentId)}
                onReparent={(parentEntityId) => {
                    setIsReparentDialogVisible((prev) => ({ ...prev, isBusy: true }));
                    props.onReparentEntity(isReparentDialogVisible?.data?.id, parentEntityId);
                }}
                onCancel={() => setIsReparentDialogVisible(resetActionState)}
            />
        )}
    </>
    );
};

/**
 * Maps performance hierarchy items to grid data collection.
 * 
 * @param hierarchyItems Defines the performance hierarchy items.
 * @param elementDefinitions Defines the element definitions.
 * @param selectedType Defines the currently selected type.
 * @param workflowDictionary Defines the workflowDictionary.
 * 
 * @returns the grid data collection.
 */
const mapHierarchyItemsToGridData = (
    hierarchyItems: Domain.Performance.HierarchyItem[],
    elementDefinitions: Record<string, Domain.Shared.ElementDefinition>,
    selectedType: Domain.Performance.HierarchyItemElementType,
    workflowDictionary: Record<Domain.Performance.HierarchyItemElementType, Domain.Shared.WorkflowTemplateWithStates>,
    users: Domain.Shared.User[]) => {

    const rootParentId = '0';
    return hierarchyItems?.reduce((acc, item: Domain.Performance.HierarchyItem) => {
        const elementItem = new Domain.Performance.HierarchyItemElement();
        if (!elementDefinitions[item.element.elementDefinitionId]) {
            return acc;
        }

        FieldsHelper.mapObject<Domain.Performance.HierarchyItemElement>(elementItem, elementDefinitions[item.element.elementDefinitionId].fields, item.element.fields);
        elementItem.id = item.element.elementId;
        if (availableTypes.indexOf(item.element.elementDefinitionSystemId as Domain.Performance.HierarchyItemElementType) === availableTypes.indexOf(selectedType)) {
            elementItem.parentId = rootParentId;
        } else if (item.parentHierarchyItemId) {
            elementItem.parentId = item.parentHierarchyItemId
        } else {
            elementItem.parentId = rootParentId;
        }

        elementItem.type = elementDefinitions[item.element.elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType;

        // Compute the progress value:
        const statusFieldDefinition = elementDefinitions[item.element.elementDefinitionId].fields.find(item => availableStatuses.indexOf(item.systemId) >= 0);
        const progress =
            statusFieldDefinition?.optionItems.find((option: Domain.Shared.FieldDefinitionOptionItem) => option.id === item.element.fields[statusFieldDefinition.id]);
        // Compute the workflow value:
        const workflowState: Domain.Shared.WorkflowTemplateState =
            workflowDictionary[item.element.elementDefinitionSystemId]?.workflowStates.find((state: Domain.Shared.WorkflowTemplateState) => state.id === item.workflowStateId);

        // Find assigned User:
        const assignedUser = users.find(user => user.id === item.assignedUserId);

        const row = { ...elementItem, order: item.order, progress: progress, workflowState: workflowState, assignedUser: assignedUser };
        return [...acc, row];
    }, []);
};

const getElementDefinition = (elementDefinitions: Domain.Shared.ElementDefinition[], id: string) => {
    return Object.values(elementDefinitions).find(item => item.systemId === id);
};

const resetActionState = { isVisible: false, isBusy: false, data: undefined };
