import React from "react";
import { LoadPanel as DxLoadPanel } from 'devextreme-react/load-panel';
import { PositionConfig } from "devextreme/animation/position";

export type LoadPanelProps = {
    visible: boolean;
    position?: PositionConfig;
    container?: string;
    shading?: boolean;
    shadingColor?: string;
    showPane?: boolean;
    text?: string;
}

export const LoadPanel: React.FC<LoadPanelProps> = ({
    shading = true,
    showPane = true,
    shadingColor = "rgba(0,0,0,0.4)",
    text = "Bezig met verwerken...",
    ...props
}) => {
    return (
        <DxLoadPanel
            position={props.position}
            container={props.container}
            visible={props.visible}
            shading={shading}
            shadingColor={shadingColor}
            message={text}
            showPane={showPane}
        />
    )
};
