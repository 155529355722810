import styled from 'styled-components';
import { px } from '@liasincontrol/ui-basics';

const Container = styled.div<{ style?: React.CSSProperties }>`
    position: relative;
    user-select: none;
`;

const ImageContainer = styled.div`
    touch-action: none;
    position: relative;
    cursor: grab;
    img {
        display: block;
    }
    :active {
        cursor: grabbing;
    }
`;

const CenterContainer = styled.div`
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.48), 0 4px 16px rgba(0, 0, 0, 0.12);
    display: grid;
    min-height: ${px(64)};
    align-items: center;
    justify-items: center;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAKElEQVQoU2O8ffv2fwY0oKKigi7EwDgUFP7//x/DM3fu3MH0zBBQCACtnjTH7bzZoAAAAABJRU5ErkJggg==')
        repeat;
`;

const Circle = styled.div.attrs((x: any) => ({ style: { left: `${x.point[0] * 100}%`, top: `${x.point[1] * 100}%` } }))<{ point: any }>`
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid rgba(255, 255, 255, 0.9);
    box-shadow: 0 1px 3px 2px rgba(0, 0, 0, 0.3), inset 0 0 2px 1px rgba(0, 0, 0, 0.5), inset 0 0 2px 3px rgba(255, 255, 255, 0.5);
    width: 16px;
    height: 16px;
    border-radius: 50%;
    transform: translate(-8px, -8px);
    position: absolute;
    pointer-events: none;
`;

const StyledImage = styled.img`
    object-fit: contain;
    height: auto;
    width: auto;
`;

const ZoomContainerInside = styled.div`
    position: absolute;
    bottom: ${px(4)};
    right: ${px(4)};
    display: none;
    @media (pointer: fine) {
        display: grid;
    }
`;

const ZoomButtonInside = styled.button`
    display: block;
    padding: ${px(2)};
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: rgba(255, 255, 255, 0.8);
    cursor: pointer;
    :first-child {
        border-radius: ${px(4)} ${px(4)} 0 0;
    }
    :last-child {
        border-top: none;
        border-radius: 0 0 ${px(4)} ${px(4)};
    }
    :hover {
        background: rgba(255, 255, 255, 1);
    }
`;

const ZoomContainerOutside = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto;
    padding: ${px(8)};
    align-items: center;
    justify-items: center;
    @media (pointer: fine) {
        display: none;
    }
`;


const styledComponents = { Container, ImageContainer, CenterContainer, Circle, StyledImage, ZoomContainerInside, ZoomButtonInside, ZoomContainerOutside };
export default styledComponents;
