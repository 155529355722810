import React from 'react';
import styled, { css } from 'styled-components';

import { cssBreakpoint } from '../../responsive/css';
import { IPanelGridProps } from './Props';

const cssGridGap = (pixels: number) => (css`
    grid-gap: ${pixels}px;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        margin: -${pixels}px;
        > *{
            margin: ${pixels / 2}px;
        }
        /* IE10+ CSS */
    }
`);
const Container = styled.div`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(auto-fill, minmax(200px, auto));
    grid-auto-rows: 200px 200px 70px 130px minmax(80px, auto) ;
    ${cssGridGap(24)};
    grid-auto-columns: minmax(100px,auto);

    ${cssBreakpoint('m')`
        grid-template-columns: repeat(3, minmax(200px, auto));
        grid-auto-rows: 160px;
        ${cssGridGap(16)};
    `};
    ${cssBreakpoint('xs', 's')`
        grid-auto-rows: 160px;
        ${cssGridGap(16)};
    `};

`;

export const PanelGrid = ({ children }: IPanelGridProps) => (
    <Container>
        <>{children}</>
    </Container>
);
