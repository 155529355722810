import styled, { css } from 'styled-components';

const LabelContainer = styled.div`
    display: flex;
    justify-content: space-between;

    > label {
        flex-grow: 1;
    }
`;

const HoverContainer = styled.div<{ $fullHeight?: boolean, $displayInline?: boolean }>`
    width: 100%;
    
    ${({ $fullHeight: fullHeight }) =>
        !fullHeight
            ? null
            : css`flex-grow: 1; display: flex; flex-direction: column;`}    

    ${({ $displayInline: displayInline }) =>
        !displayInline
            ? null
            : css`display: flex;gap: 1rem;`}    

    .maximize-button {
        visibility: hidden;
    }

    &:hover .maximize-button {
        visibility: visible;
    }
`;

const styledComponents = { LabelContainer, HoverContainer };

export default styledComponents;