import React from 'react';
import DataSource from 'devextreme/data/data_source';
import TileView from "devextreme-react/tile-view";

const minTileWidth = 32;
const minTileHeight = 32;

interface Props<T> {
    /**
     * A data source used to fetch data to be displayed by the widget.
     */
    readonly dataSource: DataSource | T[];

    /**
     * Specifies whether tiles are placed horizontally or vertically.
     */
    readonly direction?: 'horizontal' | 'vertical';
    /**
     * Specifies the widget's height.
     */
    readonly height?: number | string;

    /**
     * Specifies the widget's width.
     */
    readonly width?: number | string;

    /**
     * Specifies the distance in pixels between adjacent tiles.
     */
    readonly itemMargin?: number;

    /**
     * Specifies the height of the base tile view item
     */
    readonly baseItemHeight?: number;

    /**
     * Specifies the width of the base tile view item.
     */
    readonly baseItemWidth?: number;

    /**
     * A Boolean value specifying whether or not to display a scrollbar.
     */
    readonly showScrollbar?: 'always' | 'never' | 'onHover' | 'onScroll';


    /**
     * A function that is executed when a collection item is clicked or tapped.
     */
    readonly onItemClick?: (item: T) => void;

    /**
     * Specifies a custom rendering for items.
     */
    readonly itemRender?: (item: T) => React.ReactElement;

}

export const LsTileView = <T extends {}>(props: Props<T>) => {
    return (
        <TileView
            className='lias-tileview rounded-tiles'
            dataSource={props.dataSource}
            direction={props.direction || "vertical"}
            height={props.height || 300}
            width={props.width || '100%'}
            itemMargin={props.itemMargin || 10}
            baseItemHeight={props.baseItemHeight || minTileHeight}
            baseItemWidth={props.baseItemWidth || minTileWidth}
            showScrollbar={props.showScrollbar}
            itemRender={props.itemRender}
            onItemClick={(e) => props.onItemClick?.(e.itemData)}
            noDataText='Geen gegevens beschikbaar'
        />
    );
};
