import React, { JSX } from 'react';
import CircleIcon from '@mui/icons-material/Circle';

/**
 * Helper class that exposes common icon-related operations.
 */
export class IconHelper {
    /**
     * Represents a method to render the de default workflow's status icon.
     * 
     * @param status Defines the status.
     * @param size Defines the size of the icon.
     * 
     * @returns a JSX element with the icon. 
     */

    public static getWorkFlowStatusIcon = (status: string, size: number): JSX.Element => {
        switch (status) {
            case 'Nieuw':
                return <CircleIcon sx={{ color: "#BCBCC6", fontSize: size }} />;
            case 'Gereed':
                return <CircleIcon sx={{ color: "#68A350", fontSize: size }} />;
            default:
                return <CircleIcon sx={{ color: "#4299E1", fontSize: size }} />;
        }
    };

    /**
     * Represents a method to render the default performance's progress icon.
     * 
     * @param status Defines the progress status.
     * @param size Defines the size of the icon.
     * 
     * @returns a JSX element with the icon. 
     */

    public static getPerformanceProgressIcon = (status: string, size: number): JSX.Element => {
        switch (status) {
            case 'Anders':
                return <CircleIcon sx={{ color: "#BCBCC6", fontSize: size }} />;
            case 'Op koers':
                return <CircleIcon sx={{ color: "#68A350", fontSize: size }} />;
            case 'Bijsturing':
                return <CircleIcon sx={{ color: "#FFA500", fontSize: size }} />;
            case '(Deels) Niet haalbaar':
                return <CircleIcon sx={{ color: "#FF0000", fontSize: size }} />;
            default:
                return null;
        }
    };
}