import styled from 'styled-components';
import { cssFontNormal, px } from '../style';
import { palette } from '../styleguide';

export const EdgeTabs = styled.div<{ $tabCount: number }>`
    min-width: 100%;
    min-height: 100%;
    background-color: #eaeaf1;
    align-self: stretch;
    justify-self: stretch;
    display: grid;
    grid-template-columns: 50px 1fr;
    grid-template-rows: ${(p) => `repeat(${p.$tabCount}, 50px) 1fr`};
    grid-row-gap: 0;
    height: calc(100% - ${px(64)});
`;

const Tab = styled.button<{ $isActive: boolean, $isDisabled?: boolean }>`
    background-color: transparent;
    padding: 0;
    margin: 0;
    width: ${px(50)};
    height: ${px(50)};
    border: none;
    cursor: ${(p) => p.$isDisabled ? `default` : `pointer`};
    color: ${palette.grey2};
    grid-column: 1;
    transition: background-color .2s, color .2s, box-shadow .2s;
    position: relative;    
    background: ${(p) => p.$isActive ? `#FFF` : `#eaeaf1`};
    color: ${(p) => p.$isActive ? palette.primary2 : p.$isDisabled ? palette.grey3b : ``};
`;

const TabLabel = styled.div`
     ${cssFontNormal(16, 18, 30)};       
    color: ${palette.primary2};
    div.tab-icon-container {
        height: 100%;
        width: 100%;
        display: grid;
        justify-content: center;
        align-content: center;
    }
`;

const TabContent = styled.div<{ $tabCount: number }>`
    background-color: #FFF;
    grid-column: 2;
    grid-row: ${(p) => `1 / ${p.$tabCount + 1} span`};
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: ${px(15)};
    }
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }
    ::-webkit-scrollbar-thumb {
        background-color: #ececf3;
    }
    ::-webkit-scrollbar-thumb:hover {
        background-color: #e3e3ec;
    }
    ::-webkit-scrollbar-button,
    ::-webkit-scrollbar-corner {
        background-color: transparent;
    }
`;

const styledComponents = { Tab, TabLabel, TabContent };
export default styledComponents;