import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ConfigService } from '@liasincontrol/config-service';
import Styled from './index.styled';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import { Button } from 'devextreme-react/button';
import { Breakpoint, useResponsiveMode, IconSize, IconValue, Icon } from '@liasincontrol/ui-basics';
import { v4 as uuid } from 'uuid';
import Ink from 'react-ink';
import { Link } from 'react-router-dom';
import { License } from "@liasincontrol/auth-service";
import { getMuiIconAsSvgString } from "@liasincontrol/ui-devextreme";
import { MenuGroup, MenuItem } from "@liasincontrol/ui-elements";

type Props = {
    licenses: License[],
    withExternalApp: boolean,
    withUserManagement: boolean,
};


const CustomLink = ({ to, children, ...props }) => {

    // It is a simple element with nothing to link to
    if (!to.pathname) return <span {...props}>{children}</span>;

    // It is intended to be an external link
    if (/^https?:\/\//.test(to.pathname)) return <a href={to.pathname} {...props}>{children}</a>;

    // Finally, it is an internal link
    return <Link to={to} {...props}>{children}</Link>;
};

/**
 * Represents a UI component that renders the main menu for InControl app.
 */
export const MainMenu: React.FC<Props> = (props) => {
    const [managementURL, setManagementURL] = useState<string>('');
    const [expanded, setExpanded] = useState<boolean>(false);
    const { is } = useResponsiveMode();
    const mini = is(Breakpoint.s) || is(Breakpoint.xs);
    const collapsed = !expanded && !!mini;

    useEffect(() => {
        if (managementURL !== '') return;
        ConfigService.getConfigurations()
            .then((config) => {
                setManagementURL(config.IDENTITY.ACCOUNT_URL);
            });
    }, [managementURL]);

    const getGroupItemElements = useCallback((items: MenuItem[]) => items.filter(item => item.enabled).map((menuItem) => {
        const itemId = `${uuid()}`;
        return (
            <CustomLink id={`btn-menu-${itemId}`} key={itemId} to={{ pathname: menuItem.to }} target={menuItem.target} style={{ color: 'inherit', textDecoration: 'inherit' }} >
                {menuItem.description && <Styled.HiddenText id={`${itemId}-description`}>{menuItem.description}</Styled.HiddenText>}
                <Styled.ButtonContainer
                    role="option"
                    aria-label={menuItem.alt}
                    title={menuItem.description}
                    $active={menuItem.active}
                    aria-current={menuItem.active ? 'page' : undefined}
                    aria-describedby={menuItem.description && `${itemId}-description`}
                    aria-selected={menuItem.active}
                    $collapsed={collapsed!}>
                    <Styled.ButtonContent
                        $value={menuItem.icon as IconValue}
                        $collapsed={collapsed!}>
                        {mini
                            ? <Icon value={menuItem.icon} size={IconSize.small} />
                            : <Icon value={menuItem.icon} size={IconSize.default} />
                        }
                        {!collapsed && <Styled.ButtonText>{menuItem.title}</Styled.ButtonText>}
                        <Ink recenter={true} />
                    </Styled.ButtonContent>
                </Styled.ButtonContainer>
            </CustomLink>
        );
    }), [collapsed, mini]);

    const groupElements = useMemo(() => {
        const menuItemsCollection = [...menuItems(props.licenses, props.withExternalApp)];
        if (props.withUserManagement) {
            const userManagementGroup: MenuGroup = {
                key: "menu-cockpit-incontrol-item-10",
                title: "Beheer apps",
                items: [
                    {
                        key: "incontrol-cockpit-menu-item-info-10",
                        active: false,
                        title: "Gebruikers",
                        alt: "Edit Users",
                        to: managementURL,
                        icon: IconValue.User,
                        target: "_blank",
                        enabled: true
                    }
                ]
            };
            menuItemsCollection.push(userManagementGroup);
        }

        return menuItemsCollection.map((group) => {
            const groupId = `${uuid()}-group`;
            return (
                <Styled.GroupListbox key={groupId} role="listbox" aria-labelledby={group.title && groupId}>
                    {group.title && (
                        <Styled.GroupLabel id={groupId} role="presentation">
                            {group.title}
                        </Styled.GroupLabel>
                    )}
                    {getGroupItemElements(group.items)}
                </Styled.GroupListbox>
            );
        });
    }, [managementURL, getGroupItemElements, props.licenses]);

    return (
        <Styled.Container
            role="navigation"
            aria-label="main-menu-navigation"
            $collapsed={collapsed}>
            {mini && (
                <Button
                    id='btn-main-menu-toogle'
                    type='default'
                    stylingMode="text"
                    icon={getMuiIconAsSvgString(expanded ? CloseIcon : SortIcon)}
                    hint={expanded ? 'Navigatie inklappen' : 'Navigatie uitklappen'}
                    onClick={() => setExpanded(!expanded)}
                />
            )}
            {groupElements}
        </Styled.Container>
    );
};

const isOptionEnabled = (requiredLicense: License, userLicenses: License[]) => {
    return userLicenses.includes(requiredLicense);
};

const menuItems = (userLicenses: License[], withExternalApp: boolean): MenuGroup[] => {
    const appList: MenuItem[] = [
        {
            key: `incontrol-cockpit-menu-item-publisher`,
            active: false,
            title: "Publisher",
            alt: "Open module Publisher",
            to: "/publisher",
            icon: IconValue.Publisher,
            enabled: isOptionEnabled(License.Publisher, userLicenses)
        },
        {
            key: `incontrol-cockpit-menu-item-performance`,
            active: false,
            title: "Performance",
            alt: "Open module Performance",
            to: "/performance",
            icon: IconValue.Performance,
            enabled: isOptionEnabled(License.Performance, userLicenses)
        },
        {
            key: `incontrol-cockpit-menu-item-finance`,
            active: false,
            title: "Finance",
            alt: "Open module Finance",
            to: "/finance",
            icon: IconValue.Finance,
            enabled: isOptionEnabled(License.Finance, userLicenses)
        },
        {
            key: `incontrol-cockpit-menu-item-studio`,
            active: false,
            title: "Studio",
            alt: "Open module Studio",
            to: "/studio",
            icon: IconValue.Studio,
            enabled: isOptionEnabled(License.Studio, userLicenses)
        },
        {
            key: `incontrol-cockpit-menu-item-risk`,
            active: false,
            title: "Risico management",
            alt: "Open module Risk",
            to: "/risk",
            icon: IconValue.Risk,
            enabled: false
        },
        {
            key: `incontrol-cockpit-menu-item-analytics`,
            active: false,
            title: "LIAS Analytics",
            alt: "Open module Analytics",
            to: "/analytics",
            icon: IconValue.Datawarehouse,
            enabled: isOptionEnabled(License.Analytics, userLicenses)
        },
        {
            key: `incontrol-cockpit-menu-item-ai`,
            active: false,
            title: "LIAS Assistant",
            alt: "Open module LIAS Assistant",
            to: "/ai",
            icon: IconValue.AI,
            enabled: isOptionEnabled(License.AITextAssistant, userLicenses)
        }
    ];

    if (withExternalApp) {
        appList.push(
            {
                key: `incontrol-cockpit-menu-item-isms`,
                active: false,
                title: "LIAS ISMS",
                alt: "Open module LIAS ISMS",
                to: "https://bio.isms.nl/sep-bio",
                icon: IconValue.Growth,
                target: "_blank",
                enabled: true
            },
            {
                key: `incontrol-cockpit-menu-item-pauw`,
                active: false,
                title: "LIAS Pauw",
                alt: "Open module LIAS Pauw",
                to: "https://liaspauw.nl",
                icon: IconValue.Growth,
                target: "_blank",
                enabled: true
            },
            {
                key: `incontrol-cockpit-menu-item-buig`,
                active: false,
                title: "LIAS Buig",
                alt: "Open module LIAS Buig",
                to: "https://liasbuig.nl",
                icon: IconValue.Growth,
                target: "_blank",
                enabled: true
            },
            {
                key: `incontrol-cockpit-menu-item-info`,
                active: false,
                title: "LIAS Info",
                alt: "Open module LIAS Info",
                to: "https://www.liasinfo.nl",
                icon: IconValue.Growth,
                target: "_blank",
                enabled: true
            },
            {
                key: `incontrol-cockpit-menu-item-horizontal`,
                active: false,
                title: "LIAS Horizontaal Toezicht",
                alt: "Open module LIAS Horizontaal Toezicht",
                to: "https://www.liasht.nl",
                icon: IconValue.Growth,
                target: "_blank",
                enabled: true
            }
        );
    }
    const menu = [
        {
            key: "menu-cockpit-incontrol-item-1",
            title: "InControl apps",
            items: appList,
        }
    ];
    return menu;
};