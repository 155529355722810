import styled, { css } from 'styled-components';
import { BasicText, palette, px } from '@liasincontrol/ui-basics';

export enum WarningLook {
    default = 0,
    danger = 1,
    warning = 2,
    dangerInverted = 3,
}

const warningLookCss = {
    [WarningLook.default]: css`
        color: ${palette.grey2};
    `,
    [WarningLook.warning]: css`
        color: ${palette.grey2};
        svg {
            color: ${palette.warningOrange};
        }
    `,
    [WarningLook.danger]: css`
        color: ${palette.danger1};  
        font-weight: 600;      
    `,
    [WarningLook.dangerInverted]: css`
        color: ${palette.white};
        background-color: ${palette.danger2};
        border-radius: ${px(6)};
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;  
    `,
};

const WarningWrapper = styled(BasicText) <{ $look: WarningLook }>`
    display: inline-flex;
    align-items: center;
   
    ${(p) => warningLookCss[p.$look]};

    svg {
        margin: 0 0.25rem;
    }
`;
const styledComponents = { WarningWrapper };
export default styledComponents;
