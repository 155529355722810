import React, { useCallback, useMemo } from 'react';
import { SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import DataSource from "devextreme/data/data_source";
import CustomStore from 'devextreme/data/custom_store';
import { withField, WithFieldBaseType } from '../field';

export type DxSelectItem = number | string;

export type GroupedSelectItem<V> = { key: string; items: V[] };

export type SelectBoxFieldProps<V> = WithFieldBaseType<V> & {
    valueExpr?: keyof V,
    displayExpr?: keyof V,
    clearable?: boolean,
    searchable?: boolean,
    showDropDownButton?: boolean,
    openOnFieldClick?: boolean,
    acceptCustomValue?: boolean,
    placeholder?: string,
    grouped?: boolean,
    groupTemplate?: (item: V) => React.ReactNode,
    groupSelector?: string,
    groupOrder?: 'asc' | 'desc',
    itemRender?: (item: V, templateProps?: SelectElementTemplateProps) => React.ReactNode,
    fieldRender?: (item: V, templateProps?: SelectElementTemplateProps) => React.ReactNode,
    optionItems: V[],
}

const CreateDataSource = <V,>(props: SelectBoxFieldProps<V>): DataSource => {
    const source = new DataSource({
        paginate: false,
        store: new CustomStore({
            load: () => props.optionItems,
            loadMode: 'raw',
        }),
    });
    if (props.grouped) {
        source.group({ selector: props.groupSelector || '', desc: props.groupOrder === 'desc' ? true : false });
    }
    return source;
};

export const SelectBoxField = <V,>(props: SelectBoxFieldProps<V>) => {
    const { onChange, value, searchable, clearable, itemRender, fieldRender } = props;

    const onValueChanged = useCallback((e) => {
        onChange?.(e.value);
    }, [onChange]);

    const ds = useMemo(() =>
        CreateDataSource<V>(props)
        , [props.grouped, props.optionItems, props.groupSelector, props.groupOrder, props.groupTemplate]);

    const HoC = withField<V, SelectBoxFieldProps<V>>(
        () => <Styled.StyledSelectBox
            id={`${props.id}-container`}
            inputAttr={{ id: props.id }}
            displayExpr={!!props.displayExpr ? String(props.displayExpr) : undefined}
            valueExpr={!!props.valueExpr ? String(props.valueExpr) : undefined}
            dataSource={ds}
            value={value}
            disabled={props.disabled}
            readOnly={props.readOnly}
            grouped={props.grouped}
            placeholder={props.placeholder}
            searchEnabled={searchable}
            showClearButton={clearable}
            noDataText='Geen gegevens beschikbaar'
            dropDownOptions={{
                //If it's not in body, the container element will limit the visibility of the dropdown option list!
                //This can generate issues in popups, modals, etc.
                //container: `#${props.id}-container`,
                container: 'body',
                hideOnParentScroll: true,
                hideOnOutsideClick: true,
            }}
            itemRender={itemRender}
            fieldRender={fieldRender}
            onValueChanged={onValueChanged}
            groupRender={props.groupTemplate}
            onFocusOut={props.onBlur}
        />);
    return <HoC {...props} />
};
