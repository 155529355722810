import { DEFAULT_DATE_TIME_FORMAT, DateUtils } from '@liasincontrol/core-service';
import { formatNumber } from "devextreme/localization";

const integerFormatter = (value = 0, formatPattern = "#0,###") => {
    if (!formatPattern.trim())
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    return formatNumber(value, formatPattern);
};

const decimalFormatter = (value = 0, formatPattern = "#0,###.##") => {
    if (!formatPattern.trim())
        return value.toLocaleString(navigator.language, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    return formatNumber(value, formatPattern);
};

const dateFormatter = (value) => {
    if (!value) return null;

    let dateValue = value;
    if ((value instanceof Date) === false) {
        const str = value as string;
        dateValue = new Date(str);
    }
    const formattedNLDate = DateUtils.formatDate(dateValue);
    return formattedNLDate;
};

const dateTimeFormatter = (value, formatPattern = DEFAULT_DATE_TIME_FORMAT) => {
    if (!value) return null;

    let dateTimeValue = value;
    if ((value instanceof Date) === false) {
        const str = value as string;
        dateTimeValue = new Date(str);
    }
    const formattedNLDateTime = DateUtils.formatDateTime(dateTimeValue, formatPattern);
    return (formattedNLDateTime);
};

const createFormatString = (decimal: number, thousandSeparator: boolean, decorationCharacter: string) : string => {
    const prefix = decorationCharacter === '€' ? '€ ' : '';
    const separator = (thousandSeparator ?? true) ? ',' : '';
    const decimals = decimal > 0 ? `.${"0".repeat(decimal)}` : '';
    const postfix = (decorationCharacter !== undefined && decorationCharacter !== '€') ? `'${decorationCharacter}'` : '';

    return `${prefix}#${separator}##0${decimals}${postfix}`;
}

export { integerFormatter, decimalFormatter, dateFormatter, dateTimeFormatter, createFormatString };
