import { JSX, useState } from 'react';
import { Button } from 'devextreme-react/button';
import ContextMenu, { ContextMenuTypes, Position, ShowSubmenuMode } from 'devextreme-react/context-menu';

type Action<T> = {
    text: string;
    visible?: boolean;
    callBack?: (item: T) => void;
    items?: Action<T>[];
}

interface IProps<T> {
    keyExpr: keyof T;
    item: T;
    actions: Action<T>[];
}

export const LiasContextMenu = <T,>(props: IProps<T>): JSX.Element => {
    const [popoverVisible, setPopoverVisible] = useState(false);

    const itemClick = (e: ContextMenuTypes.ItemClickEvent, action: Action<T>) => {
        if (!e.itemData.items) {
            action.callBack(props.item);
        }
    };

    return (
        <>
            <Button
                id={`btn-show-context-menu-${props.item[props.keyExpr]}`}
                type='default'
                stylingMode='text'
                icon='overflow'
                onClick={(e) => {
                    e.event?.stopPropagation();
                    setPopoverVisible(true);
                }}
            />
            <ContextMenu
                items={props.actions}
                visible={popoverVisible}
                target={`#btn-show-context-menu-${props.item[props.keyExpr] as string}`}
                cssClass='lias-context-menu'
                onItemClick={(e) => itemClick(e, e.itemData as Action<T>)}
                onHiding={() => setPopoverVisible(false)}
                width={200}
                submenuDirection='left'
            >
                <Position
                    my="left top"
                    at="left top"
                    offset="25 0"
                    of={`#btn-show-context-menu-${props.item[props.keyExpr] as string}`}
                />
                <ShowSubmenuMode name='onClick' />
            </ContextMenu>

        </>
    );
};
