import { useRef, useState } from "react";

type WithTooltipProps = {
  tooltipCaption: string;
};

export type InnerProps = {
  onMouseEnter: () => void;
  title: string;
};

export function enhanceWithTooltip<T extends InnerProps = InnerProps>(OriginalComponent: React.ComponentType<T>): React.FC<Omit<T, keyof InnerProps> & WithTooltipProps> {
  const Enhanced = ({ tooltipCaption, ...rest }: Omit<T, keyof InnerProps> & WithTooltipProps) => {
    const caption = useRef(null);
    const [title, setTitle] = useState("");

    return (
      <OriginalComponent
        {...(rest as unknown as T)}
        title={title}
        ref={caption}
        onMouseEnter={() => {
          const isOverflow = caption && caption?.current?.scrollWidth - caption?.current?.getBoundingClientRect()?.width > 0.5;
          setTitle(isOverflow ? tooltipCaption : "");
        }}
      />
    );
  };

  return Enhanced;
}
