import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useRef, useState } from 'react';
import Styled from './index.styled';
import { Button } from 'devextreme-react/button';
import { IconSize, NormalText } from '@liasincontrol/ui-basics';
import { ImageFocusViewer } from './viewer';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';

export type ImageFocus = [/*x*/ number, /*y*/ number, /*scale*/ number];
type ChangeHandlerArgs = { point: ImageFocus };
type ChangeHandler = (args: ChangeHandlerArgs) => void;

export const ImageFocusX = 0;
export const ImageFocusY = 1;
export const ImageFocusScale = 2;

const defaultPoint: ImageFocus = [0.5, 0.5, 1];
const supportPointerLock = Boolean(typeof document !== `undefined` && document.exitPointerLock);

type Props = {

    /**
     * Determines if the image preview is scaled when zooming inside.
     */
    contain?: boolean;

    /**
     * Defines the source of the image.
     */
    src: string;

    /**
     * Defines the initial focus point.
     */
    initialPoint: ImageFocus;

    /**
     * Represents an event handler that is called when a change has been made over the focus point values.
     */
    onChange: ChangeHandler;

    /**
     * Defines the additional style.
     */
    style?: React.CSSProperties;

};

/**
 * Represents a UI component that renders an image focus editor.
 */
export const ImageFocusEditor = ({ contain, src, initialPoint, onChange, style = {} }: Props) => {

    const imgContainer = useRef<HTMLDivElement>(null);
    const [image, setImage] = useState<HTMLImageElement>();
    const [pointer, setPointer] = useState<{ coordinates: ImageFocus, pointerDown: boolean }>({ coordinates: initialPoint || defaultPoint, pointerDown: false });

    useEffect(() => {
        setImage(undefined);
        const image = new Image();
        image.src = src;
        image.onload = () => {
            setImage(image);
        };
    }, [src]);

    useEffect(() => {
        if (pointer.pointerDown) {
            return;
        }

        onChange({ point: pointer.coordinates });
    }, [pointer]);

    const onPointerUp: React.PointerEventHandler = (e) => {
        supportPointerLock && imgContainer?.current?.releasePointerCapture(e.pointerId);

        const bounds = imgContainer?.current?.getBoundingClientRect();
        if (!bounds) {
            return;
        }
        setPointer((prev) => ({
            coordinates: [(-bounds.left + e.clientX) / bounds.width, (-bounds.top + e.clientY) / bounds.height, prev.coordinates[ImageFocusScale]],
            pointerDown: false
        }));

        e.stopPropagation();
        e.preventDefault();
    };

    const onPointerDown: React.PointerEventHandler = (e) => {
        if (e.pointerType === `mouse` && !e.ctrlKey) {
            // Set position using cursor position
            if (contain) {
                const bounds = imgContainer.current?.getBoundingClientRect();
                if (!bounds) {
                    return;
                }
                setPointer((prev) => ({
                    coordinates: [(-bounds.left + e.clientX) / bounds.width, (-bounds.top + e.clientY) / bounds.height, prev.coordinates[ImageFocusScale]],
                    pointerDown: true
                }));
            }
        }

        supportPointerLock && imgContainer.current?.setPointerCapture(e.pointerId);
        // Set start position using cursor position
        e.stopPropagation();
        e.preventDefault();
    };

    const onPointerMove = (e: React.PointerEvent<HTMLDivElement>) => {
        if (!pointer.pointerDown) {
            e.stopPropagation();
            e.preventDefault();
            return;
        }

        const bounds = imgContainer.current?.getBoundingClientRect();
        if (!bounds) {
            return;
        }
        setPointer((prev) => ({
            ...prev,
            coordinates: [
                constrain(prev.coordinates[ImageFocusX] + e.movementX / bounds.width, 0, 1),
                constrain(prev.coordinates[ImageFocusY] + e.movementY / bounds.height, 0, 1),
                prev.coordinates[ImageFocusScale]
            ]
        }));

        e.stopPropagation();
        e.preventDefault();
    };

    const onZoomIn = (e) => {
        setPointer((prev) => ({
            ...prev,
            coordinates: [prev.coordinates[ImageFocusX], prev.coordinates[ImageFocusY], nextZoomLevel(prev.coordinates[ImageFocusScale])]
        }));

        e.stopPropagation();
    };
    const onZoomOut = (e) => {
        setPointer((prev) => ({
            ...prev,
            coordinates: [prev.coordinates[ImageFocusX], prev.coordinates[ImageFocusY], prevZoomLevel(prev.coordinates[ImageFocusScale])]
        }));

        e.stopPropagation();
    };

    if (image) {
        const zoomInside = (
            <Styled.ZoomContainerInside>
                <Styled.ZoomButtonInside type="button" title="Inzoomen" onClick={onZoomIn} disabled={!canNextZoomlevel(pointer.coordinates[ImageFocusScale])}>
                    <AddIcon sx={{ fontSize: 16 }} />
                </Styled.ZoomButtonInside>
                <Styled.ZoomButtonInside type="button" title="Uitzoomen" onClick={onZoomOut} disabled={!canPrevZoomlevel(pointer.coordinates[ImageFocusScale])}>
                    <RemoveIcon sx={{ fontSize: 16 }} />
                </Styled.ZoomButtonInside>
            </Styled.ZoomContainerInside>
        );
        const zoomOutside = (
            <Styled.ZoomContainerOutside>
                <Button
                    type="normal"
                    stylingMode='contained'
                    className='btn-small'
                    hint="Uitzoomen"
                    onClick={(e) => onZoomOut(e.event)}
                    icon={getMuiIconAsSvgString(RemoveIcon, IconSize.small)}
                    disabled={!canPrevZoomlevel(pointer.coordinates[ImageFocusScale])}
                />
                <NormalText>{pointer.coordinates[2].toFixed(1)}x</NormalText>
                <Button
                    type="normal"
                    stylingMode='contained'
                    className='btn-small'
                    hint="Inzoomen"
                    onClick={(e) => onZoomIn(e.event)}
                    icon={getMuiIconAsSvgString(AddIcon, IconSize.small)}
                    disabled={!canNextZoomlevel(pointer.coordinates[ImageFocusScale])}
                />
            </Styled.ZoomContainerOutside>
        );
        const imageAsContain = (
            <Styled.StyledImage
                src={src}
                style={{
                    ...style,
                    maxHeight: (style && style.height) || style.maxHeight,
                    maxWidth: (style && style.width) || style.maxWidth,
                    minHeight: undefined,
                    minWidth: undefined,
                    width: undefined,
                    height: undefined,
                }}
            />
        );
        const imageAsRegular = <ImageFocusViewer src={src} dimensions={[image.width, image.height]} point={pointer.coordinates} style={{ height: image.height, ...style }} />;
        return (
            <Styled.Container
                style={{
                    ...style,
                    maxHeight: (style && style.height) || style.maxHeight,
                    maxWidth: (style && style.width) || style.maxWidth,
                    minHeight: undefined,
                    minWidth: undefined,
                    width: undefined,
                    height: undefined,
                }}
            >
                {contain ? (
                    <Styled.CenterContainer style={style}>
                        <Styled.ImageContainer
                            onPointerMove={onPointerMove}
                            onPointerUp={onPointerUp}
                            onPointerDown={onPointerDown}
                            ref={imgContainer}
                        >
                            {imageAsContain}
                            <Styled.Circle point={pointer.coordinates} />
                        </Styled.ImageContainer>
                    </Styled.CenterContainer>
                ) : (
                    <Styled.ImageContainer
                        onPointerMove={onPointerMove}
                        onPointerUp={onPointerUp}
                        onPointerDown={onPointerDown}
                        ref={imgContainer}>
                        {imageAsRegular}
                        <Styled.Circle point={pointer.coordinates} />
                    </Styled.ImageContainer>
                )}
                {zoomInside}
                {zoomOutside}
            </Styled.Container>
        );
    } else {
        return null;
    }
};

//
// Zoom

const zoomlevels = [1.0, 1.3, 2.1, 3.4, 5.5, 8.9, 14.4];
const canNextZoomlevel = (input: number) => zoomlevels.some((x) => x > input);
const nextZoomLevel = (input: number) => zoomlevels.find((x) => x > input) || zoomlevels[zoomlevels.length - 1];
const canPrevZoomlevel = (input: number) => zoomlevels.some((x) => x < input);
const prevZoomLevel = (input: number) => zoomlevels.slice(0).reverse().find((x) => x < input) || zoomlevels[0];
const constrain = (val: number, min: number, max: number) => (val < min ? min : val > max ? max : val);

