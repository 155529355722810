import React, { useCallback, useRef } from 'react';
import Styled from './index.styled';
import { DxDataSource } from '../..';
import { withField, WithFieldBaseType } from '../field';

export type ListFieldProps = WithFieldBaseType<any> & {
    displayExpr: string | ((item) => string),
    placeholder?: string,
    dataSource?: DxDataSource<any, any> | any[],
    items?: any[],
    itemKey?: string,
    isDisabled?: boolean,
    noDataText?: string,
    selectedLabel?: string,
    enableSearch?: boolean,
    customHeaderComponent?: React.ReactNode,
    itemTemplate?: (...params: any) => React.ReactNode,
    searchExpr?: string,
};

export const ListField = withField<any, ListFieldProps>(
    (props) => {
        const { id, displayExpr, value, items, dataSource, isDisabled, noDataText, selectedLabel, enableSearch, customHeaderComponent,
            itemTemplate, searchExpr, onChange } = props;

        const onChangeCallBack = useCallback((e) => onChange?.(e), [onChange]);

        const builder = useRef(null);

        return (
            <>
                <Styled.StyledHeader>
                    <>{customHeaderComponent}</>
                </Styled.StyledHeader>
                <Styled.StyledSelected>
                    <span className="caption">{selectedLabel}: </span>
                    <span>{value.map(v => typeof displayExpr === 'string' ? v[displayExpr] : displayExpr(v))?.join(', ')}</span>
                </Styled.StyledSelected>
                <Styled.StyledList
                    ref={builder}
                    repaintChangesOnly={true}
                    id={id}
                    height={250}
                    displayExpr={displayExpr}
                    disabled={isDisabled}
                    showSelectionControls={true}
                    selectionMode='multiple'
                    selectAllMode='allPages'
                    selectedItemKeys={value}
                    dataSource={items ? items : dataSource}
                    pageLoadMode='scrollBottom'
                    pageLoadingText='Laden...'
                    indicateLoading={true}
                    scrollByContent={true}
                    scrollByThumb={true}
                    useNativeScrolling={true}
                    onSelectionChanged={onChangeCallBack}
                    noDataText={noDataText}
                    searchEnabled={enableSearch}
                    searchMode='contains'
                    searchTimeout={500}
                    searchEditorOptions={{
                        placeholder: 'Zoeken',
                    }}
                    searchExpr={searchExpr}
                    itemRender={itemTemplate}
                />
            </>
        );
    }
);
