import React, { useEffect, useState } from 'react';
import { Heading2, PageTitle, PanelGrid, Wrapper, WrapperContent } from '@liasincontrol/ui-basics';
import { TextImageWidget } from '@liasincontrol/ui-elements';
import { ActionSource, AjaxRequestStatus, useModules } from '@liasincontrol/redux-service';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { PerformanceIconTiles } from './PerformanceIconTiles';
import { PerformanceTaskList } from './PerformanceTaskList';

/**
 * Represents a UI component that renders the Performance cockpit view.
 */
const Cockpit: React.FC = () => {
    const modules = useModules(ActionSource.Performance);
    const [performanceModuleId, setPerformanceModuleId] = useState<string>(null);

    useEffect(() => {
        if (modules?.items?.[SystemModuleDefinitions.Performance]?.id) {
            setPerformanceModuleId(modules.items[SystemModuleDefinitions.Performance].id);
        }
    }, [modules]);

    if (!modules || modules.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    return (
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Cockpit Performance</Heading2>
                </PageTitle>
                <PanelGrid>
                    <TextImageWidget
                        title='LIAS Performance'
                        text='Voer beleid eenvoudig op en vertaal deze naar onderliggende doelstellingen, prestaties en activiteiten.
                            Monitor de voortgang en presenteer deze voortgang gemakkelijk in Publisher.'
                    />
                    {performanceModuleId && <PerformanceIconTiles performanceModuleId={performanceModuleId} />}
                    {performanceModuleId && <PerformanceTaskList performanceModuleId={performanceModuleId} />}
                </PanelGrid>
            </WrapperContent>
        </Wrapper>
    );
};

export { Cockpit as index };
