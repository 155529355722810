import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, UsersActionCreator, RootState } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

type UsersType = {
    items: Domain.Shared.User[],
    status: AjaxRequestStatus,
}

export const useUsers = () => {
    const templates = useSelector<State, UsersType>((state) => state.users);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    if (templates.status === AjaxRequestStatus.NotSet) {
        dispatch(UsersActionCreator.set());
    }

    return templates;
};