import React from 'react';
import styled from 'styled-components';
import { IThumbnailImgProps } from './Props';

import addFiles from './imgs/illu-add-files.svg';
import algemeen from './imgs/illu-algemeen.svg';
import blankCanvas from './imgs/illu-blank-canvas.svg';
import exploitatie from './imgs/illu-exploitatie.svg';
import hierarchies from './imgs/illu-hierarchiedefs.svg';
import news from './imgs/illu-news.svg';
import notes from './imgs/illu-notes.svg';
import pieChart from './imgs/illu-pieChart.svg';
import progress from './imgs/illu-progress.svg';
import pubInrichting from './imgs/illu-pub-inrichting.svg';
import publicaties from './imgs/illu-publicaties.svg';
import schermen from './imgs/illu-schermen.svg';
import spreadsheet from './imgs/illu-spreadsheet.svg';
import todo from './imgs/illu-todo.svg';
import velden from './imgs/illu-velden.svg';
import workflow from './imgs/illu-workflow.svg';
import undrawSpreadsheets from './imgs/illu_undrawSpreadsheets.svg';
import attachedFile from './imgs/illu-attached-file.svg';
import onlineReading from './imgs/illu-online-reading.svg';
import undrawFileSearching from './imgs/undraw_file_searching.svg';
import undrawSelection from './imgs/undraw_selection_re_ycpo.svg';
import undrawAddFriends from './imgs/undraw_add-friends_v4kx.svg';
import undrawArtificialIntelligence from './imgs/undraw_artificial_intelligence_re_enpp.svg';
import { px } from '../../style';

interface ISvgProps {
    readonly $variant: string;
}

const svgImg: { readonly [key: string]: string; } = {
    addFiles: `transparent url( ${addFiles}) 0 0 no-repeat`,
    algemeen: `transparent url( ${algemeen}) 0 0 no-repeat`,
    exploitatie: `transparent url( ${exploitatie}) 0 0 no-repeat`,
    hierarchies: `transparent url( ${hierarchies}) 0 0 no-repeat`,
    pubInrichting: `transparent url( ${pubInrichting} ) 0 0 no-repeat`,
    publicaties: `transparent url( ${publicaties} ) 0 0 no-repeat`,
    pieChart: `transparent url( ${pieChart} ) 0 0 no-repeat`,
    schermen: `transparent url( ${schermen} ) 0 0 no-repeat`,
    velden: `transparent url( ${velden} ) 0 0 no-repeat`,
    workflow: `transparent url( ${workflow} ) 0 0 no-repeat`,
    news: `transparent url( ${news} ) 0 0 no-repeat`,
    blankCanvas: `transparent url( ${blankCanvas} ) 0 0 no-repeat`,
    todo: `transparent url( ${todo} ) 0 0 no-repeat`,
    notes: `transparent url( ${notes} ) 0 0 no-repeat`,
    spreadsheet: `transparent url( ${spreadsheet} ) 0 0 no-repeat`,
    progress: `transparent url( ${progress} ) 0 0 no-repeat`,
    undrawSpreadsheets: `transparent url( ${undrawSpreadsheets} ) 0 0 no-repeat`,
    attachedFile: `transparent url( ${attachedFile} ) 0 0 no-repeat`,
    onlineReading: `transparent url( ${onlineReading} ) 0 0 no-repeat`,
    undrawFileSearching: `transparent url( ${undrawFileSearching} ) 0 0 no-repeat`,
    undrawSelection: `transparent url( ${undrawSelection} ) 0 0 no-repeat`,
    undrawAddFriends: `transparent url( ${undrawAddFriends} ) center center no-repeat`,
    undrawArtificialIntelligence: `transparent url( ${undrawArtificialIntelligence} ) 0 0 no-repeat`,
};

const ThumbnailBg = styled.div<{ $width?: number }>`
    display: inline-block;
    height: 232px;
    width: ${(p) => p.$width ? px(p.$width) : `95%`};
`;

const SvgWrap = styled.i<ISvgProps>`
    display:block;
    width:100%;
    height:100%;
    background:${(p) =>
        svgImg[p.$variant] || svgImg.algemeen
    };
    background-size:contain;
`;

export const ThumbnailImg = ({ variant, width }: IThumbnailImgProps) => {
    return (
        <ThumbnailBg $width={width}>
            <SvgWrap $variant={variant}></SvgWrap>
        </ThumbnailBg>
    );
};
