import React, { useCallback, useRef, useState } from 'react';
import { Popup, ToolbarItem } from 'devextreme-react/popup';
import { DropDownBox, RadioGroup, TreeView } from 'devextreme-react';
import TextBox from 'devextreme-react/text-box';
import { UrlState, UrlTypes } from './index';
import { TreeViewRef } from 'devextreme-react/cjs/tree-view';

type LinkPopupProps = {
    originalUrl: UrlState;
    sitemapsource?: any[];
    insertLink: (type: UrlTypes, text: string, url: string) => void;
    onClose: () => void;
};

/**
 * LinkPopup component provides a modal for inserting hyperlinks.
 */
const LinkPopup: React.FC<LinkPopupProps> = ({ originalUrl, sitemapsource, insertLink, onClose }) => {
    const treeViewRef = useRef<TreeViewRef<any>>(null);
    const [isTreeBoxOpened, setIsTreeBoxOpened] = useState(false);
    const [linkType, setLinkType] = useState(originalUrl.type);
    const linkUrl = useRef(originalUrl.url);
    const linkText = useRef(originalUrl.text);

    const treeViewItemSelectionChanged = useCallback((e) => {
        setLinkType('Internal');
        linkUrl.current = e.itemData.id;
        if (!linkText) linkText.current = e.itemData.name;
    }, [linkText]);

    const treeViewOnContentReady = useCallback((e) => {
        e.component.selectItem(linkUrl.current);
    }, [linkUrl.current]);

    const onTreeItemClick = useCallback(() => {
        setIsTreeBoxOpened(false);
    }, [setIsTreeBoxOpened]);

    const treeViewRender = useCallback(() => (
        <TreeView
            items={sitemapsource}
            ref={treeViewRef}
            dataStructure="plain"
            keyExpr="id"
            parentIdExpr="parentId"
            selectionMode="single"
            displayExpr="name"
            selectByClick={true}
            onContentReady={treeViewOnContentReady}
            onItemClick={onTreeItemClick}
            onItemSelectionChanged={treeViewItemSelectionChanged}
        />
    ), [sitemapsource, treeViewOnContentReady, onTreeItemClick, treeViewItemSelectionChanged]);

    const onTreeBoxOpened = useCallback((e) => {
        if (e.name === 'opened') {
            setIsTreeBoxOpened(e.value);
        }
    }, [setIsTreeBoxOpened]);

    const syncTreeViewSelection = useCallback((e) => {
        linkUrl.current = e.value;
        if (!treeViewRef.current) return;

        if (!e.value) {
            treeViewRef.current.instance().unselectAll();
        } else {
            treeViewRef.current.instance().selectItem(e.value);
        }
    }, [linkUrl.current]);

    const radioItems: { key: string, label: string }[] = [
        { key: 'External', label: 'Externe website' },
        { key: 'Internal', label: 'Pagina in publicatie' },
    ];

    return (
        <Popup
            showTitle={true}
            title="Hyperlink invoegen"
            visible={true}
            showCloseButton={true}
            width={400}
            height={'auto'}
            dragEnabled={false}
            hideOnParentScroll={true}
            onHiding={onClose}
        >
            <div className="dx-fieldset dx-lias-fieldset">
                <div className="dx-field dx-lias-field">
                    <div className="dx-field-label">Link naar</div>
                    <RadioGroup
                        items={radioItems}
                        valueExpr='key'
                        displayExpr='label'
                        defaultValue={linkType}
                        layout="horizontal"
                        onValueChanged={(e) => {
                            setLinkType(e.value);
                            linkUrl.current = '';
                        }}
                    />
                </div>
                <div className="dx-field dx-lias-field">
                    {linkType === 'Internal' ?
                        <>
                            <div className="dx-field-label">Pagina</div>
                            <DropDownBox
                                value={linkUrl.current}
                                opened={isTreeBoxOpened}
                                valueExpr="id"
                                displayExpr="name"
                                placeholder="Kies..."
                                showClearButton={true}
                                items={sitemapsource}
                                onValueChanged={syncTreeViewSelection}
                                onOptionChanged={onTreeBoxOpened}
                                contentRender={treeViewRender}
                            />
                        </> :
                        <>
                            <div className="dx-field-label">URL</div>
                            <TextBox
                                defaultValue={linkUrl.current}
                                onValueChanged={(e) => { linkUrl.current = e.value; }}
                            />
                        </>}
                </div>
                <div className="dx-field dx-lias-field">
                    <div className="dx-field-label">Tekst</div>
                    <TextBox
                        defaultValue={linkText.current}
                        onValueChanged={(e) => { linkText.current = e.value; }}
                    />
                </div>
            </div>

            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'OK',
                    stylingMode: 'text',
                    type: 'default',
                    onClick: () => {
                        insertLink(linkType, linkText.current, linkUrl.current);
                    },
                }}
            />
            <ToolbarItem
                widget="dxButton"
                toolbar="bottom"
                location="after"
                options={{
                    text: 'Annulleren',
                    stylingMode: 'text',
                    type: 'default',
                    onClick: onClose,
                }}
            />
        </Popup>
    );
};

export default LinkPopup;