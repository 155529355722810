import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, RootState, ActionSource, HierarchyDefinitionsActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

type HierarchyDefinitionsType = {
    items: { [id: string]: Domain.Shared.HierarchyDefinition, }
    status: AjaxRequestStatus,
}

export const useHierarchyDefinitions = (source: ActionSource, data: { moduleId: string, includeLinkDefinitions: boolean }) => {
    const hierarchyDefinitions = useSelector<State, HierarchyDefinitionsType>((state) => state.hierarchydefinitions[source]);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    const fetchHierarchyDefinitions = (source: ActionSource, data: { moduleId: string, includeLinkDefinitions: boolean }) => {
        dispatch(HierarchyDefinitionsActionCreator.set({ source: source, data: data }));
    };

    useEffect(() => {
        if (data?.moduleId && (!hierarchyDefinitions || hierarchyDefinitions.status === AjaxRequestStatus.NotSet)) {
            fetchHierarchyDefinitions(source, data);
        }
    }, [data?.moduleId, hierarchyDefinitions, source, dispatch]);

    return { hierarchyDefinitions, fetchHierarchyDefinitions };
}