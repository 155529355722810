import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { px } from '../../style';
import { palette } from '../../styleguide';
import { BasicText } from '../typography';

const ErrorIcon = styled(CloseIcon)`
    fill: ${palette.validationNeg};
    width: ${px(16)};
    height: ${px(16)};
    position: absolute;
    left: 0;
    font-size: 1.1rem;
`;

const LeftSpan = styled.span`
    float: left;
    flex: 1;
`;
const RightSpan = styled.span`
    float: right;
    margin-left: auto;
`;

const Container = styled(BasicText)<{ $error: boolean }>`
    padding-left: ${({ $error }) => ($error ? `${px(20)}` : null)};
    display: flex;
    min-height: ${px(24)}; /* HACK: for reserving space for inputs for now */
    line-height: ${px(24)};
    color: ${({ $error } ) => ($error ? palette.validationNeg : palette.grey2)};
    position: relative;
`;

const styledComponents = { Container, LeftSpan, RightSpan, ErrorIcon };
export default styledComponents;