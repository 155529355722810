import React from 'react';
import { InputCheckbox } from '../primitives';
import * as Styled from './index.styles';

export type MultiSelectItem = {
  readonly label: string;
  readonly value: boolean;
  readonly id: string;
  readonly disabled?: boolean;
}

type Props = {
  readonly className?: string,
  readonly withBorder?: boolean,
  readonly onChange: (item: MultiSelectItem, value: boolean) => void,
  readonly previewMax: { height: number, width: number },
  readonly listItems: MultiSelectItem[],
}

/**
 * Represents a checkbox list that allows the selection of multiple items.
 */
export const MultiSelectList: React.FC<Props> = ({ className, withBorder, onChange, previewMax, listItems }) => {

  return (
    <Styled.Container className={className} $previewMax={previewMax}>
      <Styled.Border $withBorder={withBorder}>
        <Styled.CheckList>
          {listItems.map((item: MultiSelectItem) => {
            return (
              <Styled.Li key={item.id}>
                <InputCheckbox
                  id={item.id}
                  label={item.label}
                  ariaLabel={`item ${item.label} with id ${item.id}`}
                  selected={item.value}
                  onChange={(value) => onChange(item, value)}
                  disabled={!!item?.disabled}
                />
              </Styled.Li>);
          })}
        </Styled.CheckList>
      </Styled.Border>
    </Styled.Container>
  );
};
