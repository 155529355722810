import React from 'react';
import styled, { css } from 'styled-components';
import { px } from '../style';
import { cssBreakpoint } from '../responsive/css';

export const WrapperBreadcrumb = ({ children, navigation }: { children: React.ReactNode; navigation?: boolean }) => {
    return (
        <Nav navigation={navigation}>
            <Center><>{children}</></Center>
        </Nav>
    )
};

// tslint:disable:no-magic-numbers

const Nav = styled.nav<{ navigation?: boolean }>`
    position: relative;
    display: grid;
    background-color: #fafafc;
    ${({ navigation }) =>
        navigation
            ? css`
                  grid-row: 2;
                  grid-column: 3;
                  ${cssBreakpoint(`xs`, `s`)`grid-template-columns: auto ${px(48)} 1fr auto;`}
              `
            : css`
                  grid-row: 2;
                  grid-column: 2;
                  grid-template-columns: auto minmax(auto, ${px(2560)}) auto;
              `};
`;

const Center = styled.div`
    padding: ${px(16)} 0;

    ${cssBreakpoint('xl', 'l', 'm')`
        padding: ${px(16)} ${px(24)}
    `};

    ${cssBreakpoint('s')`
        padding: ${px(16)} ${px(8)}
    `};
`;
