import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';

export class TaskSummaries {

    /**
     * Gets task summaries for Publisher, Studio and Finance.
     */
    public static get = async (): Promise<AxiosResponse<Domain.Dto.Shared.ActiveTasksSummary>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Dto.Shared.ActiveTasksSummary>(`/api/cockpit/activetasksummary`, config);
    };

}