import { AxiosResponse } from 'axios';
import HttpClient from '@liasincontrol/http-service';
import * as Domain from '@liasincontrol/domain';
import { DataAccessHostUtils } from '../../Shared/DataAccessHostUtils';
import { oDataResponse } from '../../Shared/oData/oDataResponse';

/**
 * Represents a data accessor of Studio hierarchies.
 */
export class HierarchyDataAccessor {

    /**
     * Gets the collection of Studio hierarchy items.
     * 
     * @param hierarchyId Defines the hierarchy unique identifier.
     * @param includeSettings True if the Studio tree settings should also be retrieved along with the tree data.
     * @param includeWorkflowState True if the Studio tree items should also contain workflow state information.
     */
    public static get = async (hierarchyId: string, includeSettings = true, includeWorkflowState = true): Promise<AxiosResponse<{ hierarchy: Domain.Studio.HierarchyItem[], settings?: Domain.Studio.StudioHierarchySettings }>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        const extraParams = includeSettings || includeWorkflowState ? `?includeSettings=${includeSettings}&includeWorkflowStates=${includeWorkflowState}` : '';
        return HttpClient.get<{ hierarchy: Domain.Studio.HierarchyItem[], settings?: Domain.Studio.StudioHierarchySettings }>(`/api/studio/hierarchy/${hierarchyId}${extraParams}`, config);
    };


    /**
     * Get all hierarchies based on a query
     * @param query The query
     * @returns A list of hierarchies
     */
    public static getV2 = async (query: string): Promise<AxiosResponse<oDataResponse<Domain.Studio.HierarchyListItem[]>>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<oDataResponse<Domain.Studio.HierarchyListItem[]>>(`/api/studio/hierarchy${query}`, config);
    };

    /**
     * Gets a dictionary with key as measure moment id and values as hierarchy definition ids used in that measure moment.
     */
    public static getUsedHierarchies = async (): Promise<AxiosResponse<{ measureMomentId: string, hiearchyDefinitionIds: string[] }[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.get<{ measureMomentId: string, hiearchyDefinitionIds: string[] }[]>(`/api/studio/hierarchy/definitions/used`, config);
    };

    /**
     * Creates a hierarchy.
     * @param measureMomentId MeasureMomentId on which will the hierarchy created.
     * @param hierarchyDefinitionId HierarchyDefinitionId based on what the hierarchy will be created.
     * @returns the Hierarchy Id.
     */
    public static createEmptyHierarchy = async (measureMomentId: string, hierarchyDefinitionId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<string>('/api/studio/hierarchy', { measureMomentId: measureMomentId, hierarchyDefinitionId: hierarchyDefinitionId }, config);
    };

    /**
     * Delete a hierarchy
     * @param hierarchyId The unique identifier of the hierarchy.
     */
    public static deleteHierarchy = async (hierarchyId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(`/api/studio/hierarchy/${hierarchyId}`, config);
    };

    /**
     * Copies/imports a hierarchy from one measure moment to another.
     * @param hierarchyDefinitionId Defines the hierarchy definition Id of the hierarchy to be copied
     * @param fromMeasureMomentId Defines the measure moment Id of the hierarchy to be copied
     * @param toMeasureMomentId Defines the measure moment Id of the target hierarchy 
     * @returns the new Hierarchy Id
     */
    public static importHierarchy = async (hierarchyDefinitionId: string, fromMeasureMomentId: string, toMeasureMomentId: string): Promise<AxiosResponse<string>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.post<string>('/api/studio/hierarchy/copy', { hierarchyDefinitionId, fromMeasureMomentId, toMeasureMomentId }, config)
    };

    /**
    * Links a hierarchy item to a structure node.
    * 
    * @param hierarchyId The unique identifier of the hierarchy.
    * @param itemId The unique identifier of the item.
    * @param model The request model for linking.
    */
    public static linkHierarchyItemToStructureNode = async (
        hierarchyId: string,
        itemId: string,
        model: Record<string, string>
    ): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(
            `/api/studio/hierarchy/${hierarchyId}/${itemId}/structurenode`,
            model,
            config
        );
    };

    /**
     * Unlinks a structure node from a hierarchy item.
     * 
     * @param hierarchyId The unique identifier of the hierarchy.
     * @param itemId The unique identifier of the item.
     */
    public static unlinkStructureNodeFromHierarchyItem = async (
        hierarchyId: string,
        itemId: string
    ): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.delete<void>(
            `/api/studio/hierarchy/${hierarchyId}/${itemId}/structurenode`,
            config
        );
    };

    /**
     * Gets the list of editors for the hierarchy.
     *
     * @param hierarchyId Id of the hierarchy.
     */
    public static getHierarchyEditors = async (hierarchyId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/studio/${hierarchyId}/editors`, config);
    };

    /**
     * Gets the list of readers for the hierarchy.
     *
     * @param hierarchyId Id of the hierarchy.
     */
    public static getHierarchyReaders = async (hierarchyId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/studio/${hierarchyId}/readers`, config);
    };

    /**
     * Sets the list of editors for the hierarchy.
     *
     * @param hierarchyId Defines the hierarchy id.
     * @param userIds the user ids to set as redactors
     */
    public static setHierarchyEditors = async (hierarchyId: string, userIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/studio/${hierarchyId}/editors`, { userIds: userIds }, config);
    };

    /**
     * Gets the list of admins for the hierarchy.
     *
     * @param hierarchyId Defines the unique identifier of the hierarchy.
     */
    public static getHierarchyAdmins = async (hierarchyId: string): Promise<AxiosResponse<string[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<string[]>(`/api/studio/${hierarchyId}/administrators`, config);
    };

    /**
     * Get the workflow overview for a studio hierarchy.
     *
     * @param hierarchyId The id of the studio hierarchy.
     */
    public static getWorkflowOverview = async (studioHierarchyId: string): Promise<AxiosResponse<Domain.Studio.WokflowOverview>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Studio.WokflowOverview>(`/api/studio/${studioHierarchyId}/workflow/overview`, config);
    };

    /**
     * Get the workflow states for the hierarchy.
     *
     * @param hierarchyId The id of the studio hierarchy.
     */
    public static getHierarchyWorkflowStates = async (studioHierarchyId: string): Promise<AxiosResponse<Domain.Dto.Shared.StudioHierarchyWorkflowState[]>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.get<Domain.Dto.Shared.StudioHierarchyWorkflowState[]>(`/api/studio/${studioHierarchyId}/workflow/states`, config);
    };

    /**
     * Sets the hierarchy workflow state to a new forward value.
     *
     * @param studioHierarchyId The id of the studio hierarchy.
     * @param workflowStateId The id of the new workflow state.
     *
     */
    public static setHierachyWorkflowState = async (studioHierarchyId: string, workflowStateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/studio/${studioHierarchyId}/workflow/move-forward-to`, { workflowStateId }, config);
    };

    /**
     * Sets the hierarchy workflow state for an single item to a new forward value.
     *
     * @param studioHierarchyId The id of the studio hierarchy.
     * @param itemId The id of the studio hierarchy item.
     * @param workflowStateId The id of the new workflow state.
     *
     */
    public static setHierachyItemWorkflowState = async (studioHierarchyId: string, itemId: string, workflowStateId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/studio/${studioHierarchyId}/item/${itemId}/move-forward-to`, { workflowStateId }, config);
    };

    /**
     * Sets the list of readers for the hierarchy.
     *
     * @param hierarchyId Defines the hierarchy id.
     * @param userIds the user ids to set as readers
     */
    public static setHierarchyReaders = async (hierarchyId: string, userIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/studio/${hierarchyId}/readers`, { userIds: userIds }, config);
    };

    /**
     * Sets the list of admins for the hierarchy.
     *
     * @param hierarchyId Defines the unique identifier of the hierarchy.
     * @param userIds A list of unique identifiers for users to be set as admins.
     */
    public static setHierarchyAdmins = async (hierarchyId: string, userIds: string[]): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();

        return HttpClient.put<void>(`/api/studio/${hierarchyId}/administrators`, { userIds: userIds }, config);
    };

    /**
     * Sets the hierarchy workflow to a new value.
     *
     * @param hierarchyId Defines the unique identifier of the hierarchy.
     * @param workflowDefinitionId Defines the unique identifier of the workflow definition.
     *
     */
    public static setHierarchyWorkflow = async (hierarchyId: string, workflowDefinitionId: string): Promise<AxiosResponse<void>> => {
        const config = await DataAccessHostUtils.getPubRequestConfig();
        return HttpClient.post<void>(`/api/studio/${hierarchyId}/workflow`, { workflowDefinitionId: workflowDefinitionId }, config);
    };

}

export default HierarchyDataAccessor;
