import React, { JSX, useRef } from 'react';
import styled from 'styled-components';
import { useComponentBounds } from '@liasincontrol/ui-basics';

export const ImageFocusViewer = <ComponentType extends AsComponentType>({
    as,
    src,
    alt,
    dimensions,
    point,
    style = {},
    ...propsAs
}: As<
    ComponentType,
    {
        src: string;
        alt?: string;
        dimensions: [number, number];
        point: [number, number, number];
        style?: React.CSSProperties;
    }
>) => {
    const container = useRef<HTMLElement>(null);
    const { width, height } = useComponentBounds(container);

    return (
        <Container
            role="img"
            aria-label={alt}
            {...propsAs}
            as={as}
            ref={container}
            style={{ ...style, ...imageFocusViewerStyle({ src, dimensions, point, containerSize: [width, height] }) }}
        />
    );
};

export const imageFocusViewerStyle = ({
    src,
    point,
    dimensions,
    containerSize,
}: {
    src: string;
    point: [number, number, number];
    dimensions: [number, number];
    containerSize: [number, number];
}) => {
    const userScale = constrain(point[2], 1, 50);
    const scaleToCover = scale({ image: dimensions, window: containerSize });
    const availableX = dimensions[0] * scaleToCover * userScale - containerSize[0];
    const availableY = dimensions[1] * scaleToCover * userScale - containerSize[1];

    const x = constrain(availableX * constrain(point[0], 0, 1), 0, dimensions[0] * scaleToCover * userScale);
    const y = constrain(availableY * constrain(point[1], 0, 1), 0, dimensions[1] * scaleToCover * userScale);

    const width = dimensions[0] * scaleToCover * userScale;
    const height = dimensions[1] * scaleToCover * userScale;

    const containerStyle = {
        backgroundImage: `url(${src})`,
        backgroundSize: `${width}px ${height}px`,
        backgroundPosition: `${-x}px ${-y}px`,
    };

    return containerStyle;
};


export const constrain = (val: number, min: number, max: number) => (val < min ? min : val > max ? max : val);
export const scale = ({ image, window }: { image: [number, number]; window: [number, number] }) => {
    const xs = window[0] / image[0];
    const ys = window[1] / image[1];
    if (xs > ys) {
        return xs;
    } else {
        return ys;
    }
};

const Container = styled.div`` as React.ComponentType<any> /* TypeScript 3.7, `as` prop defined as never in styled components */;

// As: https://gist.github.com/Zaibot/bb6b3193c57f5fdbc2f3fc495ea1425f
type HtmlComponentTypes = keyof JSX.IntrinsicElements;
type AsComponentType = HtmlComponentTypes | React.ComponentType<any>;

/** if one of the JSX.IntrinsicElements React Component type for that HTML element -else- the specific React Component type */
type ComponentTypeOrElement<T extends AsComponentType> = T extends HtmlComponentTypes
    ? React.ComponentType<React.ComponentProps<T>>
    : T extends React.ComponentType<any>
    ? T
    : never;

/** props of the `as` component */
type PropsOfComponentType<T> = T extends React.ComponentType<infer P> ? P : never;

/** props to be forwarded to the `as` component */
type AsPassthroughProps<T extends AsComponentType, Props> = Omit<PropsOfComponentType<ComponentTypeOrElement<T>>, keyof Props | 'as'>;
type As<T extends AsComponentType, Props> = { as?: T } & Props & AsPassthroughProps<T, Props>;
