import { createAsyncThunk } from '@reduxjs/toolkit';
import { AttachmentsHelper } from '@liasincontrol/core-service';

/**
 * Represents the data of the payload.
 */
type Data = {
    attachmentId: string;
    attachment: File;
};

const action = createAsyncThunk<Data, { attachmentId: string, fileName?: string }, {}>('/liasattachments/fetch',
    async ({ attachmentId, fileName }) => {
        const response = await AttachmentsHelper.getAttachmentInfo(attachmentId);
        const fileResponse = await fetch(response.data.urlWithSasToken, { method: 'GET' });
        if (!fileResponse.ok) {
            throw new Error('Failed to fetch file');
        }
        const blob = await fileResponse.blob();
        const attachmentFileName = fileName ? fileName : attachmentId; // Default to attachmentId if no explicit name mapping exists

        const file = new File([blob], attachmentFileName, { type: blob.type });
        return { attachmentId, attachment: file };
    }
);

export default action;
