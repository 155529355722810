import styled from 'styled-components';
import React from 'react';
import { px } from '../style/pixel';
import { cssBreakpoint } from '../responsive/css';

export const WrapperSubNav = ({ children }: { children: React.ReactNode }) => <StyledContainer><>{children}</></StyledContainer>;

const StyledContainer = styled.div`
    position: relative;
    display: grid;
    grid-row: 2/-1;
    grid-column: 2;
    width: ${px(280)};
    ${cssBreakpoint(`xs`, `s`)`width: ${px(48)};`}
`;
