import { JSX, useState } from "react";
import * as Domain from "@liasincontrol/domain";
import { Text } from "@liasincontrol/ui-basics";
import { SelectElement } from "@liasincontrol/ui-elements";
import { LsModal } from "@liasincontrol/ui-devextreme";
import { Box } from "@mui/material";

export type SelectWorkflowStateModalProps = {
  workflowStates: Domain.Publisher.TaskManagerWorkflowState[] | Domain.Dto.Shared.StudioHierarchyWorkflowState[];
  onConfirm: (newState: string) => void;
};

type Props = {
  descriptionChangeAll: string;
  descriptionChangeOne: string;
  singleItem?: boolean;
  onCancel: () => void;
} & SelectWorkflowStateModalProps;

export const SelectWorkflowStateModal = ({ workflowStates, descriptionChangeAll, descriptionChangeOne, onConfirm, onCancel, singleItem }: Props) => {
  const [selectedState, setSelectedState] = useState<Domain.Publisher.TaskManagerWorkflowState | Domain.Dto.Shared.StudioHierarchyWorkflowState>();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);

  const changeStateConfirmDialog: JSX.Element = (
    <LsModal
      id="modal-confirm-state-change"
      title="Nieuwe workflowstatus bevestigen"
      toolbar={{
        enabled: true,
        leftButtonText: "Annuleren",
        onLeftButtonClick: () => {
          setShowConfirmationDialog(false);
          onCancel();
        },
        rightButtonText: "BEVESTIGEN",
        onRightButtonClick: () => onConfirm(selectedState.id),
        rightButtonDisabled: !selectedState,
      }}
    >
      <Text value="Weet u zeker dat u de status van de workflow wilt veranderen? Schrijvers in deze workflowstatus krijgen een actieve taak." />
    </LsModal>
  );

  return (
    <>
      <LsModal
        id="modal-set-workflow-state"
        title="Workflowstatus wijzigen"
        toolbar={{
          enabled: true,
          leftButtonText: "Annuleren",
          onLeftButtonClick: onCancel,
          rightButtonText: "WIJZIGEN",
          onRightButtonClick: () => {
            if (!singleItem) setShowConfirmationDialog(true);
            else onConfirm(selectedState.id);
          },
          rightButtonDisabled: !selectedState,
        }}
      >
        <Box marginBottom={"1rem"}>{singleItem ? <Text value={descriptionChangeOne} /> : <Text value={descriptionChangeAll} />}</Box>
        <SelectElement<Domain.Publisher.TaskManagerWorkflowState | Domain.Dto.Shared.StudioHierarchyWorkflowState>
          id="select-new-state"
          label="Workflowstatus"
          displayExpr="name"
          optionItems={workflowStates}
          value={selectedState}
          clearable={false}
          searchable={false}
          editorSettings={{
            disabled: false,
            restrictions: { required: true },
            validationErrors: [],
            onChange: setSelectedState,
          }}
        />
      </LsModal>
      {showConfirmationDialog && changeStateConfirmDialog}
    </>
  );
};
