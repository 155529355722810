import React, { useState } from 'react';
import * as DxPieChart from 'devextreme-react/pie-chart';
import DataGrid from 'devextreme-react/data-grid';

import {
    IChartTooltip,
    IChartTitle,
    IChartLoadingIndicator,
    getLiasPalette,
    ChartLabels,
    ChartWrapper,
} from '../shared';

import { ChartCenterTemplate } from './chartCenterTemplate'
import { IMargin } from '../shared';
import { LsdxPieChart, LsdxElement } from '../../..';
import { defaultDoughnutChartSettings } from './defaults';
import { ChartPanel, Views } from '../shared/chartPanel';
import { CreateCustomDataSource } from '../../helper/datasource';

export type CombinedSlices = {
    maxSlices?: number;
    label?: string;
}

/**
 * Defines the props of the PieChart component.
 */
export interface ILsPieChartProps {
    readonly adaptiveLayout?: {
        height?: number,
        keepLabels?: boolean,
        width?: number,
    },
    readonly size?: {
        width?: number;
        height?: number;
    };
    readonly palette?: string | string[];
    readonly startAngle?: number;

    readonly redrawOnResize?: boolean;
    readonly innerRadius?: number;
    readonly chartTitle?: string;

    readonly pieChartData: DxPieChart.ISeriesProps;
    readonly animationData?: DxPieChart.IAnimationProps;
    readonly legendData?: DxPieChart.ILegendProps;

    readonly tooltipData?: IChartTooltip;
    readonly titleData?: IChartTitle;
    readonly loadingIndicator?: IChartLoadingIndicator;
    readonly margin?: IMargin,

    readonly minDiameter?: number,
    readonly type: 'donut' | 'doughnut' | 'pie',
    readonly labels: ChartLabels
    /**
     * Defines the piechart series data collection.
     */
    readonly data: [];

    /**
     * Defines the filter object. 
     */
    readonly filterValue?: string | Array<any> | Function;

    readonly centerText?: string;

    readonly onInitialized?: (e: { component?: LsdxPieChart | undefined; element?: LsdxElement | undefined; }) => any,

    readonly textColor?: string;

    readonly textFontSize?: string;

    readonly primaryColor?: string;

    readonly primaryTextColor?: string;

    readonly combinedSlices?: CombinedSlices;
}

export const LsPieChart = (props: ILsPieChartProps) => {
    const [view, setView] = useState<Views>(Views.Chart);
    const chartProps = { ...defaultDoughnutChartSettings, ...props };

    const usedPalette = (props.palette && Array.isArray(props.palette))
        ? props.palette
        : getLiasPalette(chartProps.palette as string, chartProps.primaryColor).simpleSet;

    const dataSource = CreateCustomDataSource(props.data, undefined, undefined, props.filterValue, undefined, true);
    const columns = [{
        dataField: chartProps.pieChartData.argumentField,
        key: chartProps.pieChartData.argumentField,
        minWidth: 32,
        encodeHtml: false,
    },
    {
        dataField: chartProps.pieChartData.valueField,
        key: chartProps.pieChartData.valueField,
        minWidth: 32,
        encodeHtml: false,
        format: chartProps.pieChartData.label.format,
    }];

    return (<ChartWrapper $primaryColor={chartProps.primaryColor} $primaryTextColor={chartProps.primaryTextColor}>
        <ChartPanel view={view} onSwitch={setView} />
        {view === Views.Chart &&
            <DxPieChart.PieChart
                type={chartProps.type}
                title={chartProps.titleData}
                palette={usedPalette}
                paletteExtensionMode='extrapolate'
                dataSource={dataSource}
                innerRadius={chartProps.innerRadius}
                redrawOnResize={chartProps.redrawOnResize}
                centerRender={(pieChart) => props.type !== 'pie' ? ChartCenterTemplate(pieChart, chartProps.centerText, chartProps.textColor, chartProps.textFontSize) : null}
                onInitialized={chartProps.onInitialized}
                resolveLabelOverlapping='shift'

            >
                <DxPieChart.Legend {...chartProps.legendData} />
                <DxPieChart.Animation {...chartProps.animationData} />
                <DxPieChart.Series {...chartProps.pieChartData}>
                    {chartProps?.combinedSlices?.maxSlices &&
                        <DxPieChart.SmallValuesGrouping mode='topN' topCount={chartProps.combinedSlices.maxSlices} groupName={chartProps.combinedSlices.label} />
                    }
                </DxPieChart.Series>
                <DxPieChart.Tooltip {...chartProps.tooltipData} zIndex={2000} />
                <DxPieChart.Size {...chartProps.size} />
            </DxPieChart.PieChart>
        }
        {view === Views.Table &&
            <DataGrid
                dataSource={dataSource}
                noDataText='Geen gegevens beschikbaar'
                columnAutoWidth={true}
                showBorders={true}
                wordWrapEnabled={true}
                repaintChangesOnly={true}
                allowColumnReordering={false}
                columns={columns}
                paging={{ enabled: false }}
            />
        }
    </ChartWrapper>);
}
