import React, { useState, useEffect } from 'react';
import TextBox from "devextreme-react/text-box";
import { useNavigate } from 'react-router-dom';
import * as Domain from '@liasincontrol/domain';
import { FlexBox, IDataItemProps, Icon, IconSize, IconValue, SVGIcon } from '@liasincontrol/ui-basics';
import { Performance } from '@liasincontrol/data-service';
import { LsGrid, GridColumn } from '@liasincontrol/ui-devextreme';
import { FieldsHelper, IconHelper, isMomentOpen, useUserSettings } from '@liasincontrol/core-service';
import { IconHelper as PerformanceIconHelper } from '../../../helpers/IconHelper';
import Styled from './index.style';
import { Section, SelectElement, SelectElementTemplateProps, } from '@liasincontrol/ui-elements';
import { ActionSource, AjaxRequestStatus, useElementDefinitions, useIcons, useMeasureMoments, useWorkflowTemplates } from '@liasincontrol/redux-service';

const availableStatuses: string[] = [
    Domain.SystemFieldDefinitions.Performance.GoalStatus,
    Domain.SystemFieldDefinitions.Performance.AchievementStatus,
    Domain.SystemFieldDefinitions.Performance.ActivityStatus
];

/**
 * Defines the props of the performance task list component.
 */
type Props = { performanceModuleId: string; };

type HierarchyTask = Domain.Performance.HierarchyItemElement & { progress: Domain.Shared.FieldDefinitionOptionItem, workflowState: Domain.Shared.WorkflowTemplateState };

/**
 * Represents a UI component that renders the users performance task list.
 */
export const PerformanceTaskList: React.FC<Props> = (props) => {
    const icons = useIcons();
    const { measureMoments } = useMeasureMoments();
    const { elementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: props.performanceModuleId });
    const { workflowTemplates } = useWorkflowTemplates();
    const userSettingsContext = useUserSettings();
    const [hierarchyTasks, setHierarchyTasks] = useState<HierarchyTask[]>([]);

    const [localMeasureMoments, setLocalMeasureMoments] = useState<{
        values: Domain.Shared.MomentItem[],
        selectedMeasureMoment: Domain.Shared.MomentItem
    }>({ values: [], selectedMeasureMoment: undefined });
    const navigate = useNavigate();

    useEffect(() => {
        if (!measureMoments || measureMoments.status === AjaxRequestStatus.NotSet) {
            return;
        }

        const filteredMoments = measureMoments.items.filter((moment) => isMomentOpen(moment.status));
        const moments = filteredMoments?.map((moment) => ({
            value: moment.id,
            label: moment.name,
            closed: moment.status === Domain.Shared.MeasureMomentStatus.Closed
        }));

        // Get the context measure moment or the first opened measure moment as default.
        const defaultMoment: Domain.Shared.MeasureMoment = userSettingsContext.measureMomentId
            ? filteredMoments.find((moment) => moment.id === userSettingsContext.measureMomentId)
            : filteredMoments.reduce((min, momentObj) => (min?.order < momentObj.order ? min : momentObj), undefined);

        setLocalMeasureMoments({
            values: moments,
            selectedMeasureMoment: defaultMoment
                ? {
                    value: defaultMoment.id,
                    label: defaultMoment.name,
                    closed: !isMomentOpen(defaultMoment.status),
                }
                : undefined,
        });

    }, [measureMoments, userSettingsContext.measureMomentId]);

    useEffect(() => {
        if (!localMeasureMoments || !localMeasureMoments.selectedMeasureMoment || !localMeasureMoments.selectedMeasureMoment.value
            || elementDefinitions?.status !== AjaxRequestStatus.Done
            || workflowTemplates?.status !== AjaxRequestStatus.Done) {
            return;
        }

        Performance.HierarchyDataAccessor.getAssignedTasks(localMeasureMoments.selectedMeasureMoment.value).then((result) => {
            const tasklist = result.data?.tasklist?.map((item: Domain.Performance.HierarchyTask) => {
                const elementItem = new Domain.Performance.HierarchyItemElement();
                FieldsHelper.mapObject<Domain.Performance.HierarchyItemElement>(
                    elementItem,
                    elementDefinitions.items[item.element.elementDefinitionId].fields,
                    item.element.fields);
                elementItem.id = item.element.elementId;
                elementItem.type = elementDefinitions.items[item.element.elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType;

                // Compute the progress value:
                const statusFieldDefinition = elementDefinitions.items[item.element.elementDefinitionId].fields.find(item => availableStatuses.indexOf(item.systemId) >= 0);
                const progress =
                    statusFieldDefinition?.optionItems.find((option: Domain.Shared.FieldDefinitionOptionItem) => option.id === item.element.fields[statusFieldDefinition.id]);
                // Compute the workflow value:                
                const workflowState: Domain.Shared.WorkflowTemplateState = result.data?.settings ?
                    workflowTemplates.items.find(workflow => workflow.id === getWorkflowIdByElementType(elementItem.type, result.data?.settings))?.workflowStates.find((state: Domain.Shared.WorkflowTemplateState) => state.id === item.workflowStateId) :
                    undefined;

                return { ...elementItem, progress: progress, workflowState: workflowState };
            });
            setHierarchyTasks(tasklist || []);

        });

    }, [localMeasureMoments.selectedMeasureMoment, elementDefinitions?.status, workflowTemplates?.status]);

    if (!elementDefinitions || elementDefinitions.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    const getWorkflowIdByElementType = (type: Domain.Performance.HierarchyItemElementType, settings: Domain.Performance.HierarchySettings) => {
        switch (type) {
            case Domain.SystemElementDefinitions.Performance.Policy: return settings.policyWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Goal: return settings.goalWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Achievement: return settings.achievementWorkflowProcessId;
            case Domain.SystemElementDefinitions.Performance.Activity: return settings.activityWorkflowProcessId;
            default: return undefined;
        }
    };

    const momentInputCustomOptions = (inputProps: IDataItemProps<string>, templateProps?: SelectElementTemplateProps) => {
        const moment = measureMoments.items.find(mm => inputProps?.value === mm.id);

        return (
            <Styled.SingleValueWrapper>
                {moment && <Icon value={isMomentOpen(moment.status) ? IconValue.OpenLock : IconValue.Lock} size={IconSize.small} />}
                {templateProps?.isFieldTemplate ?
                    <TextBox stylingMode='outlined' value={inputProps?.label} />
                    :
                    <Styled.SingleValueLabel>
                        {inputProps?.label}
                    </Styled.SingleValueLabel>
                }
            </Styled.SingleValueWrapper>
        );
    };

    const columns = getColumnConfiguration(icons.items, elementDefinitions.items);

    return (
        <Section look='white' grid={true} colSpan={3} rowSpan={2}>
            <Styled.TitleContainer>
                <Styled.Title>Toegewezen</Styled.Title>
                <Styled.RightBox>
                    <SelectElement<Domain.Shared.MomentItem>
                        id='performance-task-list-measure-moments'
                        optionItems={localMeasureMoments.values}
                        placeholder='Kies...'
                        displayExpr='label'
                        value={localMeasureMoments?.selectedMeasureMoment}
                        customOptions={momentInputCustomOptions}
                        customSingleValue={(item) => momentInputCustomOptions(item || localMeasureMoments.selectedMeasureMoment, { isFieldTemplate: true, placeholder: 'Kies...' })}
                        editorSettings={{
                            disabled: false,
                            onChange: (selectedItem) => {
                                userSettingsContext.setMeasureMomentId(selectedItem ? selectedItem.value : undefined);
                            }
                        }}
                    />
                </Styled.RightBox>
            </Styled.TitleContainer>
            <Styled.GridOverflow>
                <LsGrid
                    columns={columns}
                    dataSource={hierarchyTasks}
                    allowColumnResizing={true}
                    keyExpr='id'
                    showRowLines={true}
                    onClickRow={(item: HierarchyTask) => {
                        navigate(`/performance/tree/overview/${item.type}/${localMeasureMoments.selectedMeasureMoment.value}/${item.id}`);
                    }}
                />
            </Styled.GridOverflow>
        </Section>
    );
};

const getElementDefinition = (elementDefinitions: Record<string, Domain.Shared.ElementDefinition>, id: string) => {
    return Object.values(elementDefinitions).find(item => item.systemId === id);
};

const getColumnConfiguration = (icons: Record<string, Domain.Shared.SvgIcon>, elementDefinitions: Record<string, Domain.Shared.ElementDefinition>): GridColumn<HierarchyTask>[] => {
    if (!elementDefinitions) {
        return [];
    }
    return [
        {
            name: 'type',
            title: 'Titel',
            width: '50%',
            renderCustom: (item) => {
                const ed = getElementDefinition(elementDefinitions, item.data.type);
                return <FlexBox>
                    <SVGIcon value={icons[ed.icon]?.svg} size={IconSize.medium} color={ed.color} />
                    {item.data.name}
                </FlexBox>
            }
        },
        {
            name: 'number',
            title: 'Nummer',
            allowSorting: false,
            width: '10%',
        },
        {
            name: 'workflowState',
            title: 'Workflow status',
            width: '20%',
            renderCustom: ({ data }) => (
                <FlexBox>
                    {IconHelper.getWorkFlowStatusIcon(data.workflowState?.name, IconSize.small)}
                    {data.workflowState?.name || 'Nieuw'}
                </FlexBox>
            )
        },
        {
            name: 'progress',
            title: 'Voortgang',
            width: '20%',
            renderCustom: ({ data }) => (
                <FlexBox>
                    {PerformanceIconHelper.getPerformanceProgressIcon(data.progress?.name || 'Anders', IconSize.small)}
                    {data.progress?.name || 'Anders'}
                </FlexBox>
            )
        },
    ];
};
