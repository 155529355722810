import { Stack } from "@mui/material";
import { InnerProps, enhanceWithTooltip } from "../genericComponents/enhanceWithTooltip";
import { CheckBox } from "devextreme-react";

type Props = {
  dataField: string;
  caption: string;
  isBulk: boolean;
  onValueChanged: (selected: boolean, statusId: string) => void;
} & InnerProps;

const Caption = ({ dataField, caption, isBulk, onValueChanged, ...rest }: Props) => {
  return (
    <Stack direction={"row"}>
      {isBulk && <CheckBox id={`input-${dataField}`} className="mr-050" onValueChanged={(e) => onValueChanged(e.value, dataField)} />}
      <div {...rest} className="dx-treelist-text-content">
        {caption}
      </div>
    </Stack>
  );
};

export const BulkActionTableHeader = enhanceWithTooltip<Props>(Caption);
