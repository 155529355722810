import React from 'react';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export interface IOverlayProps {
  /**
   * default 10%
   */
  readonly visible?: boolean;
  /**
   * default 10%
   */
  readonly opacity?: number;
  /**
   * default 1
   */
  readonly zIndex?: number;
  /**
   * Position children using position: absolute
   */
  readonly relative?: boolean;
  readonly onClick?: React.MouseEventHandler;
  readonly children: React.ReactNode;
}

interface UnderlayProps {
  $customZIndex: number;
  $customOpacity: number;
}

interface RelativeProps {
  $customZIndex: number;
}

const underlayVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.2, delay: 0 },
    display: 'block',
    pointerEvents: 'auto' as const
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.2, delay: 0.15 },
    transitionEnd: {
      display: 'none',
      pointerEvents: 'none' as const
    }
  }
};

const relativeVariants = {
  visible: {
    opacity: 1,
    transition: { duration: 0.1, delay: 0.15 },
    display: 'block'
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.1, delay: 0 },
    transitionEnd: {
      display: 'none'
    }
  }
};

const Underlay = styled(motion.div)<UnderlayProps>`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: ${(p) => p.$customZIndex};
  background-color: ${(p) => `rgba(0, 0, 0, ${p.$customOpacity})`};
  min-width: 100vw;
  min-height: 100vh;
`;

const Relative = styled(motion.div)<RelativeProps>`
  position: absolute;
  z-index: ${(p) => p.$customZIndex};
`;

export const Overlay = ({
  children,
  onClick,
  opacity = 0.1,
  relative = false,
  visible = true,
  zIndex = 1,
}: IOverlayProps) => {
  const commonProps = {
    initial: "hidden",
    animate: visible ? "visible" : "hidden"
  };

  if (relative) {
    return (
      <>
        <Underlay
          {...commonProps}
          variants={underlayVariants}
          $customOpacity={opacity}
          $customZIndex={zIndex}
          onClick={onClick}
          role="presentation"
        />
        <Relative
          {...commonProps}
          variants={relativeVariants}
          $customZIndex={zIndex}
          role="dialog"
        >
          {children}
        </Relative>
      </>
    );
  }

  return (
    <Underlay
      {...commonProps}
      variants={underlayVariants}
      $customOpacity={opacity}
      $customZIndex={zIndex}
      onClick={onClick}
      role="dialog"
    >
      {children}
    </Underlay>
  );
};