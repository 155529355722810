import React from 'react';
import Select from 'react-select';
import { IDataItemProps, IInputSelectProps } from './Props';
// tslint:disable:no-submodule-imports
import { palette } from '../../styleguide';
// tslint:enable:no-submodule-imports

/* tslint:disable:no-magic-numbers */

import './style.css';

export const InputSelect = <TValue extends string | number | boolean>({
  id,
  name,
  options,
  selectedOption,
  placeholder,
  clearable,
  searchable,
  onChange,
  isMultiSelector,
  maxTagsCount,
  disabled
}: IInputSelectProps<TValue>) => {

  const customStyles = {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? `${palette.primary2}` : `${palette.primary3}`,
      overflow: "hidden",
    }),
    valueContainer: (baseStyles) => ({
      ...baseStyles,
      display: "flex",
      flexWrap: "nowrap",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    }),
    multiValue: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: palette.primary3,
      minWidth: "50px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      position: "relative",
    }),
    multiValueLabel: (baseStyles) => ({
      ...baseStyles,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "100px", // Limit the width to prevent wrapping
      position: "relative",
    }),
    multiValueRemove: (baseStyles, state) => ({
      ...baseStyles,
      backgroundColor: state.isFocused ? `${palette.primary2}` : `${palette.primary3}`,
      ':hover': {
        backgroundColor: palette.grey3,
        color: palette.white,
      },
    }),
  };

  return (
    <Select
      name={name}
      options={options}
      closeMenuOnSelect={!isMultiSelector}
      value={selectedOption}
      placeholder={placeholder}
      isClearable={clearable}
      isSearchable={searchable}
      onChange={onChange}
      isDisabled={disabled}
      inputId={id}
      isMulti={isMultiSelector}
      isOptionDisabled={() => isMultiSelector && (selectedOption as IDataItemProps<TValue>[]).length >= maxTagsCount}
      noOptionsMessage={() => 'Niets gevonden'}
      menuPortalTarget={document.body}
      menuShouldScrollIntoView={true}
      styles={{ ...customStyles }}
      components={{
        MultiValueLabel: ({ data }) => (
          <div id={data.label} className="tooltip-container" title={data.label}>
            {data.label}
          </div>
        ),
      }}
    />
  );
};

