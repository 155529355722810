import React, { JSX } from 'react';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';

/**
 * Represents a helper that is responsible for returning an icon based on the datatype of an entity.
 */
export class IconHelper {
    /**
     * Represents a method to render the default performance's progress icon.
     * 
     * @param status Defines the progress status.
     * @param size Defines the size of the icon.
     * 
     * @returns a JSX element with the icon. 
     */

    public static getPerformanceProgressIcon = (status: string, size: number): JSX.Element => {
        switch (status) {
            case 'Anders':
                return <CircleIcon sx={{ color: "#BCBCC6", fontSize: size }} />;
            case 'Op koers':
                return <CircleIcon sx={{ color: "#68A350", fontSize: size }} />;
            case 'Bijsturing':
                return <CircleIcon sx={{ color: "#FFA500", fontSize: size }} />;
            case '(Deels) Niet haalbaar':
                return <CircleIcon sx={{ color: "#FF0000", fontSize: size }} />;
            default:
                return null;
        }
    };

    /**
     * Represents a method to render the cockpit's performance's progress icon.
     * 
     * @param status Defines the progress status.
     * @param size Defines the size of the icon.
     * 
     * @returns a JSX element with the icon. 
     */
    public static getPerformanceCockpitProgressIcon = (status: string, size: number): JSX.Element => {
        switch (status) {
            case 'Anders':
                return <HelpOutlineOutlinedIcon sx={{ color: "#BCBCC6", fontSize: size }} />;
            case 'Op koers':
                return <CheckCircleOutlineOutlinedIcon sx={{ color: "#68A350", fontSize: size }} />;
            case 'Bijsturing':
                return <ErrorOutlineOutlinedIcon sx={{ color: "#FFA500", fontSize: size }} />;
            case '(Deels) Niet haalbaar':
                return <CancelOutlinedIcon sx={{ color: "#FF0000", fontSize: size }} />;
            default:
                return null;
        }
    };
}