import React from 'react';
import AuthService from '@liasincontrol/auth-service';
import { useTenant } from '@liasincontrol/redux-service';

const Index: React.FC = () => {
    const { initTenant } = useTenant();

    AuthService.getInstance()
        .then((auth) => auth.signinSilentCallback())
        .then((user) => {
            initTenant(user?.profile?.tenant_name as string);
        }).catch(error => {
            throw error;
        });

    return <></>;
};

export default Index;
