/**
 * Represents an abstract node element.
 */
export class ElementNode {

    public elementId: string;

    public children: ElementNode[] = [];

    public parentId: string;

}
