import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import _ from 'lodash';
import { IconValue } from '@liasincontrol/ui-basics';
import { SideNavigation, Look, NavGroup } from '@liasincontrol/ui-elements';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { useAnnouncement } from '@liasincontrol/redux-service';

/**
 * Represents a UI component that renders the performance menu.
 */
const Menu: React.FC<{ userIdentity: UserIdentity }> = (props) => {
    const { pathname } = useLocation();

    const announcement = useAnnouncement();

    const menuItems: NavGroup[] = [
        {
            key: 'menu-performance',
            title: 'Performance',
            active: !_.isNull(matchPath({ path: '/performance', end: true }, pathname)),
            icon: IconValue.Performance,
            to: '/performance',
            items: [],
        },
        {
            key: 'menu-performance-tree',
            active: !_.isNull(matchPath('/performance/tree/*', pathname)),
            title: 'Doelenboom',
            to: '/performance/tree/overview',
            icon: IconValue.Hierarchy_filled,
            items: [
                {
                    key: 'menu-performance-tree-overview',
                    active: !_.isNull(matchPath('/performance/tree/overview', pathname)),
                    title: 'Doelenboom',
                    alt: 'Open doelenboom',
                    to: '/performance/tree/overview',
                    icon: IconValue.Hierarchy_empty,
                },
                {
                    key: 'menu-performance-tree-monitor',
                    active: !_.isNull(matchPath('/performance/tree/monitor', pathname)),
                    title: 'Monitor',
                    alt: 'Prestatiemonitor openen',
                    to: '/performance/tree/monitor',
                    icon: IconValue.Monitor,
                }
            ],
        },
        {
            key: 'menu-performance-admin',
            active: !_.isNull(matchPath('/performance/admin/*', pathname)),
            title: 'Beheer',
            to: '/performance/admin/entities',
            icon: IconValue.Settings,
            hidden: !(props.userIdentity && UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_ManagePerformanceElementDefinition, ActionType.Update)),
            items: [
                {
                    key: 'menu-performance-admin-entities',
                    active: !_.isNull(matchPath('/performance/admin/entities', pathname)),
                    title: 'Entiteiten',
                    alt: 'Open entiteiten',
                    to: '/performance/admin/entities',
                    icon: IconValue.PerformanceEntities,
                }
            ],
        }
    ];

    return (
        <SideNavigation look={Look.performance} alt='Zijnavigatiemenu' menuItems={menuItems} announcement={announcement.items?.message} />
    );
};

export { Menu };
