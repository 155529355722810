import { palette, px } from '@liasincontrol/ui-basics';
import { styled } from 'styled-components';

const NumberOfTasksWrapper = styled.div`
    display: flex; 
    justify-content: right;
    align-items: center;
    background-color: ${palette.grey4};
    width: 100%;
    font-weight: 600;
`;

const MeasureMomentName = styled.div`
    background-color: #F2F2F2;
    border: 1px solid #F2F2F2;
    width: fit-content; 
    max-width: 100%;
    border-radius: 2px; 
    padding: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const TaskName = styled.span`
    margin-left: 5px;
    font-weight: 600; 
    text-overflow: ellipsis;
    overflow: hidden;
`;


type CardProps = {
    $backgroundColor: string
}

const Card = styled.div<CardProps>`
    background-color: ${p => p.$backgroundColor};
    border-radius: 10px;
    width: ${px(180)};
    height: ${px(130)};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const TaskListsWrapper = styled.div`
    height: 80%;
    max-height: 80vh;
    overflow-y: auto; 
    margin-top: 10px;
    padding-bottom: 50px;
    @supports (scrollbar-width: thin) {
        scrollbar-width: thin; /* Reduces the width of the scrollbar */
        padding-right: 10px; /* Prevents overlap */
    }
`;

const WidgetTitle = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
`;

const styledComponents = { NumberOfTasksWrapper, MeasureMomentName, TaskName, Card, TaskListsWrapper, WidgetTitle };

export default styledComponents;