import { useSelector, useDispatch } from "react-redux";
import { State, AjaxRequestStatus, WorkflowTemplateActionCreator, RootState } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

type WorkflowTemplatesType = {
    items: Domain.Shared.WorkflowTemplateWithStates[],
    status: AjaxRequestStatus,
}

export const useWorkflowTemplates = () => {
    const workflowTemplates = useSelector<State, WorkflowTemplatesType>((state) => state.workflowtemplates);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    if (workflowTemplates.status === AjaxRequestStatus.NotSet) {
        dispatch(WorkflowTemplateActionCreator.set());
    }

    const fetchWorkflowTemplates = () => {
        dispatch(WorkflowTemplateActionCreator.set());
    }

    return { workflowTemplates, fetchWorkflowTemplates };
}
