import { px } from '../style';
import styled from 'styled-components';
import { motion } from 'framer-motion';

export type DevicePosition = 'left' | 'center';


const DeviceWrapper = styled(motion.div)`
  zoom: 80%;
  -moz-transform: scale(0.8);
  -moz-transform-origin: 0 0;
  display: grid;
  grid-template-rows: ${px(16)} 1fr 80px;
  grid-template-columns: minmax(auto, ${px(1200)}) minmax(${px(80)}, auto);
`;

const DeviceContent = styled(motion.div)`
  grid-column: 1;
  grid-row: 2;
  align-self: center;
  justify-self: center;
  overflow: auto;
  position: relative;
  z-index: 0;
  transform: translate(0);
`;

const Frame = styled(motion.div) <{ $position: DevicePosition }>`
  grid-column: ${(props) => (props.$position === 'center' ? 2 : 1)};
  grid-row: 2;
  align-self: center;
  justify-self: center;
  background: #fff;
  border-radius: 52px;
  box-shadow: 0 23px 26px rgba(0, 0, 0, 0.16);
  position: relative;
`;

const DeviceFrameButton = styled(motion.div)`
  background: #fff;
  border: solid 1px #5d5d5d;
  border-radius: 50%;
  position: absolute;
`;

const DeviceFrameCamera = styled(motion.div)`
  background: #fff;
  border: solid 1px #5d5d5d;
  border-radius: 50%;
  position: absolute;
`;

const DeviceFrameSpeaker = styled(motion.div)`
  background: #fff;
  border: solid 1px #5d5d5d;
  border-radius: 20px;
  position: absolute;
`;

const DesktopWrapper = styled.div`
    width: 1200px;
    height: 100wh;
`;

const PhoneWrapper = styled.div`
    width: 375px;
    height: 667px;
`;

const ScaledTabletWrapper = styled.div`
    zoom: 125%;
    -moz-transform: scale(1.25);
    -moz-transform-origin: 0 0;
    width: 819px;
    height: 614px;
`;

const styledComponents = {
  Frame,
  DeviceFrameButton,
  DeviceFrameCamera,
  DeviceFrameSpeaker,
  DeviceWrapper,
  DeviceContent,
  DesktopWrapper,
  ScaledTabletWrapper,
  PhoneWrapper,
};
export default styledComponents;
