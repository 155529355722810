import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, RootState, ActionSource, ElementDefinitionsActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { useEffect } from 'react';

type ElementDefinitionsType = {
    items: { [id: string]: Domain.Shared.ElementDefinition, }
    status: AjaxRequestStatus,
    includeFieldDefinitions: boolean,
    includeDetailCards: boolean,
    includeDeleted: boolean,
}

export const useElementDefinitions = (source: ActionSource, data: { moduleId: string, includeDetailCards?: boolean, includeDeleted?: boolean }) => {

    const elementDefinitions = useSelector<State, ElementDefinitionsType>((state) => state.elementdefinitions[source]);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    const fetchElementDefinitions = (source: ActionSource, data: { moduleId: string, includeDetailCards?: boolean, includeDeleted?: boolean }) => {
        dispatch(ElementDefinitionsActionCreator.set({ source: source, data: data }));
    };

    /*
    useEffect for conditional dispatching:

    In both useModules and useElementDefinitions, the dispatch logic has been moved into a useEffect. This way, the action will only be dispatched when necessary (i.e., when the state is missing or failed).
    The dependencies in useEffect (modules, elementDefinitions, and source) ensure that the effect runs only when these values change.

    Avoiding unnecessary re-renders:
    By using useEffect, the dispatching only happens when the relevant conditions are met, avoiding unnecessary re-renders.

    fetchElementDefinitions inside useEffect:
    The function fetchElementDefinitions is called only if the conditions for needing the element definitions are met.
    */
    useEffect(() => {
        if (data?.moduleId && (!elementDefinitions || elementDefinitions.status === AjaxRequestStatus.NotSet)) {
            fetchElementDefinitions(source, data);
        }
    }, [data?.moduleId, elementDefinitions, source, dispatch]);

    return { elementDefinitions, fetchElementDefinitions };
}