import { Button, GridColumn } from "@liasincontrol/ui-devextreme";
import * as Domain from '@liasincontrol/domain';
import Styled from './index.styled';
import { Icon, IconSize, IconValue, palette } from "@liasincontrol/ui-basics";

export type Module = "Finance" | "Studio" | "Publisher" | "Risk";

export const getPubTasksColumnConfiguration = (): GridColumn<Domain.Dto.Shared.PublicationTaskSummary>[] => {
    return [
        {
            name: 'publicationName',
            title: "Publisher",
            dataType: 'string',
            width: '50%',
            renderCustom: PublisherTaskName
        },
        {
            name: 'measureMomentName',
            title: '',
            renderCustom: MeasureMomentName
        },
        {
            name: 'numberOfTasks',
            title: '',
            width: '90px',
            renderCustom: NumberOfTasks
        }
    ];
};

export const getFinTasksColumnConfiguration = (): GridColumn<Domain.Dto.Shared.FinanceTaskSummary>[] => {
    return [
        {
            name: 'measureMomentId',
            title: "Finance",
            width: '50%',
            renderCustom: (item) => {
                return (
                    <div style={{ display: 'flex'}}>
                        <Icon size={IconSize.small} color={palette.darkGreen} displayMode='block' value={IconValue.Finance}/>
                        <span style={{ marginLeft: '5px'}}>Journalen</span>
                    </div>
                )
            },
        },
        {
            name: 'measureMomentName',
            title: '',
            renderCustom: MeasureMomentName
        },
        {
            name: 'numberOfTasks',
            title: '',
            width: '90px',
            renderCustom: NumberOfTasks
        }
    ];
};

export const getStudioTasksColumnConfiguration = (): GridColumn<Domain.Dto.Shared.StudioTaskSummary>[] => {
    return [
        {
            name: 'hierarchyDefinitionName',
            title: "Studio",
            width: '50%',
            renderCustom: StudioTaskName
        },
        {
            name: 'measureMomentName',
            title: '',
            renderCustom: MeasureMomentName
        },
        {
            name: 'numberOfTasks',
            title: '',
            width: '90px',
            renderCustom: NumberOfTasks
        }
    ];
};

const PublisherTaskName = (item) => {
    return (
        <div style={{ display: 'flex'}}>
            <Icon size={IconSize.small} color={palette.primary1c} displayMode='block' value={IconValue.Publisher}/>
            <Styled.TaskName title={item.value}>{item.value}</Styled.TaskName>
        </div>
    );
};

const StudioTaskName = (item) => {
    return (
        <div style={{ display: 'flex'}}>
            <Icon size={IconSize.small} color={palette.darkBrown} displayMode='block' value={IconValue.Studio}/>
            <Styled.TaskName title={item.value}>{item.value}</Styled.TaskName>
        </div>
    );
};

const MeasureMomentName = (item) => {
    return (
        <Styled.MeasureMomentName title={item.value}>
            {item.value}
        </Styled.MeasureMomentName>
    );
};

const NumberOfTasks = (item) => {
    return (
        <Styled.NumberOfTasksWrapper>
            <span>{item.value}</span>
            <Button icon="arrowright" stylingMode="text"/>
        </Styled.NumberOfTasksWrapper>
    );
};
