import { createReducer } from '@reduxjs/toolkit';
import * as Domain from '@liasincontrol/domain';
import { SiteDesignsActionCreator, TenantActionCreator } from '../../../../actions/creators';
import { AjaxRequestStatus } from '../../../state';

/**
 * Represents the Site Designs reducer.
 */
const reducer = createReducer<{
    [service: string]: {
        items: Domain.Publisher.SiteDesign[],
        status: AjaxRequestStatus
    }
}>({}, (builder) => {
    return (
        builder.addCase(SiteDesignsActionCreator.set.fulfilled, (state, action) => {
            state[action.payload.source] = {
                items: action.payload.data,
                status: AjaxRequestStatus.Done
            };

            return state;
        }),
        builder.addCase(SiteDesignsActionCreator.set.pending, (state, action) => {
            // Loading in progress;
            state[action.meta.arg.source] = {
                items: [],
                status: AjaxRequestStatus.Pending
            };

            return state;
        }),
        builder.addCase(SiteDesignsActionCreator.set.rejected, (state, action) => {
            state[action.meta.arg.source] = {
                items: [],
                status: AjaxRequestStatus.Failed
            };

            return state;
        }),
        builder.addCase(TenantActionCreator.initTenant, (state, action) => {
            state = {};
            return state;
        })
    );
});

export default reducer;
