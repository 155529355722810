import React, { useCallback, useEffect, useMemo, useState } from 'react';
import TextBox from "devextreme-react/text-box";
import { useParams } from 'react-router-dom';
import { Bar, Heading2, Icon, IconSize, IconValue, PageTitle, Wrapper, WrapperContent, Text, SVGIcon } from '@liasincontrol/ui-basics';
import { ErrorOverlay, Section } from '@liasincontrol/ui-elements';
import { ApiErrorReportingHelper, FormMode, ValueType, isMomentOpen, FormInfo, useUserSettings, isMomentDeleted } from '@liasincontrol/core-service';
import * as Domain from '@liasincontrol/domain';
import {
    ActionSource, AjaxRequestStatus,
    useIcons, useModules,
    useUsers,
    useWorkflowTemplates,
    useMeasureMoments,
    useElementDefinitions
} from '@liasincontrol/redux-service';
import { UserIdentity } from '@liasincontrol/auth-service';
import { UserRightsService, ActionType, Actions } from '@liasincontrol/userrights-service';
import { Performance, Shared as SharedDataAccess } from '@liasincontrol/data-service';
import { SystemModuleDefinitions } from '@liasincontrol/domain';
import { SelectElement, SelectElementTemplateProps } from '@liasincontrol/ui-elements';
import Styled from './index.styled';
import { HierarchyItem } from './HierarchyItem';
import { HierarchyGrid } from './HierarchyGrid';
import { MissingWorkflowDialog } from './MissingWorkflowDialog';
import _ from 'lodash';
import { LsModal, LeaseWrapper, Button } from '@liasincontrol/ui-devextreme';

type Props = {
    userIdentity: UserIdentity
};

type PanelVisibilityData = {
    elementId?: string;
    parentElementId?: string;
    elementDefinitionSystemId?: string;
    isVisible: boolean;
    audit: Domain.Dto.Shared.AuditEvent[];
};

type PerformanceType = { value: Domain.Performance.HierarchyItemElementType, label: string, elementDefinitionId: string };

/**
 * Represents a UI component that renders the overview of the performance management module.
 */
export const Overview: React.FC<Props> = (props) => {
    const { selectedtype: selectedTypeParam, selectedmoment: selectedMoment, selecteditem: selectedItemParam } = useParams<{ selectedtype?: string, selectedmoment?: string, selecteditem?: string }>();
    const [types, setTypes] = useState<{
        values: PerformanceType[],
        selectedType?: PerformanceType
    }>({ values: [], selectedType: undefined });

    const [localMeasureMoments, setLocalMeasureMoments] = useState<{
        values: Domain.Shared.MomentItem[],
        selectedMeasureMoment: Domain.Shared.MomentItem
    }>({ values: [], selectedMeasureMoment: undefined });
    const [editPanelVisibility, setEditPanelVisibility] = useState<PanelVisibilityData>({ isVisible: !!selectedItemParam, elementId: selectedItemParam, elementDefinitionSystemId: selectedTypeParam, audit: [] });
    const [error, setError] = useState<Domain.Shared.ErrorInfo>(undefined);
    const [hierarchyItems, setHierarchyItems] = useState<Domain.Performance.HierarchyItem[]>();
    const [hierarchySettings, setHierarchySettings] = useState<Domain.Performance.HierarchySettings>();
    const [hierarchyRights, setHierarchyRights] = useState<Domain.Dto.Performance.HierarchyRights>();
    const [showMissingWorkflowDialog, setShowMissingWorkflowDialog] = useState<boolean>(false);
    const [elementInstance, setElementInstance] = useState<Domain.Performance.HierarchyItem>(null);
    const [leaseInfo, setLeaseInfo] = useState<Domain.Shared.AcquireLease>();
    const [showNoLeaseDialog, setShowNoLeaseDialog] = useState<{ visible: boolean, message?: string }>({ visible: false });
    const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
    const [fromMeasureMoment, setFromMeasureMoment] = useState<Domain.Shared.MomentItem>();
    const [isImportDialogSaveButtonActive, setIsImportDialogSaveButtonActive] = useState<boolean>(true);
    const [showTimeoutMessage, setShowTimeoutMessage] = useState<boolean>(false);
    const [performanceModuleId, setPerformanceModuleId] = useState(null);
    const userSettingsContext = useUserSettings();

    const icons = useIcons();
    const users = useUsers();
    const { workflowTemplates } = useWorkflowTemplates();
    const { measureMoments } = useMeasureMoments();
    const modules = useModules(ActionSource.Performance);
    const { elementDefinitions } = useElementDefinitions(ActionSource.Performance, { moduleId: performanceModuleId });

    const availableUsers = useMemo(() => {
        if (!users || users.status !== AjaxRequestStatus.Done) {
            return [];
        }

        return users.items.filter(user => user.enabled);
    }, [users]);

    const workflowDictionary = useMemo(() => {
        const workflowDictionary = {};
        if (hierarchySettings && workflowTemplates?.items?.length > 0) {
            const tryAddWorkflowDefinition = (workflowId: string, hierarchyElementType: Domain.Performance.HierarchyItemElementType) => {
                if (workflowId) {
                    const workflow = workflowTemplates.items.find(workflow => workflow.id === workflowId);
                    if (workflow) {
                        workflowDictionary[hierarchyElementType] = workflow;
                    }
                }
            };

            tryAddWorkflowDefinition(hierarchySettings.policyWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Policy);
            tryAddWorkflowDefinition(hierarchySettings.goalWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Goal);
            tryAddWorkflowDefinition(hierarchySettings.achievementWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Achievement);
            tryAddWorkflowDefinition(hierarchySettings.activityWorkflowProcessId, Domain.SystemElementDefinitions.Performance.Activity);
        }

        return workflowDictionary as Record<Domain.Performance.HierarchyItemElementType, Domain.Shared.WorkflowTemplateWithStates>;
    }, [hierarchySettings, workflowTemplates]);

    const selectedElementDefinition = useMemo(() => {
        if (!elementDefinitions?.items || !editPanelVisibility.elementDefinitionSystemId) {
            return null;
        }

        return Object.values(elementDefinitions.items).find(item => item.systemId === editPanelVisibility.elementDefinitionSystemId);
    }, [elementDefinitions, editPanelVisibility.elementDefinitionSystemId]);

    const canCreate = useMemo(() =>
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalPerformanceContributor, ActionType.Create)
        , [props.userIdentity]);

    const canEdit = useMemo(() =>
        UserRightsService.getInstance().canPerformAction(props.userIdentity, Actions.COMPLEX_GlobalPerformanceContributor, ActionType.Update)
        , [props.userIdentity]);

    useEffect(() => {
        // Check if (default) workflow is set:
        if (workflowTemplates.items.length === 0 && workflowTemplates.status === AjaxRequestStatus.Done) {
            setShowMissingWorkflowDialog(true);
        }
    }, [workflowTemplates]);

    useEffect(() => {
        if (measureMoments.status !== AjaxRequestStatus.Done) {
            return;
        }
        const availableMoments = measureMoments.items.filter((moment) => !isMomentDeleted(moment.status));
        const momentObjects = availableMoments.map((moment) => ({ value: moment.id, label: moment.name, closed: moment.status === Domain.Shared.MeasureMomentStatus.Closed }));

        const defaultMoment = userSettingsContext.measureMomentId
            ? availableMoments.find((moment) => moment.id === userSettingsContext.measureMomentId)
            : selectedMoment
                ? availableMoments.find((moment) => moment.id === selectedMoment)
                : availableMoments.find((moment) => isMomentOpen(moment.status));

        setLocalMeasureMoments({
            values: momentObjects,
            selectedMeasureMoment: defaultMoment ? {
                value: defaultMoment.id,
                label: defaultMoment.name,
                closed: !isMomentOpen(defaultMoment.status),
            } : undefined,
        });
    }, [measureMoments, userSettingsContext.measureMomentId]);

    useEffect(() => {
        if (!localMeasureMoments.selectedMeasureMoment || !localMeasureMoments.selectedMeasureMoment.value) {
            return;
        }

        fetchHierarchy(localMeasureMoments.selectedMeasureMoment.value);
    }, [localMeasureMoments.selectedMeasureMoment]);

    useEffect(() => {
        if (!elementDefinitions?.items) {
            return;
        }

        const availableTypes = Object.keys(elementDefinitions.items)
            .filter((elementDefinitionId) => performanceElementDefinitionSystemIds.includes(elementDefinitions.items[elementDefinitionId].systemId))
            .map((elementDefinitionId) => ({
                value: elementDefinitions.items[elementDefinitionId].systemId as Domain.Performance.HierarchyItemElementType,
                label: elementDefinitions.items[elementDefinitionId].name,
                elementDefinitionId: elementDefinitions.items[elementDefinitionId].id,
                icon: elementDefinitions.items[elementDefinitionId].icon,
                color: elementDefinitions.items[elementDefinitionId].color,
            }));

        setTypes({
            values: availableTypes,
            selectedType: selectedTypeParam ? availableTypes.find(item => item.value === selectedTypeParam) : undefined
        });
    }, [elementDefinitions, selectedTypeParam]);

    useEffect(() => {
        if (!selectedElementDefinition) {
            setElementInstance(null);
            setLeaseInfo(undefined);
        } else if (editPanelVisibility.elementId) {
            if (!hierarchyItems || hierarchyItems.length <= 0) return;
            if (canEdit) {
                getLease(editPanelVisibility.elementId);
            }
            Performance.HierarchyItemDataAccessor.getHierarchyItem(editPanelVisibility.elementId)
                .then((response) => {
                    const instance = hierarchyItems?.find(item => item.element.elementId === editPanelVisibility.elementId);
                    instance.element = response.data;
                    setElementInstance({ ...instance, element: response.data });
                }).catch((exception) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
                });
        } else {
            const newElement = new Domain.Performance.HierarchyItem();
            newElement.element = {
                fields: selectedElementDefinition.fields.reduce(
                    (collection, item) => ({ ...collection, [`${item.id}`]: '' }),
                    {}
                ),
                elementDefinitionId: selectedElementDefinition.id,
                elementDefinitionSystemId: selectedElementDefinition.systemId,
                elementId: undefined,
                attachments: null,
                complexFields: null
            };
            setElementInstance(newElement);
        }
    }, [selectedElementDefinition, hierarchyItems, editPanelVisibility.elementId]);

    // #region handlers
    const fetchHierarchy = (selectedMeasureMomentId: string) => {
        if (!selectedMeasureMomentId) return;
        Performance.HierarchyDataAccessor.get(selectedMeasureMomentId, true, true).then((result) => {
            setHierarchyItems(result.data.hierarchy);
            setHierarchySettings(result.data.settings);
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });

        Performance.HierarchyRightsAccessor.get(selectedMeasureMomentId).then((response) => {
            setHierarchyRights(response.data)
        }).catch((exception) => {
            setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Loading, exception));
        });
    };

    const getLease = (elementId: string) => {
        if (elementId) {
            SharedDataAccess.Leases.acquireLease(elementId)
                .then((response) => {
                    const lease: Domain.Shared.AcquireLease = response.data;
                    setLeaseInfo(lease);
                }).catch((err) => {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err));
                });
        }
    };

    const deleteLease = useCallback(() => {
        //delete lease if exist a lease, and it's owned by logged in user
        if (leaseInfo && leaseInfo?.ownerId === props.userIdentity.profile.sub) {
            SharedDataAccess.Leases.deleteLease(leaseInfo.subjectId)
                .then(() => {
                    setLeaseInfo(undefined);
                }).catch(() => null);
            return true;
        } else
            return false;
    }, [leaseInfo]);

    const onCancelChanges = (withDeleteLease = true) => {

        setEditPanelVisibility({
            isVisible: false,
            elementId: null,
            elementDefinitionSystemId: null,
            parentElementId: null,
            audit: []
        });
        if (withDeleteLease) deleteLease();
    };

    const onSaveChanges = (refreshData = true) => {
        if (!localMeasureMoments.selectedMeasureMoment?.value) return;

        // Save the changes that have been made over a performance instance:
        const instance: Domain.Performance.HierarchyItem = {
            ...elementInstance,
            parentHierarchyItemId: editPanelVisibility.parentElementId,
        };

        const cleanFields = Object.keys(instance.element.fields).reduce((collection, item) => ({ ...collection, [item]: instance.element.fields[item].toString() }), {});
        const cleanAttachments = instance.element.attachments.filter(attachment => attachment.blobId || !attachment.deleted);
        instance.element = {
            ...instance.element,
            fields: cleanFields,
            attachments: cleanAttachments,
        };

        if (editPanelVisibility.elementId) {
            if (!canEdit) {
                return;
            }

            Performance.HierarchyItemDataAccessor.update(localMeasureMoments.selectedMeasureMoment.value, instance)
                .then(() => {
                    if (refreshData) {
                        onCancelChanges(false);
                        fetchHierarchy(localMeasureMoments.selectedMeasureMoment.value);
                    }
                }).catch((exception) => {
                    const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);
                    if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceNoValidLeaseForSave) ||
                        errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser)) {
                        setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceNoValidLeaseForSave] });
                    } else {
                        onCancelChanges();
                        setError(errorInfo);
                    }
                });
            return;
        }

        Performance.HierarchyItemDataAccessor.create(localMeasureMoments.selectedMeasureMoment.value, instance)
            .then(() => {
                if (refreshData) {
                    onCancelChanges();
                    fetchHierarchy(localMeasureMoments.selectedMeasureMoment.value);
                }
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceCreateItemLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceCreateItemLeaseInvalid] });
                } else {
                    onCancelChanges();
                    setError(errorInfo);
                }
            });
    };

    const onAssignContributors = (contributors: Record<string, boolean>) => {
        if (!localMeasureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyRightsAccessor.updateContributors(localMeasureMoments.selectedMeasureMoment.value, elementInstance.element.elementId, contributors)
            .then(() => {
                fetchHierarchy(localMeasureMoments.selectedMeasureMoment.value);
            }).catch((exception) => {
                onCancelChanges();
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
            });
    };

    const onReparentEntity = (hierarchyItemId: string, parentHierarchyItemId: string) => {
        if (!localMeasureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyItemDataAccessor.reparent(localMeasureMoments.selectedMeasureMoment.value, hierarchyItemId, parentHierarchyItemId)
            .then(() => {
                fetchHierarchy(localMeasureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception));
            });
    };

    const onReorderEntities = (moveAfterItemId: string, movedItemId: string) => {
        if (!localMeasureMoments.selectedMeasureMoment?.value) return;

        Performance.HierarchyItemDataAccessor.reorderChildren(localMeasureMoments.selectedMeasureMoment.value, moveAfterItemId, movedItemId)
            .then(() => {
                fetchHierarchy(localMeasureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Saving, exception);

                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceMoveItemError) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceReorderItemLeaseInvalid)) {
                    setError({ ...errorInfo, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceReorderItemLeaseInvalid] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onDeleteEntity = (hierarchyItemId) => {
        if (!localMeasureMoments.selectedMeasureMoment?.value || !hierarchyItemId) return;
        Performance.HierarchyItemDataAccessor.delete(localMeasureMoments.selectedMeasureMoment.value, hierarchyItemId)
            .then(() => {
                fetchHierarchy(localMeasureMoments.selectedMeasureMoment?.value);
            }).catch((exception) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Deleting, exception);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser) ||
                    errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.PerformanceDeleteItemLeaseInvalid)) {
                    setShowNoLeaseDialog({ visible: true, message: Domain.Shared.ApiKnownErrorTypesMessages[Domain.Shared.ApiKnownErrorTypes.PerformanceItemIsLockedByAnotherUser] });
                } else {
                    setError(errorInfo);
                }
            });
    };

    const onEditEntity = (id) => {
        const hierarchyItem = hierarchyItems.find(item => item.element.elementId === id);

        if (!hierarchyItem) return;

        const panelVisibilityData: PanelVisibilityData = {
            isVisible: true,
            elementId: hierarchyItem.element.elementId,
            elementDefinitionSystemId: hierarchyItem.element.elementDefinitionSystemId,
            parentElementId: hierarchyItem.parentHierarchyItemId,
            audit: []
        };

        if (hierarchyItem.element.elementId && localMeasureMoments.selectedMeasureMoment?.value) {
            Performance.HierarchyItemDataAccessor.getAuditData(localMeasureMoments.selectedMeasureMoment.value, hierarchyItem.element.elementId)
                .then((response) => {
                    setEditPanelVisibility({
                        ...panelVisibilityData,
                        audit: response.data || []
                    });
                })
                .catch(() => {
                    setEditPanelVisibility(panelVisibilityData);
                });

        } else {
            setEditPanelVisibility(panelVisibilityData);
        }
    };

    const onImport = () => {
        if (!localMeasureMoments.selectedMeasureMoment?.value || !fromMeasureMoment) return;

        setIsImportDialogSaveButtonActive(false);
        Performance.HierarchyDataAccessor.import(fromMeasureMoment.value, localMeasureMoments.selectedMeasureMoment?.value)
            .then(() => {
                fetchHierarchy(localMeasureMoments.selectedMeasureMoment?.value);
            }).catch((err) => {
                const errorInfo = ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Default, err);
                if (errorInfo?.details?.type?.includes(Domain.Shared.ApiKnownErrorTypes.AxiosTimeout)) {
                    setShowTimeoutMessage(true);
                } else {
                    setError(ApiErrorReportingHelper.generateErrorInfo(ApiErrorReportingHelper.GenericMessages.Adding, err));
                }

            })
            .finally(() => {
                setIsImportDialogSaveButtonActive(true);
                setShowImportDialog(false);
            })

    };
    // #endregion

    // #region idle timer handlers
    const onActive = () => {
        getLease(editPanelVisibility.elementId);
    };

    const onIdle = () => {
        onSaveChanges(false);
    };

    const handleStillHere = () => {
        getLease(editPanelVisibility.elementId);
    };

    const handleGone = () => {
        onCancelChanges();
        if (localMeasureMoments.selectedMeasureMoment.value) {
            fetchHierarchy(localMeasureMoments.selectedMeasureMoment.value);
        }
    };

    const handleDeleteLease = () => {
        deleteLease();
    };
    // #endregion
    useEffect(() => {
        if (modules?.items?.[SystemModuleDefinitions.Performance]?.id) {
            setPerformanceModuleId(modules.items[SystemModuleDefinitions.Performance].id);
        }
    }, [modules]);

    if (!modules || modules.status === AjaxRequestStatus.NotSet ||
        !measureMoments || measureMoments.status === AjaxRequestStatus.NotSet ||
        !users || users.status === AjaxRequestStatus.NotSet ||
        !workflowTemplates || workflowTemplates.status === AjaxRequestStatus.NotSet ||
        !elementDefinitions || elementDefinitions.status === AjaxRequestStatus.NotSet) {
        return null;
    }

    const canRenderHierarchyItem = editPanelVisibility.isVisible && hierarchyItems && hierarchyRights && localMeasureMoments.selectedMeasureMoment?.value && elementInstance && selectedElementDefinition;

    // #region custom components
    const momentInputCustomOptions = (inputProps: any, templateProps?: SelectElementTemplateProps) => {
        const moment = measureMoments.items.find(moment => inputProps?.value === moment.id); // very inefficient
        return (
            <Styled.HierarchyTypeWrapper>
                {moment && <Icon value={!isMomentOpen(moment.status) ? IconValue.Lock : IconValue.OpenLock} size={IconSize.medium} />}
                {templateProps?.isFieldTemplate ?
                    <TextBox stylingMode='outlined' value={inputProps && inputProps.label} placeholder={templateProps.placeholder} />
                    :
                    <Styled.SingleValueLabel>{inputProps.label}</Styled.SingleValueLabel>
                }
            </Styled.HierarchyTypeWrapper>
        )
    };

    const typeInputCustomOptions = (customValueProps: any, templateProps?: SelectElementTemplateProps) => {
        const ed = getElementDefinition(Object.values(elementDefinitions.items), customValueProps?.value as string);
        return (<Styled.HierarchyTypeWrapper>
            {ed &&
                <SVGIcon value={icons.items?.[ed.icon]?.svg} size={IconSize.medium} color={ed.color} />
            }
            {templateProps?.isFieldTemplate ?
                <TextBox stylingMode='outlined' value={customValueProps && customValueProps.label} placeholder={templateProps?.placeholder} />
                :
                <Styled.SingleValueLabel>{customValueProps?.label}</Styled.SingleValueLabel>
            }
        </Styled.HierarchyTypeWrapper>);
    };

    const measureMomentsOptions = localMeasureMoments.values.filter((moment) => moment.value !== localMeasureMoments?.selectedMeasureMoment?.value);
    // #endregion

    return (<>
        <Wrapper>
            <WrapperContent>
                <PageTitle>
                    <Heading2>Doelenboom</Heading2>
                </PageTitle>
                <ErrorOverlay error={error?.details.title} errorDetails={error?.details} onRetry={() => fetchHierarchy(localMeasureMoments.selectedMeasureMoment?.value)} onBack={error?.canGoBack ? () => setError(undefined) : null}>
                    <Bar look='toolbar'>
                        <Bar start>
                            <Button
                                id='btn-add-new-item'
                                text='Nieuw'
                                icon='plus'
                                type='default'
                                stylingMode='text'
                                disabled={!canCreate
                                    || workflowTemplates.items.length === 0
                                    || workflowTemplates.status !== AjaxRequestStatus.Done
                                    || localMeasureMoments.selectedMeasureMoment?.closed}
                                onClick={() => {
                                    setEditPanelVisibility({
                                        isVisible: true,
                                        elementId: null,
                                        elementDefinitionSystemId: types.selectedType ? types.selectedType.value : types.values[0]?.value,
                                        parentElementId: null,
                                        audit: []
                                    })
                                }}
                            />
                            {(hierarchyItems?.length === 0 && !localMeasureMoments?.selectedMeasureMoment?.closed)
                                ? <Button
                                    text="Overnemen"
                                    id="btn-import-hierarchy"
                                    type="default"
                                    stylingMode="text"
                                    disabled={!canCreate}
                                    onClick={() => {
                                        setFromMeasureMoment(null);
                                        setShowImportDialog(true);
                                    }}
                                />
                                : null
                            }
                        </Bar>
                        <Bar end>
                            <SelectElement<PerformanceType>
                                id='select-hierarchy-item-element'
                                optionItems={types.values}
                                value={types.selectedType}
                                displayExpr='label'
                                clearable={true}
                                customOptions={(item) => typeInputCustomOptions(item)}
                                customSingleValue={(item) => typeInputCustomOptions(item || types.selectedType, { isFieldTemplate: true, placeholder: 'Alles' })}
                                editorSettings={{
                                    disabled: false,
                                    validationErrors: [],
                                    restrictions: {},
                                    onChange: (selectedItem: PerformanceType) => {
                                        setTypes((prev) => ({
                                            ...prev,
                                            selectedType: prev.values.find(item => item === selectedItem)
                                        }));
                                    }
                                }}
                            />
                            <SelectElement<Domain.Shared.MomentItem>
                                id='select-measuremoment'
                                displayExpr='label'
                                optionItems={localMeasureMoments.values}
                                placeholder='Kies...'
                                value={localMeasureMoments.selectedMeasureMoment}
                                customOptions={momentInputCustomOptions}
                                customSingleValue={(item) => momentInputCustomOptions(item || localMeasureMoments.selectedMeasureMoment, { isFieldTemplate: true, placeholder: 'Kies...' })}
                                editorSettings={{
                                    onChange: (selectedItem) => {
                                        userSettingsContext.setMeasureMomentId(selectedItem ? selectedItem.value : undefined);
                                    }
                                }}
                            />
                        </Bar>
                    </Bar>
                    <Section look='white'>
                        <HierarchyGrid
                            userIdentity={props.userIdentity}
                            performanceModuleId={performanceModuleId}
                            hierarchyItems={hierarchyItems}
                            selectedType={(types.selectedType ? types.selectedType.value : types.values[0]?.value)}
                            isMeasureMomentClosed={localMeasureMoments.selectedMeasureMoment?.closed}
                            workflowDictionary={workflowDictionary}
                            availableUsers={availableUsers}
                            allowReordering={!types.selectedType || types.selectedType.value === Domain.SystemElementDefinitions.Performance.Policy}
                            onCreateEntity={(type: Domain.Performance.HierarchyItemElementType, parentHierarchyItemId: string) => {
                                setEditPanelVisibility({
                                    isVisible: true,
                                    elementDefinitionSystemId: type,
                                    parentElementId: parentHierarchyItemId,
                                    elementId: null,
                                    audit: []
                                });
                            }}
                            onReparentEntity={onReparentEntity}
                            onReorderEntities={onReorderEntities}
                            onDeleteEntity={onDeleteEntity}
                            onEditEntity={onEditEntity}
                        />
                    </Section>
                </ErrorOverlay>
            </WrapperContent>
        </Wrapper>

        {showMissingWorkflowDialog && <MissingWorkflowDialog onClose={() => setShowMissingWorkflowDialog(false)} />}
        {/* Lease makes no sense when you view item or create a new item. So it will be enabled only if one can edit the selected item */}
        {canRenderHierarchyItem &&
            <HierarchyComponent
                canEditHierarchyItem={canEdit}
                fields={elementInstance.element.fields}
                complexFields={elementInstance.element.complexFields}
                attachments={elementInstance.element.attachments}
                isMeasureMomentClosed={localMeasureMoments.selectedMeasureMoment?.closed}
                audit={editPanelVisibility.audit}
                users={availableUsers}
                workflowTemplate={(elementInstance.element.elementId) ? workflowDictionary[selectedElementDefinition.systemId] : null}
                workflowState={(elementInstance.element.elementId) ? {
                    workflowState: elementInstance.workflowStateId,
                    assignedUser: elementInstance.assignedUserId,
                    workflow: workflowDictionary[selectedElementDefinition.systemId]?.id
                } : null}
                elementDefinition={selectedElementDefinition}
                hierarchyRights={hierarchyRights.hierarchyRights[elementInstance.element.elementId]}
                elementId={elementInstance.element.elementId}
                onAssignContributors={onAssignContributors}
                onSaveChanges={onSaveChanges}
                onCancelChanges={onCancelChanges}
                deleteLease={deleteLease}
                handleDeleteLease={handleDeleteLease}
                handleGone={handleGone}
                handleStillHere={handleStillHere}
                onIdle={onIdle}
                onActive={onActive}
                userIdentity={props.userIdentity}
                workflowDictionary={workflowDictionary}
                onFieldsDataChanged={(fields: FormInfo<ValueType>) => {
                    setElementInstance((prev) => {
                        const newInstance = _.cloneDeep(prev);
                        newInstance.element = {
                            ...prev.element,
                            fields: fields.values,
                            complexFields: fields.complex,
                            attachments: fields.attachments,
                        };
                        return newInstance;
                    });
                }}
                onWorkflowStateChanged={(workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => {
                    setElementInstance((prev) => {
                        const newInstance = _.cloneDeep(prev);
                        newInstance.assignedUserId = workflowStateValue?.assignedUser;
                        newInstance.workflowStateId = workflowStateValue?.workflowState;
                        return newInstance;
                    });
                }}
                leaseInfo={leaseInfo}
            />}

        {showNoLeaseDialog.visible &&
            <LsModal
                id='modal-no-lease-dialog'
                toolbar={{
                    enabled: true,
                    rightButtonText: 'Sluiten',
                    onRightButtonClick: () => setShowNoLeaseDialog({ visible: false }),
                }}
            >
                <Text value={showNoLeaseDialog.message} />
            </LsModal>
        }

        {showImportDialog &&
            <LsModal
                id='modal-import-dialog'
                title='Hiërarchieën overnemen'
                toolbar={{
                    enabled: true,
                    rightButtonText: 'Aanmaken',
                    leftButtonText: 'Annuleren',
                    rightButtonDisabled: !fromMeasureMoment || !isImportDialogSaveButtonActive,
                    onRightButtonClick: onImport,
                    onLeftButtonClick: () => setShowImportDialog(false),
                }}
            >
                <SelectElement<Domain.Shared.MomentItem>
                    id='input-moment'
                    label='Moment'
                    optionItems={measureMomentsOptions}
                    value={fromMeasureMoment}
                    displayExpr='label'
                    clearable={true}
                    searchable={true}
                    customOptions={(item) => momentInputCustomOptions(item)}
                    customSingleValue={(item) => momentInputCustomOptions(item, { isFieldTemplate: true, placeholder: 'Kies...' })}
                    editorSettings={{
                        disabled: false,
                        restrictions: { required: true },
                        validationErrors: [],
                        onChange: setFromMeasureMoment
                    }}
                />
            </LsModal>
        }

        {showTimeoutMessage &&
            <LsModal
                id='modal-timeout-message'
                title='Kopie maken'
                toolbar={{
                    enabled: true,
                    rightButtonText: 'Sluiten',
                    onRightButtonClick: () => setShowTimeoutMessage(false),
                }}
            >
                <Text value='Kopieren duurt wat langer dan verwacht.' />
            </LsModal>
        }
    </>);
};

//separate?
type HierarchyComponentProps = {
    canEditHierarchyItem: boolean;
    complexFields: Domain.Shared.ComplexField[],
    hierarchyRights: Domain.Performance.HierarchyItemRight[],
    leaseInfo: Domain.Shared.AcquireLease;
    userIdentity: UserIdentity;
    audit: Domain.Dto.Shared.AuditEvent[];
    users: Domain.Shared.User[];
    isMeasureMomentClosed: boolean;
    workflowDictionary: Record<string, Domain.Shared.WorkflowTemplateWithStates>;
    elementDefinition: Domain.Shared.ElementDefinition;
    fields: Record<string, string>;
    attachments: Domain.Shared.Attachment[],
    workflowTemplate: Domain.Shared.WorkflowTemplateWithStates;
    workflowState: Domain.Shared.AbstractElementWorkflowStatus;
    elementId: string,
    onAssignContributors: (contributors: Record<string, boolean>) => void,
    onFieldsDataChanged: (fields: FormInfo<ValueType>) => void,
    onWorkflowStateChanged: (workflowStateValue: Domain.Shared.AbstractElementWorkflowStatus) => void,
    handleGone: () => void;
    onIdle: () => void;
    onActive: () => void;
    handleStillHere: () => void;
    handleDeleteLease: () => void;
    onSaveChanges: () => void;
    onCancelChanges: () => void;
    deleteLease: () => void;
};

const HierarchyComponent: React.FC<HierarchyComponentProps> = (props) => {
    const {
        canEditHierarchyItem,
        complexFields,
        leaseInfo,
        userIdentity,
        fields,
        attachments,
        hierarchyRights,
        audit,
        users,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        elementId,
        handleGone,
        onIdle,
        onActive,
        handleStillHere,
        handleDeleteLease,
        onSaveChanges,
        onCancelChanges,
        deleteLease,
        onAssignContributors,
        onFieldsDataChanged,
        onWorkflowStateChanged,
    } = props;

    const commonProps = {
        userIdentity,
        complexFields,
        users,
        fields,
        attachments,
        audit,
        isMeasureMomentClosed,
        workflowTemplate,
        workflowState,
        elementDefinition,
        onAssignContributors,
        onFieldsDataChanged,
        onWorkflowStateChanged,
    };

    if (canEditHierarchyItem) {
        return (
            <LeaseWrapper leaseInfo={leaseInfo} onActive={onActive} handleGone={handleGone} onIdle={onIdle} handleStillHere={handleStillHere} handleDeleteLease={handleDeleteLease}>
                <HierarchyItem
                    {...commonProps}
                    leaseInfo={leaseInfo}
                    hierarchyRights={hierarchyRights}
                    mode={FormMode.Edit}
                    onSaveChanges={onSaveChanges}
                    userIdentity={userIdentity}
                    onCancelChanges={() => {
                        onCancelChanges();
                        deleteLease();
                    }}
                />
            </LeaseWrapper>
        );
    }

    return (
        <HierarchyItem
            {...commonProps}
            mode={elementId ? FormMode.View : FormMode.AddNew}
            onSaveChanges={onSaveChanges}
            onCancelChanges={onCancelChanges}
        />);
};
//end separate

const performanceElementDefinitionSystemIds = [
    Domain.SystemElementDefinitions.Performance.Policy,
    Domain.SystemElementDefinitions.Performance.Goal,
    Domain.SystemElementDefinitions.Performance.Achievement,
    Domain.SystemElementDefinitions.Performance.Activity
] as string[];

const getElementDefinition = (elementDefinitions: Domain.Shared.ElementDefinition[], id: string) => {
    if (!id) return
    return elementDefinitions.find(item => item.systemId === id);
};

export { Overview as index };
