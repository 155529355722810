import React from 'react';
import { Button } from 'devextreme-react/button';
import { Bar, Text } from '@liasincontrol/ui-basics';
import * as Styled from './index.styled';

type Props = {
    error: string | null | undefined,
    errorDetails?: any,
    onRetry?: () => void,
    onBack?: () => void,
    className?: string,
    children?: React.ReactNode
};

/**
 * Represents a component that can display information about any errors happening in the application pages.
 */
export const ErrorOverlay: React.FC<Props> = (props) => {

    const onFadedRef = (el: HTMLDivElement) => {
        if (el) {
            el.setAttribute(`inert`, `true`);
        }
    };

    return (
        <Styled.Container>
            {props.error
                ? (
                    <>
                        <Styled.Faded ref={onFadedRef}>
                            <>{props.children}</>
                        </Styled.Faded>
                        <Styled.Message>
                            <Styled.SubTitle>Oeps</Styled.SubTitle>
                            <Text value={props.error} />
                            <br />
                            <Bar>
                                <Bar start>
                                    {props.onBack
                                        ? <Button
                                            id="btn-back"
                                            type="default"
                                            stylingMode="text"
                                            text="Terug"
                                            onClick={props.onBack} />
                                        : null}
                                    {props.onRetry
                                        ? <Button id="btn-error-retry"
                                            type="default"
                                            stylingMode="text"
                                            text="Opnieuw proberen"
                                            onClick={props.onRetry}
                                        />
                                        : null}
                                </Bar>
                            </Bar>
                        </Styled.Message>
                    </>
                ) : <>{props.children}</>}
        </Styled.Container>);
};

export default ErrorOverlay;