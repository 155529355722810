declare global {
    interface ArrayExtensions<T> {
        /**
         * Returns a flat list of items from a list of hierarchical structured items.
         *
         * @param childrenSelector A function that returns a list of child items for a given item.
         * @returns A flat list with all items including children of all hierarchy levels.
         *
         * @example Get a list with all persons including their children, children of children, children of children of children and so on.
         * var items = [
         * {
         *     name: "Mother",
         *     children: [
         *         {
         *             name: "Child of Mother",
         *             children: [
         *                 {
         *                     name: "Grandchild of Mother",
         *                     children: []
         *                 }
         *             ]
         *         },
         *         {
         *             name: "Other child of Mother",
         *             children: []
         *         }
         *     ]
         * }
         * ];
         *
         * items.flatten(person => person.children);
         *
         * This code will return a list equivalent to:
         * [
         *      items[0],                           // "Mother"
         *      items[0].children[0],               // "Child of mother"
         *      items[0].children[0].children[0],   // "Grandchild of Mother"
         *      items[0].children[1]                // "Other child of Mother"
         * ]
         */
        flatten(childrenSelector: (element: T) => T[]): T[];
    }

    interface Array<T> extends ArrayExtensions<T> { }
}

// eslint-disable-next-line no-extend-native
Array.prototype.flatten = function <T>(childElementsSelector: (element: T) => T[]): T[] {
    const result = [];
    const elementsToProcess = [];
    for (let i = this.length - 1; i >= 0; i--) {
        elementsToProcess.push(this[i]);
    }
    while (elementsToProcess.length > 0) {
        const element = elementsToProcess.pop();
        result.push(element);
        const childElements = childElementsSelector(element);
        if (!childElements) {
            continue;
        }
        for (let i = childElements.length - 1; i >= 0; i--) {
            elementsToProcess.push(childElements[i]);
        }
    }

    return result;
};

export * from './CoreService';