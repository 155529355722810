import React from 'react';
import { Section } from '@liasincontrol/ui-elements';
import Styled from './index.styled';

type Props = {
    hasPublisherAccess: boolean,
    administrativeUrl?: string,
    showManageUsers: boolean,
}
/**
 * Represents a UI component that renders a list of tiles inside the Cockpit view.
 */
const Tiles: React.FC<Props> = (props) => {
    return (
        <>
            {props.hasPublisherAccess &&
                <Section key={1} look="white" grid={true} colSpan={1} rowSpan={1} widget={true}>
                    <Styled.Link id="btn-publications" $background="#612BB7" to="/publisher/publication/list">
                        <Styled.Icon $type="file"></Styled.Icon>
                        <Styled.Content>Publicaties schrijven</Styled.Content>
                    </Styled.Link>
                </Section>
            }
            {!!props.administrativeUrl && (
                <Section key={2} look="white" grid={true} colSpan={1} rowSpan={1} widget={true}>
                    <Styled.Link id="btn-settings" $background="#0D82E6" to={props.administrativeUrl}>
                        <Styled.Icon $type="settings"></Styled.Icon>
                        <Styled.Content>ApplicatieBeheer</Styled.Content>
                    </Styled.Link>
                </Section>
            )}
            {props.showManageUsers && (
                <Section key={3} look="white" grid={true} colSpan={1} rowSpan={1} widget={true}>
                    <Styled.Link id="btn-users" $background="#1AAA62" to="/admin/usermanagement/users">
                        <Styled.Icon $type="user"></Styled.Icon>
                        <Styled.Content>GebruikersBeheer</Styled.Content>
                    </Styled.Link>
                </Section>
            )}
        </>
    );
};

export default Tiles;
