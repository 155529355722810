import { useSelector, useDispatch } from 'react-redux';
import { State, AjaxRequestStatus, RootState, SvgIconActionCreator } from '@liasincontrol/redux-service';
import * as Domain from '@liasincontrol/domain';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

type IconsType = {
    items: { [id: string]: Domain.Shared.SvgIcon },
    status: AjaxRequestStatus
}

export const useIcons = () => {
    const icons = useSelector<State, IconsType>((state) => state.icons);
    const dispatch: ThunkDispatch<RootState, void, AnyAction> = useDispatch();

    if (icons.status === AjaxRequestStatus.NotSet) {
        dispatch(SvgIconActionCreator.set());
    }

    return icons;
};