import React, { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import SortIcon from '@mui/icons-material/Sort';
import Ink from 'react-ink';
import { Link } from 'react-router-dom';
import { Button } from 'devextreme-react/button';
import { Icon, IconSize, IconValue, Breakpoint, useResponsiveMode, palette } from '@liasincontrol/ui-basics';
import { getMuiIconAsSvgString } from '@liasincontrol/ui-devextreme';
import Styled from './index.styled';
import Announcement from '../Announcement';

export enum Look {
    //Bug: without the string values, the first enum key, in this case publisherAdmin, won't be accessed by LookColor[look]
    publisherAdmin = "pubAdmin",
    publisherWriter = "pubWriter",
    performance = "performance",
    finance = "finance",
    studio = "studio",
    analytics = "analytics",
    ai = "ai",
}

export const LookColor = {
    [Look.publisherWriter]: { fill: palette.melrose2, background: palette.primary1c },
    [Look.publisherAdmin]: { fill: palette.primary1c, background: palette.grey4 },
    [Look.performance]: { fill: palette.lightBlue, background: palette.blue },
    [Look.finance]: { fill: palette.lightGreen, background: palette.darkGreen },
    [Look.studio]: { fill: palette.lightPink, background: palette.darkPink },
    [Look.analytics]: { fill: palette.lightYellow, background: palette.yellow },
    [Look.ai]: { fill: palette.lightSeaGreen, background: palette.darkSeaGreen },
};


export type NavItem = {
    key: string,
    active: boolean,
    title: string,
    alt: string,
    to: string,
    description?: string,
    icon: IconValue,
    items?: NavItem[],
    hidden?: boolean,
};

export type MenuItem = {
    key: string,
    active: boolean,
    title: string,
    alt: string,
    to: string,
    description?: string,
    icon?: IconValue,
    target?: string,
    enabled?: boolean,
};


export type MenuGroup = {
    key: string,
    title: string,
    items: MenuItem[],
};

export type NavGroup = {
    key: string,
    title: string,
    active: boolean,
    icon: IconValue,
    to: string,
    items: NavItem[],
    hidden?: boolean,
};

type Props = {
    menuItems: NavGroup[],
    look: Look,
    alt?: string,
    isResponsive?: boolean,
    announcement?: string,
};

/**
 * Represents a UI component that renders a refactored navigation side menu wrapper.
 */
export const SideNavigation: React.FC<Props> = ({ isResponsive = true, ...props }) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const { is } = useResponsiveMode();
    const mini = isResponsive && (is(Breakpoint.s) || is(Breakpoint.xs));
    const collapsed = !expanded && !!mini;

    const getGroupItemsElements = (menuItems: NavItem[]) =>
        menuItems.filter(menuItem => !menuItem.hidden).map((menuItem: NavItem, index: number) =>
            <Link id={`menu-item-${index}`} key={index} to={menuItem.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                {menuItem.description && <Styled.HiddenText id={`${index}-description`}>{menuItem.description}</Styled.HiddenText>}
                <Styled.MenuItemContainer
                    role='option'
                    $look={props.look}
                    aria-label={menuItem.alt}
                    title={menuItem.description}
                    aria-current={menuItem.active ? 'page' : undefined}
                    aria-describedby={menuItem.description && `${index}-description`}
                    aria-selected={menuItem.active}
                    $active={menuItem.active}>
                    <Styled.ButtonContent>
                        {mini
                            ? <Icon value={menuItem.icon} size={IconSize.small} />
                            : <Icon value={menuItem.icon} size={IconSize.medium} />
                        }
                        {!collapsed && <Styled.ButtonText>{menuItem.title}</Styled.ButtonText>}
                        <Ink recenter={true} />
                    </Styled.ButtonContent>
                </Styled.MenuItemContainer>
            </Link>
        );

    const groupElements = props.menuItems.map((group: NavGroup, index: number) => {
        const groupItems = getGroupItemsElements(group.items);
        const floatingMenuElement = (
            <Styled.FloatingMenu $collapsed={collapsed}>
                <span>{group.title}</span>
                {group.items.map((item, index) =>
                    !item.hidden ? (<Link id={`floating-menu-item-${index}`} key={index} to={item.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Styled.FloatingMenuItem>
                            <Styled.ButtonContent>
                                {mini
                                    ? <Icon value={item.icon} size={IconSize.small} />
                                    : <Icon value={item.icon} size={IconSize.medium} />}
                                <Styled.ButtonText>{item.title}</Styled.ButtonText>
                                <Ink recenter={true} />
                            </Styled.ButtonContent>
                        </Styled.FloatingMenuItem>
                    </Link>
                    ) : null)}
            </Styled.FloatingMenu>
        );

        return !group.hidden && (
            <Styled.GroupListbox key={index} role='listbox' aria-labelledby={group.title && `${index}`} $active={group.active}>
                {group.title && (
                    <Link id={`btn-menu-${index}`} key={index} to={group.to} style={{ color: 'inherit', textDecoration: 'inherit' }}>
                        <Styled.GroupItemContainer
                            $active={group.active}
                            role='option'
                            aria-current={group.active ? 'page' : undefined}
                            aria-describedby={`${index}-description`}
                            aria-selected={group.active}
                        >
                            <Styled.ButtonContent $look={props.look} $cockpitItem={index === 0}>
                                {mini
                                    ? <Icon value={group.icon} size={IconSize.small} />
                                    : <Icon value={group.icon} size={IconSize.default} />
                                }
                                {!collapsed && <Styled.ButtonText>{group.title}</Styled.ButtonText>}
                                <Ink recenter={true} />
                            </Styled.ButtonContent>
                        </Styled.GroupItemContainer>
                    </Link>
                )}
                {!group.active && group.items.length > 0 && group.items.filter(item => !item.hidden).length > 0 && floatingMenuElement}
                {group.active && groupItems}

            </Styled.GroupListbox>
        );
    });

    return (
        <Styled.Container
            role='navigation'
            aria-label={props.alt}
            $collapsed={collapsed}
        >
            {mini && (
                <Button
                    id="btn-sidenavigation-menu-toogle"
                    type={props.look === Look.publisherAdmin ? 'default' : 'normal'}
                    stylingMode='contained'
                    icon={getMuiIconAsSvgString(expanded ? CloseIcon : SortIcon, IconSize.default, props.look === Look.publisherAdmin ? palette.white : palette.primary2)}
                    hint={expanded ? "Navigatie inklappen" : "Navigatie uitklappen"}
                    onClick={() => setExpanded((x) => !x)}
                />
            )}
            {groupElements}
            {props.announcement && <Announcement title='Mededeling' announcement={props.announcement} />}
        </Styled.Container>
    );
};
