import React, { ReactNode } from 'react';
import { BasicText, Heading3 } from '@liasincontrol/ui-basics';
import Styled from './index.styled';
import { Section } from '../Section';

type Props = {
    readonly title: string,
    readonly text: string,
    readonly footer?: ReactNode,
}

/**
 * Text with image widget for cockpit
 */
export const TextImageWidget = ({ title, text, footer }: Props) => {
    return (
        <Section look='white' grid={true} colSpan={3} rowSpan={2} widget={true}>
            <Styled.Content>
                <Styled.ThumbnailWrap>
                    <Styled.Thumbnail />
                </Styled.ThumbnailWrap>
                <Styled.TextWrap>
                    <Heading3>{title}</Heading3>
                    <BasicText>{text}</BasicText>
                    {footer} 
                </Styled.TextWrap>
            </Styled.Content>
        </Section>
    );
};
