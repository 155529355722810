import React, { useCallback } from 'react';
import { ImageFocusEditor } from '../ImageFocus';
import { ImageFocus, ImageFocusX, ImageFocusY, ImageFocusScale } from '../ImageFocus/editor';
import { withField, WithFieldBaseType } from '@liasincontrol/ui-devextreme';

export type FocusFieldProps = WithFieldBaseType<any> & {
    imageUrl: string;
};


export const FocusField = withField<any, FocusFieldProps>(
    (props) => {
        const { id, imageUrl, value: focus, onChange, label, ...rest } = props;

        const initialPoint: ImageFocus = [Number(focus.split(',')[ImageFocusX]), Number(focus.split(',')[ImageFocusY]), Number(focus.split(',')[ImageFocusScale])];

        const onChangeCallBack = useCallback(({ point }) => onChange?.(`${point[ImageFocusX]},${point[ImageFocusY]},${point[ImageFocusScale]}`), [onChange]);

        return <ImageFocusEditor
            {...rest}
            contain={true}
            src={imageUrl}
            initialPoint={initialPoint}
            onChange={({ point }) => { !!onChange && onChangeCallBack({ point }) }}
            style={{ width: `100%`, maxHeight: 600 }}
        />;
    }
);
