import { Typography } from "@mui/material";
import { EditCustomerIcon } from "../../icons";
import { ComponentWithEditButton, ComponentWithEditButtonType } from "../genericComponents";

type Props = {
  displayedValue: string | number;
} & ComponentWithEditButtonType;

export const SummaryTileWithEditButton = ({ displayedValue, ...buttonProps }: Props) => {
  return (
    <ComponentWithEditButton {...buttonProps} icon={EditCustomerIcon}>
      <Typography> {displayedValue} </Typography>
    </ComponentWithEditButton>
  );
};
